// third-party
import { merge } from "lodash";

// project import

import MuiTypography from "./MuiTypography";
import MuiCard from "./MuiCard";
import MuiButton from "./MuiButton";
import MuiAlert from "./MuiAlert";
import MuiCheckbox from "./MuiCheckbox";
import MuiTooltip from "./MuiTooltip";
import MuiLink from "./MuiLink";

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme) {
  return merge(
    MuiButton(theme),
    MuiCard(theme),
    MuiTypography(theme),
    MuiAlert(theme),
    MuiTooltip(theme),
    MuiCheckbox(theme),
    MuiLink(theme)
  );
}

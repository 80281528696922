import * as yup from "yup";
import { TextField as MuiTextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TextField = styled(MuiTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    backgroundColor: `${theme.palette.tertiary.secondary} !important`,
    borderRadius: "2rem",
  },
  "& .MuiFormLabel-root": {
    color: `${theme.palette.primary.contrastText} !important`,
  },
  "& .MuiFormHelperText-root": {
    textAlign: "center",
  },
  "& input": {
    textAlign: "center",
  },
}));

export const roomChargesSchema = yup.object({
  credential: yup.string().required().nullable().label("Last name"),
  roomNo: yup.string().required().nullable().label("Room number"),
});

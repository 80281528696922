import React, { useState } from "react";
import {
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Card,
  CardContent,
  Link,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { giftAidAgreementText, giftAidAgreementLinkText } from "./constant";
import { useSelector, useDispatch } from "react-redux";
import GiftAidAgreementForm from "@components/gift-aid-agreement-form/GiftAidAgreementForm";
import { TipActions } from "@store/tip/reducer";

function GiftAidAgreementFormCard() {
  const [agree, setAgreement] = useState(false);
  const { tipsAmount } = useSelector((state) => state.order);
  const { isGiftAidEnable } = useSelector((state) => state.team);
  const dispatch = useDispatch();

  const handleChange = ({ target }) => {
    setAgreement((state) => !state);
    dispatch(TipActions.toggleApplicationOfGiftAid());
  };

  let sx = { borderRadius: "8px !important" };
  if (!agree) {
    sx = {
      ...sx,
      padding: 0,
      paddingBottom: "0px !important",
      paddingLeft: "8px",
    };
  }

  const shouldShowForm = tipsAmount > 20 && agree;

  if (!Boolean(tipsAmount)) {
    return null;
  }

  if (!isGiftAidEnable) {
    return null;
  }

  return (
    <Collapse in>
      <Card sx={sx}>
        <CardContent sx={sx}>
          <Grid container rowGap={2} columnGap={1}>
            <Grid item xs={12}>
              <Box color={({ palette }) => palette.primary.contrastText}>
                <Typography variant="subTitle4">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={agree} defaultChecked />}
                      onChange={handleChange}
                      name="agree"
                      label="Yes, I would like to add Gift Aid"
                      componentsProps={{ typography: "subTitle4" }}
                      sx={{ fontSize: "14px !important", fontWeight: 500 }}
                    />
                  </FormGroup>
                </Typography>
                {agree && (
                  <Typography variant="subText6" fontWeight="500">
                    <Box
                      component="span"
                      color={({ palette }) => palette.textShades.title}
                    >
                      {giftAidAgreementText}
                    </Box>{" "}
                    <Link
                      href=" https://www.gov.uk/donating-to-charity/gift-aid"
                      target="_blank"
                      sx={{
                        color: ({ palette }) => palette.primary.contrastText,
                      }}
                    >
                      {giftAidAgreementLinkText}
                    </Link>
                  </Typography>
                )}
              </Box>
            </Grid>
            {shouldShowForm && <GiftAidAgreementForm />}
          </Grid>
        </CardContent>
      </Card>
    </Collapse>
  );
}

export default GiftAidAgreementFormCard;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";
import PageHeader from "@components/page-header";
import TeamTips from "@components/team-tips";
import { getTeam } from "@store/team/api";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TipsForm from "@components/tips-form/TipsForm";
import PaymentMethodOptions from "@components/payment-method-options";
import Loading from "@components/loading";
import Padding from "@components/padding";
import TeamOverview from "@root/feature/v2/team-overview";
import PaymentContainer from "@root/feature/v2/payment-container";
import TipForm from "@feature/v2/tip-form";
import Background from "@components/background/Background";
import FooterLogo from "@components/footer-logo";
import TeamTipForm from "@root/feature/v2/team-tip-form";
import GiftAid from "@components/gift-aid";
import GiftAidAgreementFormCard from "@components/gift-aid-agreement-form-card";

function GetTeamTips(props) {
  const { teamId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.team);

  const handleFailure = (error) => {
    navigate("/not-found");
  };

  useEffect(() => {
    window.localStorage.clear();
    dispatch(getTeam(teamId)).catch(handleFailure);
  }, []);

  let isRedirected = false;
  const oldUrl = window.location.href;
  const hostName = "fetchmytip";
  const hostPathName = "/get-tips";
  if (oldUrl.includes(hostName) || oldUrl.includes(hostPathName)) {
    isRedirected = true;
    const oldUrlOrigin = window.location.origin;
    const checkHostName = oldUrl.includes(hostName);
    const checkPathName = oldUrl.includes(hostPathName);
    const newHostName = "fetchpay";

    if (checkHostName || checkPathName) {
      const redirectToUrl = oldUrlOrigin.replace(hostName, newHostName);
      window.location.replace(`${redirectToUrl}/get-payment/team/${teamId}`);
      isRedirected = false;
    }
  }

  if (isLoading && !isRedirected) {
    return <Loading message="Loading details" />;
  }

  window.localStorage.setItem("redirectUrl", `/get-payment/team/${teamId}`);

  return (
    <Background>
      <Padding>
        <Grid container>
          <Grid item xs={12}>
            <TeamOverview />
          </Grid>
          <Grid item xs={12}>
            <TeamTipForm variant="team" />
          </Grid>
          <Grid item xs={12}>
            <GiftAid />
          </Grid>
          <Grid item xs={12}>
            <GiftAidAgreementFormCard />
          </Grid>
          <Grid item xs={12}>
            <PaymentContainer />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" mt={2} mb={2}>
              <FooterLogo />
            </Box>
          </Grid>
        </Grid>
      </Padding>
    </Background>
  );
}

GetTeamTips.propTypes = {};

export default GetTeamTips;

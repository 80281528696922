import React from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";
import OutletOverview from "@components/outlet-overview";
import FetchLogo from "@components/fetch-logo";
import Celebration from "@components/celebration";
import Padding from "@components/padding";
import Background from "@components/background";
import FooterLogo from "@components/footer-logo";

function PaymentSuccess(props) {
  return (
    <Background variant="success">
      <Padding sx={{ height: "100%" }}>
        <Box display="flex" justifyContent="center" pt={6}>
          <FetchLogo variant="dark" />
        </Box>
        <Grid container pt="45%">
          {/* <Grid item xs={12}>
          <OutletOverview />
        </Grid> */}
          <Grid item xs={12}>
            <Celebration />
          </Grid>
        </Grid>
        <Box position="absolute" bottom="0px" width="100%">
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <FooterLogo />
          </Box>
        </Box>
      </Padding>
    </Background>
  );
}

PaymentSuccess.propTypes = {};

export default PaymentSuccess;

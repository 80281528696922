import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import StripeService from "@services/StripeService";
import { action as UIActions } from "@store/ui/reducer";
import { PaymentActions } from "@store/payment/reducer";
import { formatToCurrency } from "@root/util/currency";

let didRequestCompleted = true;
let lastAmountForIntentRequest = 0;

function useStripeIntent(
  totalAmount,
  selectedPaymentGateway = "stripe",
  options = {}
) {
  const {
    outletId,
    currencyCode,
    detail = {},
  } = useSelector((state) => state.outlet);

  const { teamName, paymentMode: solutionType } = useSelector(
    (state) => state.team
  );
  const { name: outletName, tenant = {} } = detail;
  const { name: organisationName } = tenant;
  const {
    serviceChargeAmount,
    serviceCharge,
    isAllowedToPay,
    paymentReference,
  } = useSelector((state) => state.order);
  const { id } = useSelector((state) => state.tip);
  const [stripeIntent, updateStripeIntentState] = useState({});
  const dispatch = useDispatch();

  const getIntentPayload = (params) => {
    lastAmountForIntentRequest = totalAmount;
    const paymentIntentPayload = {
      amount: totalAmount,
      currencyCode: currencyCode,
      serviceChargeAmount,
      description: "Payment",
      transactionType: "SALE",
      paymentType: "TIP",
      paymentGateway: selectedPaymentGateway,
      statementDescriptor: outletName,
      outletId,
      clientReferenceNumber: id,
      serviceCharge,
      timeOfRequest: new Date().toISOString(),
      metadata: {
        organisation: organisationName,
        outlet: outletName,
        teamName,
        paymentReference,
        solutionType,
        posRef: id,
        ...params,
      },
    };

    return paymentIntentPayload;
  };

  const handleIntentError = (e) => {
    let message = "Unable to make payment, please try again later";
    let snackbarMessage = e?.errorMessage?.message || e?.errorMessage;
    if (e?.errorCode && e.errorCode === "amount_too_small") {
      message = e?.errorMessage;
      snackbarMessage = message;
    }

    console.log(snackbarMessage);

    dispatch(UIActions.setSnackBarError(snackbarMessage));
    dispatch(PaymentActions.setError(message));
    throw e;
  };

  const updateStripeIntent = (data) => {
    dispatch(PaymentActions.updatePaymentId(data?.paymentId));
    updateStripeIntentState(data);
  };

  const createPaymentIntent = (params) => {
    const paymentIntentPayload = getIntentPayload(params);
    if (!paymentIntentPayload || !isAllowedToPay) {
      return false;
    }

    if (!options.skipMultipleRequestCheck) {
      if (!didRequestCompleted) {
        return;
      }
    }
    didRequestCompleted = false;
    dispatch(PaymentActions.startStripeIntentLoading());
    return StripeService.createPaymentIntent(paymentIntentPayload)
      .then((res) => {
        updateStripeIntent({
          clientSecret: res?.clientSecret,
          paymentId: res.paymentId,
        });
        dispatch(PaymentActions.setError(null));
        return res;
      })
      .catch(handleIntentError)
      .finally(() => {
        didRequestCompleted = true;
        console.log({ didRequestCompleted }, "Line 65");
        dispatch(PaymentActions.endStripeIntentLoading());
      });
  };

  const updatePaymentIntent = (params) => {
    const paymentIntentPayload = getIntentPayload(params);
    const { paymentId } = stripeIntent;

    if (!paymentIntentPayload || !paymentId || !isAllowedToPay) {
      return false;
    }

    dispatch(PaymentActions.startStripeIntentLoading());

    return StripeService.updatePaymentIntent({
      paymentId,
      ...paymentIntentPayload,
    })
      .then((res) => {
        console.log(
          res.amount !== lastAmountForIntentRequest
            ? "Intent not completed => "
            : "Intent completed => ",
          { amountReceived: res.amount, lastAmountForIntentRequest }
        );
        if (res.amount === lastAmountForIntentRequest) {
          dispatch(PaymentActions.endStripeIntentLoading());
          dispatch(PaymentActions.setError(null));
        }
        return res;
      })
      .catch(handleIntentError)
      .finally(() => {});
  };

  const handlePaymentIntent = (params = {}) => {
    // if (!Object.keys(stripeIntent).length && totalAmount > 0) {
    //   return createPaymentIntent(params);
    // } else {
    //   return updatePaymentIntent(params);
    // }
    return createPaymentIntent(params);
  };
  return { ...stripeIntent, handlePaymentIntent };
}

export default useStripeIntent;

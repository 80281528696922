import React, { useEffect } from "react";
import { Routes, Navigate, Route, Outlet, useLocation } from "react-router-dom";

import NotFoundPage from "@pages/NotFoundPage";
import UnexpectedErrorPage from "@pages/UnexpectedErrorPage";
import UnavailableQR from "@pages/UnavailableQR";
import UserLandingPage from "@pages/UserLandingPage";
import TeamLandingPage from "@pages/TeamLandingPage";
import StaffSelection from "@pages/StaffSelectionPage";
import UserDashboardPage from "@pages/UserDashboardPage";
import RoomCharges from "@pages/room-charges-payment";
import CardPayment from "@pages/card-payment";
import PaymentSuccess from "@pages/PaymentSuccess";
import FeedbackPage from "@pages/FeedbackPage";
import SingIn from "@pages/SignIn";
import GetStaffTips from "@pages/GetStaffTips";
import GetTeamTips from "@pages/GetTeamTips";

import Error from "./Error";
import TipHistory from "@pages/TipHistory";
import ProtectedRouter from "./ProtectedRouter";
import RedirectToUserProfile from "@pages/RedirectToUserProfile";
import TeamTipHistory from "@pages/TeamTipHistory";
import AfterPaymentRoute from "./AfterPaymentRoute";
import Background from "@components/background";
import usePageTracking from "@hooks/usePageTracking";
import { useDispatch } from "react-redux";
import { UIAction } from "@store/ui/reducer";
import FeedbackResponse from "@root/feature/v2/feedback/feedback-response";

const Pages = ({ m }) => {
  return (
    <>
      <h1>{m}</h1>
    </>
  );
};

function Layout(props) {
  usePageTracking();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(UIAction.updateLandingPageURL(location.pathname));
  }, []);
  return (
    <Error>
      <Routes>
        <Route path="/" element={<ProtectedRouter />}>
          <Route index element={<RedirectToUserProfile />} />
          <Route path="/user/:userId" element={<UserLandingPage />} />
          <Route path="/tips-history/:userId" element={<TipHistory />} />
          <Route
            path="/team/:teamId/user/:userId"
            element={<TeamLandingPage />}
          />
          <Route
            path="/team-tips-history/:teamId"
            element={<TeamTipHistory />}
          />
        </Route>

        <Route
          path="/staff/:userId"
          element={<Pages m="User landing Page" />}
        />
        <Route
          path="/get-tips/staff/:staffId"
          element={
            <Background>
              <GetStaffTips />
            </Background>
          }
        />
        <Route
          path="/get-tips/team/:teamId"
          element={
            <Background>
              <GetTeamTips />
            </Background>
          }
        />
        {/* Fetch Pay Route */}
        <Route
          path="/get-payment/team/:teamId"
          element={
            <Background>
              <GetTeamTips />
            </Background>
          }
        />
        <Route path="/profile/:userId" element={<UserDashboardPage />} />
        <Route path="/teams/:teamId" element={<StaffSelection />} />

        <Route
          path="/payment/room-charge"
          element={
            <AfterPaymentRoute>
              <RoomCharges />
            </AfterPaymentRoute>
          }
        />
        <Route
          path="/payment/card"
          element={
            <AfterPaymentRoute>
              <CardPayment variant="adyen" />
            </AfterPaymentRoute>
          }
        />
        <Route
          path="/payment-success"
          element={
            <AfterPaymentRoute>
              <PaymentSuccess />
            </AfterPaymentRoute>
          }
        />

        <Route path="/celebrate" element={<Pages m="celebrate" />} />
        <Route
          path="/feedback-response/:feedbackId"
          element={<FeedbackResponse />}
        />
        <Route path="/feedback" element={<FeedbackPage />} />

        <Route path="sign-in/" element={<SingIn />} />
        <Route path="/error" element={<UnexpectedErrorPage />} />
        {/* <Route path="/not-found" element={<NotFoundPage />} /> */}

        <Route path="/not-found" element={<NotFoundPage />}>
          <Route index element={<NotFoundPage />} />
          <Route path=":message1" element={<NotFoundPage />} />
          <Route path=":message1/:message2" element={<NotFoundPage />} />
        </Route>

        <Route path="/unavailable-qr" element={<UnavailableQR />} />
        {/* <Route path="*" element={<Navigate to="/not-found" replace />} /> */}
      </Routes>
    </Error>
  );
}

export default Layout;

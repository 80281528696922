import React, { useEffect, memo } from "react";
import PropTypes from "prop-types";
import { useKeycloak } from "@react-keycloak/web";
import jwt from "jwt-decode";
import { useNavigate } from "react-router-dom";

function useGetUserId(props) {
  const { keycloak, initialized } = useKeycloak();
  const parsedToken = jwt(keycloak.token);
  const { sub: userId } = parsedToken;
  return userId;
}

useGetUserId.propTypes = {};

export default useGetUserId;

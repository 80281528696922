export const Styles = {
  desc: {
    color: "#8E8E8E",
    fontSize: 15,
    marginTop: 10,
  },

  modalStyle: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0%)",
    minWidth: 410,
    maxWidth: 575,
    height: 150,
    border: "0px solid #000",
    boxShadow: 24,
    bottom: 0,
    background: "white",
    textAlign: "center",
  },
};

import MuiTooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const textColor = {
  color: (theme) => {
    return theme.palette.textShades.title;
  },
};

export const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#242424 !important",
    color: "#fff",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    padding: "12px",
    textAlign: "center",
    marginBottom: "5px !important",
    border: "1px solid #242424",
    "& .MuiTooltip-arrow": {
      "&:before": {
        color: "#242424 !important",
      },
    },
  },
}));

import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Divider, Box } from "@mui/material";
import Currency from "@components/currency";
import Padding from "@components/padding";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import queryString from "query-string";

function ThankYouMessage(props) {
  const { search } = window.location;
  // const { totalOrderAmount } = useSelector((state) => state.order);
  const { amount } = queryString.parse(search);
  console.log(amount);
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subHeading4" pb={1.3}>
            Thank you!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subText5"
            color="#8E8E8E"
            display="flex"
            alignItems="center"
          >
            <Box component="span" position="relative">
              <Box
                backgroundColor={(theme) => theme.palette.primary.contrastText}
                component="span"
                position="absolute"
                height="16px"
                width="16px"
                left="1.9px"
                top="1.9px"
                zIndex="1"
                borderRadius="50%"
              ></Box>
              <CheckCircleIcon
                sx={{
                  color: (theme) => theme.palette.secondary.main,
                  position: "relative",
                  zIndex: 3,
                }}
              />
            </Box>
            &nbsp; Payment Completed - {amount}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ height: "2px", mt: 2 }} />
    </>
  );
}

ThankYouMessage.propTypes = {};

export default ThankYouMessage;

import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

function FullHeightLayout({ children, ...props }) {
  return (
    <Grid
      className="not-found-page"
      container
      sx={{ height: "100vh" }}
      {...props}
    >
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
}

FullHeightLayout.propTypes = {
  children: PropTypes.node,
};

export default FullHeightLayout;

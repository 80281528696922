import React from "react";
import PropTypes from "prop-types";

import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetSelectedStaffMember } from "@store/team/reducer";
import TipHistoryCard from "@components/tip-history-card";

function UserDashboard(props) {
  const state = useSelector((state) => state);
  const { staffImageUrl, name } = useGetSelectedStaffMember(state);
  const { userId } = useParams();
  const navigateTo = useNavigate();

  return (
    <TipHistoryCard
      tips={[10, 15, 20, 25, 30]}
      totalTipsAmount={100}
      name="Arpit"
      staffImageUrl={staffImageUrl}
    />
  );
}

UserDashboard.propTypes = {};

export default UserDashboard;

// ==============================|| OVERRIDES - MUI CHECKBOX ||============================== //

export default function MuiTooltip() {
  return {
    MuiTooltip: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiTooltip-popper": {
            backgroundColor: "#000000 !important",
            "& .MuiTooltip-tooltip": {
              backgroundColor: "#000000 !important",
            },
          },
        }),
      },
    },
  };
}

import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware, routerReducer } from "react-router-redux";
import { createLogger } from "redux-logger";

import { history } from "../App";

import ui from "./ui/reducer";
import outlet from "./outlet/reducer";
import menu from "./menu/reducer";
import order from "./order/reducer";
import event from "./event/reducer";
import team from "./team/reducer";
import checkout from "./checkout/reducer";
import payment from "./payment/reducer";
import staff from "./staff/reducer";
import tip from "./tip/reducer";

import viewOrderPage from "./pages/viewOrderPage";
import signInPage from "./pages/signInPage";
import paymentGateway from "./payment-gateway/reducer";
import feedback from "./feedback/reducer";

const logger = createLogger({
  collapsed: true,
});

export const store = configureStore({
  reducer: {
    outlet: outlet.reducer,
    routing: routerReducer,
    menu: menu.reducer,
    order: order.reducer,
    viewOrderPage: viewOrderPage.reducer,
    event: event.reducer,
    signInPage: signInPage.reducer,
    team: team.reducer,
    ui: ui.reducer,
    checkout: checkout.reducer,
    payment: payment.reducer,
    staff: staff.reducer,
    tip: tip.reducer,
    paymentGateway: paymentGateway.reducer,
    feedback: feedback.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware().concat(routerMiddleware(history));
    console.log(process.env);
    if (process.env.NODE_ENV === "development") {
      middleware = middleware.concat(logger);
    }

    return middleware;
  },
});

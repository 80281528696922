import * as yup from "yup";

export const initialValues = {
  username: "",
  password: "",
};

export const loginFormSchema = yup.object({
  username: yup.string().required().label("User name"),
  password: yup.string().required().label("Password"),
});

import { gql } from "@apollo/client";

export const GET_TIPS_QUERY = gql`
  query getTipPayment($input: GetTipPaymentInput!) {
    getTipPayment(input: $input) {
      id
      tipAmount
      staff {
        id
        displayName
      }
      team {
        id
        teamName
        staff {
          id
          displayName
        }
      }
      payeeName
      gatewayChargeId
      stripChargeId
      chargeValue
      vatValue
      transferValue
      cardType
      gatewayTransactionId
      paymentDate
    }
  }
`;

import { styled } from "@mui/system";
import { TextField } from "@mui/material";

export const RoundedTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "31px",
  },
});

export const RoundedTextFieldLight = styled(RoundedTextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "31px",
  },

  "& .MuiSvgIcon-root": {
    color: "#a2a2a2",
  },
  "&  .Mui-focused ": {
    borderColor: " rgba(0, 0, 0, 0.23)",
  },
  "&  .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23) !important",
    borderWidth: "1px !important",
  },
});

import { gql } from "@apollo/client";

export const GET_STAFF_QUERY = gql`
  query getStaffDetails($userId: String!) {
    getStaffDetails(userId: $userId) {
      id
      userId
      displayName
      description
      staffMessage
      tipsDisplayLabel
      staffImageUrl
      isQREnable
      isActive
      qrCode {
        id
        qrCodeUUId
        qrCodeImagePNG
        qrCodeImageSVG
        qrCodeURL
      }
      team {
        id
        teamName
        description
        paymentMode
        tipsDisplayLabel
        teamMessage
        tipsDefaultValue
        isPartialTippingEnable
        outlet {
          id
          name
          logoUrl
          outletCurrency {
            currencyCode
            currencySymbol
          }
          serviceCharge {
            serviceChargeFeePercentage
            serviceChargeFeeAmount
            isServiceChargeEnabled
            isServiceChargeOptable
          }
          tenant {
            id
            name
          }
        }
      }
    }
  }
`;

import { createSlice } from "@reduxjs/toolkit";

const details = {
  name: " ",
  logo: "/assets/images/fetchmytip-logo.svg",
};

const defaultCurrencySymbol = "£";

const initialState = {
  detail: details,
  isLoading: true,
  error: null,
  apiResponse: {},
  outletId: null,
  currencySymbol: defaultCurrencySymbol,
};
const outlet = createSlice({
  name: "outlet",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    startLoading: (state, { payload }) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    endLoading: (state, { payload }) => ({ ...state, isLoading: false }),

    updateOutlet: (state, { payload }) => {
      const outlet = payload;
      const { outletCurrency = {}, outletCountry = {} } = outlet;
      return {
        ...state,
        detail: outlet,
        outletId: outlet.id,
        outletType: outlet.outletType,
        orderTypes: outlet.orderTypes,
        image: outlet.imageUrl,
        apiResponse: payload,
        currencySymbol: defaultCurrencySymbol,
        ...outletCurrency,
        ...outletCountry,
      };
    },
    updateError: (state, { payload }) => ({ ...state, error: payload }),
  },
});

export const actions = outlet.actions;

export default outlet;

import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { getDojoConfig } from "./constant";
import Currency from "@components/currency";
import { useSelector } from "react-redux";
import FloatingButton from "@components/floating-button";
import { alpha } from "@mui/material/styles";

const DojoCard = ({ clientSecret, ...props }) => {
  const { onIframeLoaded, loaded, setLoaded } = props;
  const [card, setCard] = useState(null);

  const { error, stripeIntentLoading, errorType } = useSelector(
    (state) => state.payment
  );

  useEffect(() => {
    const config = getDojoConfig({ clientSecret, onIframeLoaded });
    document.getElementById(config?.containerId).innerHTML = "";
    const newCard = new window.dojo.Payment(
      config,
      props.displayErrorsCallback
    );
    setCard(newCard);
  }, [clientSecret]);

  useEffect(() => {
    const element = document.getElementById("card-payment");
    element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
  }, []);

  const handlePaymentClick = async () => {
    try {
      if (card) {
        props.setLoading(true);
        await props.beforePayment();
        const dojoCardPaymentResponse = await card.executePayment();
        console.log(dojoCardPaymentResponse);
        if (dojoCardPaymentResponse?.statusCode !== 0) {
          throw dojoCardPaymentResponse?.message;
        }

        if ([0, 3].includes(dojoCardPaymentResponse.statusCode)) {
          await props.onPaymentSuccess(props.paymentId);
        }
        props.setLoading(false);
      }
    } catch (error) {
      console.error("Payment Request failed: ", error);
      props.setLoading(false);
      setLoaded(false);
      props.handleSubmitError(error);
    }
  };

  return (
    <Grid
      container
      spacing={2}
      id="card-payment"
      minHeight="420px"
      position="relative"
    >
      {!loaded && (
        <Box
          position="absolute"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", height: "100%" }}
          flexDirection="column"
          color="#fff"
        >
          <CircularProgress sx={{ color: "#fff" }} />

          <p>Loading payment options</p>
        </Box>
      )}
      {loaded && (
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Typography variant="subHeading5">Payment details</Typography>
          <Typography variant="subTitle6" color="rgba(255, 255, 255, 0.60)">
            All payments are secure and encrypted
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box id="dojo-card-payment">
          {/* Render your payment UI here */}
          {/* You can also add a button for payment */}
        </Box>
      </Grid>
      {props.errors.length > 0 && (
        <Grid item xs={12}>
          {props.errors.map((error, index) => (
            <Typography
              variant="subText6"
              color="red"
              key={index}
              align="center"
            >
              {error}
            </Typography>
          ))}
        </Grid>
      )}
      {loaded && (
        <Grid item xs={12}>
          <Box sx={{ mt: 2 }}>
            {props.serviceFeeMessage && (
              <Typography
                variant="subText6"
                align="center"
                color={(theme) =>
                  alpha(theme.palette.primary.contrastText, 0.6)
                }
                mt={2}
                mb={3}
              >
                {props.serviceFeeMessage}
              </Typography>
            )}
            <FloatingButton
              onClick={handlePaymentClick}
              position="relative"
              color="secondary"
              error={error}
            >
              {!stripeIntentLoading && "Pay -  "}
              {stripeIntentLoading ? (
                <CircularProgress size="1.8rem" />
              ) : (
                <Box pl={0.5} component="span">
                  <Currency value={props.totalAmount} />
                </Box>
              )}
            </FloatingButton>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default DojoCard;

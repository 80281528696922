import { PaymentActions } from "@store/payment/reducer";
import { action as UiActions } from "@store/ui/reducer";
import { useDispatch, useSelector } from "react-redux";

function useBeforePaymentValidations() {
  const { cardType, allowedCardBrands } = useSelector((state) => state.payment);
  const dispatch = useDispatch();

  const cardTypeCheck = () => {
    const message = "Card type not supported";
    if (Boolean(cardType) && !allowedCardBrands.includes(cardType)) {
      dispatch(UiActions.setSnackBarError(message));
      dispatch(PaymentActions.setError(message));
      setTimeout(() => dispatch(PaymentActions.setError("")), 3000);
      throw message;
    }
    console.log("Passed cardTypeCheck");
    return true;
  };

  return { cardTypeCheck };
}

export default useBeforePaymentValidations;

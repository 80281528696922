import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Box, alpha } from "@mui/material";
import { useSelector } from "react-redux";
import Currency from "@components/currency";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import Icon from "@components/icons";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useTipTo from "@hooks/use-tip-to";
import queryString from "query-string";
import { formatToCurrency } from "@root/util/currency";
import { thankYouMessage } from "./constant";
import useCustomField from "@containers/add-custom-fields/useCustomField";

function Celebration(props) {
  const { displayName } = useSelector((state) => state.staff);
  const { teamName, paymentMode = "tipping" } = useSelector(
    (state) => state.team
  );
  const { totalOrderAmount, serviceChargeAmount, serviceCharge } = useSelector(
    (state) => state.order
  );
  const { currencySymbol } = useSelector((state) => state.outlet);
  const { id } = useSelector((state) => state.tip);
  const { detail, name } = useSelector((state) => state.outlet);
  const { logoUrl } = detail;
  const tipType = useTipTo(2);

  const { width, height } = useWindowSize();

  const navigate = useNavigate();
  const { addCustomFields } = useCustomField();

  const redirectUrl = window.localStorage.getItem("redirectUrl");
  const queryPayload = {
    ...tipType,
    id,
    amount: `${currencySymbol}${formatToCurrency(totalOrderAmount)}`,
    logoUrl,
    redirectUrl,
  };

  useEffect(() => {
    setTimeout(() => {
      //added custom fields hook here to prevent from not triggering in case of wallet payments
      addCustomFields();
      navigate(`/feedback?${queryString.stringify(queryPayload)}`);
    }, 2500);
  }, []);

  const shouldDisplayServiceCharge = Boolean(
    serviceCharge?.isServiceChargeEnabled
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          variant="subText2"
          align="center"
          color={(theme) => alpha(theme.palette.primary.contrastText, 0.7)}
          gutterBottom
        >
          Thank you for {thankYouMessage(paymentMode)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box position="relative">
          <Typography
            variant="subHeading2"
            align="center"
            color={(theme) => theme.palette.primary.contrastText}
          >
            {!displayName ? teamName : displayName}
          </Typography>
          {/* <Box position="absolute" width="100%">
            <Typography align="center">
              <img
                src="/assets/images/amount-underline.svg"
                alt="amount-underline"
              />
            </Typography>
          </Box> */}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          align="center"
          paddingTop="20px"
          color={(theme) => theme.palette.primary.contrastText}
        >
          <Currency
            value={totalOrderAmount}
            symbolStyle={{ fontSize: "70px" }}
          />
        </Typography>
        {shouldDisplayServiceCharge && (
          <Typography
            variant="subText5"
            align="center"
            color={(theme) => theme.palette.primary.contrastText}
          >
            {/* + <Currency value={serviceChargeAmount} /> Service Fee */}
            inc service fee
          </Typography>
        )}
      </Grid>
      <Confetti
        width={width}
        height={height}
        colors={["#BE0358", "#FC6250", "#FFCE05", "#39C1D8", "#fff"]}
      />
    </Grid>
  );
}

Celebration.propTypes = {};

export default Celebration;

import React from "react";
import { Grid, TextField as MuiTextField, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import { validationSchema, initialValues, titles } from "./constant";
import { errorIfTouched } from "@root/util/form";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { OrderActions } from "@store/order/reducer";
import { TipActions } from "@store/tip/reducer";

/***********************************************************************/
// Todo : Move it in theme and use it as variant of text field
const TextField = styled(MuiTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    color: theme.palette.primary.contrastText,
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: "2rem",
  },
  "& .MuiSelect-select": {
    padding: "11px !important",
    color: `${theme.palette.primary.contrastText} !important`,
  },

  "&::before": {
    border: "none !important",
  },
  "&::after": {
    border: "none !important",
  },
  "& input": {
    padding: "0 20px",
    fontWeight: 600,

    color: theme.palette.primary.contrastText,
  },
}));
/***********************************************************************/

function GiftAidAgreementForm() {
  const dispatch = useDispatch();
  const form = useFormik({
    initialValues,
    validationSchema: validationSchema,
  });

  const { values } = form;

  const onChange = (e) => {
    const { target } = e;
    const { value, name } = target;
    dispatch(TipActions.updateGiftAidData({ ...values, [name]: value }));
    form.handleChange(e);
  };

  return (
    <Grid container rowGap={2} columnGap={1}>
      <Grid xs={12} item>
        <TextField
          placeholder="Title *"
          onChange={onChange}
          onBlur={form.handleBlur}
          name="title"
          value={values.title}
          error={errorIfTouched(form, "title")}
          helperText={errorIfTouched(form, "title")}
          fullWidth
          select
        >
          {titles.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid xs={5.8} item>
        <TextField
          placeholder="First Name *"
          name="firstName"
          onChange={onChange}
          onBlur={form.handleBlur}
          value={values.firstName}
          error={errorIfTouched(form, "firstName")}
          helperText={errorIfTouched(form, "firstName")}
          fullWidth
        />
      </Grid>
      <Grid xs={5.8} item>
        <TextField
          placeholder="Last Name *"
          name="lastName"
          onChange={onChange}
          onBlur={form.handleBlur}
          value={values.lastName}
          error={errorIfTouched(form, "lastName")}
          helperText={errorIfTouched(form, "lastName")}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          placeholder="House Name/Number *"
          name="houseNumber"
          onChange={onChange}
          onBlur={form.handleBlur}
          value={values.houseNumber}
          error={errorIfTouched(form, "houseNumber")}
          helperText={errorIfTouched(form, "houseNumber")}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          placeholder="Postcode *"
          name="postcode"
          onChange={onChange}
          onBlur={form.handleBlur}
          value={values.postcode}
          error={errorIfTouched(form, "postcode")}
          helperText={errorIfTouched(form, "postcode")}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

export default GiftAidAgreementForm;

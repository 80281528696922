import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useMatch } from "react-router-dom";

const styles = {
  backgroundColor: (theme) => theme.palette.primary.main,
  position: "relative",
  height: "100%  !important",
};

function Background({ variant = "xzy", ...props }) {
  return (
    <Box
      sx={{ ...styles, height: variant === "success" ? "100%" : "fit-content" }}
    >
      <Box position="absolute" zIndex={0} width=" 100%" height="100%">
        <img
          src="/assets/images/fetch-background.svg"
          width="100%"
          height="100%"
        />
      </Box>
      <Box position="relative" zIndex={1} width=" 100%" height="100%">
        {props.children}
      </Box>
    </Box>
  );
}

Background.propTypes = {};

export default Background;

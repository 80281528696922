import React from "react";
import PropTypes from "prop-types";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "@services/KeyCloakService";
import { Outlet } from "react-router-dom";
import Authenticate from "@components/authenticated";
import Authorized from "@components/authorized";

const eventLogger = (event, error) => {
  console.log("onKeycloakEvent", event, error);
  console.log({ event, error });
};

const tokenLogger = (tokens) => {
  const token = tokens?.token ? `Bearer ${tokens?.token}` : null;
  window.localStorage.setItem("token", token);
};

function ProtectedRouter(props) {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
      onError={console.log}
    >
      <Authenticate>
        <Authorized>
          <Outlet />
        </Authorized>
      </Authenticate>
    </ReactKeycloakProvider>
  );
}

ProtectedRouter.propTypes = {};

export default ProtectedRouter;

// ==============================|| OVERRIDES - CARD ||============================== //

export default function MuiButton(theme) {
  return {
    MuiButton: {
      variants: [
        {
          props: { variant: "circle" },
          style: {
            borderRadius: "50%",
            height: "88px",
            width: "88px",
            textTransform: "none",
            border: `1px solid ${theme.palette.secondary.main}`,
          },
        },
      ],
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: "unset",
          fontWeight: 700,
          fontsize: "0.825rem",
          borderRadius: "8px",
        }),
      },
    },
  };
}

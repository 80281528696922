import StripeService from "@root/services/StripeService";
import { useSelector, useDispatch } from "react-redux";
import { PaymentActions } from "@store/payment/reducer";

const cardRejected = "card rejected";
// This was a requirement from BE ( Ravi ) to close
// https://pos8.atlassian.net/browse/FE-3423
const dojoSuccessStatus = ["Captured"];
const stripeSuccessStatus = [
  "transfer successful",
  "succeeded",
  "transfer failed",
];

export const paymentStatus = {
  success: [...stripeSuccessStatus, ...dojoSuccessStatus],
  failed: ["failed"],
  validationFailed: [cardRejected],
  retryStatus: [
    "requires_capture",
    "payment_captured",
    "AUTHORIZATION_INITIATE",
    "Created", //dojo
    "Authorized", //dojo
  ],
};

let callCount = 0;

function useConfirmPayment() {
  const dispatch = useDispatch();

  const confirmPaymentStatus = async ({ successCb, fallBackCb, paymentId }) => {
    callCount++;
    console.log("CardPayment :- Starting confirmPaymentStatus api call");
    try {
      const res = await StripeService.getPaymentConfirmation(paymentId);
      const { paymentStatus: status } = res;

      if (paymentStatus.success.includes(status)) {
        console.log(`CardPayment :- calling success fn`);
        return successCb(res);
      }
      if (paymentStatus.failed.includes(status)) {
        console.log(`CardPayment :- calling fallback fn`);
        return fallBackCb(res);
      }
      if (paymentStatus.validationFailed.includes(status)) {
        console.log(`CardPayment :- validation error fn`);
        if (status === cardRejected) {
          console.log(`CardPayment :- validation error reason ${cardRejected}`);

          return dispatch(PaymentActions.updateCardRejected(true));
        }
        return fallBackCb(res);
      }
      if (paymentStatus.retryStatus.includes(status)) {
        if (callCount > 500) {
          console.log(
            `CardPayment :- Exiting as more than 100 api calls was made to confirm but still status was not one of $${paymentStatus.success.join(
              ","
            )}`
          );
          return fallBackCb("Too many request");
        }
        console.log(
          `CardPayment :- re calling api to get valid confirm payment status i.e ${paymentStatus.success.join(
            ","
          )}`
        );
        // Do not remove setTimeout otherwise recursion will not wait for api call to complete
        // This might be because of event loop
        setTimeout(() => {
          confirmPaymentStatus({ successCb, fallBackCb, paymentId });
        }, 2);
      }
    } catch (e) {
      console.log(`CardPayment :- Error in verifying payment`);
      throw e;
    }
  };

  return { confirmPaymentStatus };
}

export default useConfirmPayment;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  CardContent,
  Typography,
  Grid,
  Card,
  Button,
  Tabs,
  Tab,
  TextField,
  Box,
} from "@mui/material";
import Currency from "@components/currency";
import CircleButton from "@components/circle-button";
import { titleText, customAmountText } from "./styles";
import RoundedTextField from "@components/rounded-text-field";
import CustomTipInput from "@components/custom-tip-input";
import { useSelector, useDispatch } from "react-redux";
import { initialValues, tipFormSchema, tipPrecision } from "./constants";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { actions as TeamActions } from "@store/team/reducer";
import { OrderActions } from "@store/order/reducer";
import { TipActions } from "@store/tip/reducer";

function TipForm({ variant }) {
  const { product } = useSelector((state) => state.ui);

  const { tipsDisplayLabel, tipsDefaultValue } = useSelector(
    (state) => state.team
  );
  const { tipsDisplayLabel: staffTipLabel } = useSelector(
    (state) => state.staff
  );
  const { tipsAmount, totalOrderAmount, serviceChargeAmount } = useSelector(
    (state) => state.order
  );

  const [showCustomTipInput, toggleCustomTipInputVisibility] = useState(
    product === "fetchPay" ? true : false
  );

  const containerRef = React.useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const payTipAmount = (values) => {
    dispatch(TeamActions.updateTeamPaymentInfo(values));
    // dispatch(CheckoutActions.updateContinueToPay(true));
    navigate("/payment/card");
  };

  const form = useFormik({
    initialValues: initialValues(0),
    validationSchema: tipFormSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: payTipAmount,
  });

  const onTipAmountChange = (tipAmount, name) => {
    form.handleChange({ target: { name: "tipAmount", value: tipAmount } });
    form.handleChange({ target: { name, value: tipAmount } });

    if (!tipAmount) {
      return;
    }

    dispatch(
      OrderActions.updateTipsAmount({
        selectedTipsOption: 0,
        tipsAmount: tipAmount,
      })
    );
  };

  const toggleCustomTip = () => {
    if (showCustomTipInput) {
      dispatch(
        OrderActions.updateTipsAmount({
          selectedTipsOption: 0,
          tipsAmount: values.preDefinedTip,
        })
      );
    } else {
      if (!Boolean(values.customTip)) {
        dispatch(
          OrderActions.updateTipsAmount({
            selectedTipsOption: 0,
            tipsAmount: 0,
          })
        );
      } else {
        dispatch(
          OrderActions.updateTipsAmount({
            selectedTipsOption: 0,
            tipsAmount: values.customTip,
          })
        );
      }
    }

    toggleCustomTipInputVisibility((state) => !state);
  };
  const { values } = form;

  const onCustomFieldChange = ({ target }, data) => {
    const { value, name } = target;
    const { customFields } = form.values;
    const newVal = { ...customFields, [name]: { ...data, value } };
    dispatch(TipActions.updateCustomFields(newVal));
    form.handleChange({ target: { name: "customFields", value: newVal } });
  };

  useEffect(() => {
    if (product !== "fetchPay") {
      dispatch(
        OrderActions.updateTipsAmount({
          selectedTipsOption: 0,
          tipsAmount: 0,
        })
      );
    }
  }, []);

  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container rowGap={2}>
              <Grid item xs={12}>
                <Typography
                  variant="subText5"
                  align="center"
                  sx={titleText}
                  mb="15px"
                >
                  {variant === "team" ? tipsDisplayLabel : staffTipLabel}
                </Typography>
              </Grid>
              {!showCustomTipInput && (
                <>
                  {tipsDefaultValue.map((amount) => (
                    <Grid item xs={4}>
                      <CircleButton
                        color="secondary"
                        selected={amount === values?.preDefinedTip}
                        onClick={() =>
                          onTipAmountChange(amount, "preDefinedTip")
                        }
                        fullWidth
                      >
                        <Typography variant="subHeading6">
                          <Currency value={amount} />
                        </Typography>
                      </CircleButton>
                    </Grid>
                  ))}
                </>
              )}
              {showCustomTipInput && (
                <Grid item xs={12}>
                  <CustomTipInput
                    animationRef={containerRef}
                    onChange={onTipAmountChange}
                    value={values?.customTip}
                  />
                </Grid>
              )}
              {product !== "fetchPay" && (
                <Grid item xs={12}>
                  <Typography
                    variant="subTitle4"
                    align="center"
                    sx={customAmountText}
                  >
                    <Box component="span" onClick={toggleCustomTip} mt="15px">
                      {showCustomTipInput
                        ? "Use predefined amount"
                        : "Leave Custom Amount"}
                    </Box>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} mt="9px">
        <RoundedTextField
          placeholder={
            product !== "fetchPay"
              ? "Special Mention (leave a message!)"
              : "Payment reference"
          }
          sx={titleText}
          onChange={(e) => onCustomFieldChange(e, { name: "specialMention" })}
          fullWidth
        ></RoundedTextField>
      </Grid>
    </Grid>
  );
}

TipForm.propTypes = {};

export default TipForm;

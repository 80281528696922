export const thankYouMessage = (type) => {
  if (type === "tipping") {
    return type;
  }
  if (type === "pooled_tipping") {
    return "tipping";
  }

  return `the ${type}`;
};

import React from "react";
import PropTypes from "prop-types";
import TeamComment from "@components/team-comment";
import TeamMemberSelection from "@components/team-member-selection";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import Tips from "@components/tips";
import Padding from "@components/padding";
import FloatingButton from "@components/floating-button";
import { actions as CheckoutActions } from "@store/checkout/reducer";
import { actions as TeamActions } from "@store/team/reducer";
import { useFormik } from "formik";
import { initialValues, tipFormSchema, tipPrecision } from "./constants";
import Currency from "@components/currency";
import { navigate, useNavigate } from "react-router-dom";

function TipsForm({ variant }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isPartialTippingEnable, staff } = useSelector((state) => state.team);
  const { currencySymbol } = useSelector((state) => state.outlet);
  const { tipsAmount, totalOrderAmount, serviceChargeAmount } = useSelector(
    (state) => state.order
  );
  const showPartialPaymentSelection =
    isPartialTippingEnable && Boolean(staff.length);

  const payTipAmount = (values) => {
    dispatch(TeamActions.updateTeamPaymentInfo(values));
    // dispatch(CheckoutActions.updateContinueToPay(true));
    navigate("/payment/card");
  };

  const form = useFormik({
    initialValues: initialValues(tipsAmount),
    validationSchema: tipFormSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: payTipAmount,
  });

  const message = `A service charge of ${currencySymbol}${parseFloat(
    serviceChargeAmount
  ).toFixed(tipPrecision)} has been added to your total`;

  // if (serviceCharge?.serviceChargeFeePercentage) {
  //   message.push(`${serviceCharge?.serviceChargeFeePercentage}%`);
  // } else {
  //   message.push(`0%`);
  // }

  // if (Boolean(serviceCharge?.serviceChargeFeeAmount)) {
  //   message.push(
  //     " + ",
  //     `${currencySymbol}${serviceCharge?.serviceChargeFeeAmount}`
  //   );
  // }

  // message.push(" will be added to your total");

  return (
    <Padding pt={variant === "team" ? 4.8 : 1} pb={3}>
      <Grid container rowGap={4}>
        <Grid item xs={12}>
          <Tips onChange={form.handleChange} values={form.values?.tipAmount} />
        </Grid>
        {!isPartialTippingEnable && variant === "team" && (
          <Grid item xs={12}>
            <TeamComment onChange={form.handleChange} value={form.values} />
          </Grid>
        )}
        {showPartialPaymentSelection && variant === "team" && (
          <Grid item xs={12}>
            <TeamMemberSelection
              onChange={form.handleChange}
              values={form.values}
            />
          </Grid>
        )}
        <Grid item xs={12} mt={3}>
          <FloatingButton
            position="relative"
            onClick={form.handleSubmit}
            error={form?.errors?.tipAmount}
            sx={{ width: "calc(100% + 32px)" }}
            message={Boolean(serviceChargeAmount) ? message : ""}
            buttonProps={{
              fullWidth: true,
              disabled: !Boolean(totalOrderAmount),
            }}
          >
            Add Tip{" "}
            {totalOrderAmount > 0 ? (
              <Currency value={totalOrderAmount} precision={tipPrecision} />
            ) : null}
          </FloatingButton>
        </Grid>
      </Grid>
    </Padding>
  );
}

TipsForm.propTypes = {
  variant: PropTypes.string.isRequired,
};

TipsForm.defaultProps = {
  variant: "staff",
};

export default TipsForm;

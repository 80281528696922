import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import PageHeader from "@components/page-header";

import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getTeam } from "@store/team/api";
import TeamOverview from "@components/team-overview";
import Loading from "@components/loading";

function UserLandingPage(props) {
  const { teamId } = useParams();
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.team);

  const handleFailure = (error) => {
    console.log(error);
    // navigate("/not-found");
  };

  useEffect(() => {
    dispatch(getTeam(teamId)).catch(handleFailure);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Grid container rowGap={4}>
      <Grid item xs={12}>
        <PageHeader />
      </Grid>
      <Grid item xs={12}>
        <TeamOverview />
      </Grid>
    </Grid>
  );
}

UserLandingPage.propTypes = {};

export default UserLandingPage;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Typography,
  CardContent,
  Card,
  Box,
  CircularProgress,
} from "@mui/material";
import Padding from "@components/padding";
import FloatingButton from "@components/floating-button";
import SliderInput from "@components/slider-input";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { action as UIActions } from "@store/ui/reducer";
import { addTipFeedback } from "@graphql/tips/add-tip-feedback";
import { useFormik } from "formik";
import * as yup from "yup";
import useTipTo from "@hooks/use-tip-to";
import { lighten } from "@mui/material/styles";
import queryString from "query-string";
import { getTeamDetail } from "@graphql/team/hooks";
import { getTeam } from "@store/team/api";

const validationSchema = yup.object({
  feedback: yup.string().trim().label("Feedback"),
  serviceRating: yup.number().required().nullable().label("Rating"),
});

function FeedbackForm(props) {
  const { search } = window.location;
  const [disabled, toggleDisabled] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { id, staffId, teamId } = queryString.parse(search);
  // const { id } = useSelector((state) => state.tip);
  const tipType = useTipTo(2);
  const dispatch = useDispatch();
  const feedbackSubmitted = window.localStorage.getItem("feedbackSubmitted");
  const { feedbackOptions, comment, feedbackSetting } = useSelector(
    (state) => state.feedback
  );

  const send = (values) => {
    setSubmitting(true);
    const tipPayload = {};

    if (staffId) {
      tipPayload["staffId"] = Number(staffId);
    }

    if (teamId) {
      tipPayload["teamId"] = Number(teamId);
    }

    addTipFeedback({
      tipPaymentId: Number(id),
      ...values,
      ...tipPayload,
    })
      .then(() => {
        setSubmitted(true);
        window.localStorage.setItem("feedbackSubmitted", "true");
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(
          UIActions.setSnackBarError(
            error?.message || "Unable to save feedback. Try again later"
          )
        );
      });
  };

  const form = useFormik({
    initialValues: { feedback: "", serviceRating: 0 },
    validationSchema: validationSchema,
    onSubmit: send,
  });

  const {
    handleChange: onChange,
    values,
    errors,
    handleSubmit,
    setValues,
  } = form;

  const disableSubmitButton =
    !Boolean(values?.serviceRating) && !Boolean(values?.feedback);

  useEffect(() => {
    if (!Object.keys(feedbackSetting).length) {
      dispatch(getTeam(teamId));
    }
  }, []);

  if (!feedbackSetting?.isFeedbackEnabled) {
    return null;
  }

  if (submitting || feedbackSubmitted) {
    return (
      <Card
        sx={{
          height: 248,
          mt: 3,
          backgroundColor: (theme) =>
            lighten(theme.palette.primary.main, 0.183),
        }}
      >
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="210px"
          >
            {submitted || Boolean(feedbackSubmitted) ? (
              <>
                <Typography variant="subHeading4" align="center">
                  Thank you
                </Typography>
                <Typography variant="subTitle3" align="center">
                  Your feedback has been sent
                </Typography>
              </>
            ) : (
              <CircularProgress
                color="secondary"
                sx={{
                  color: (theme) => theme.palette.primary.contrastText,
                }}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {feedbackOptions.map((item) => (
          <Grid item xs={12} style={{ marginBottom: "7px" }}>
            <SliderInput
              title={item.title}
              onChangeCommitted={(e, value) =>
                onChange({
                  target: { name: `${item.name}`, value: value / 10 },
                })
              }
              name={item.key}
              defaultValue={item.rating}
            />
          </Grid>
        ))}
        {/* <SliderInput
          disabled={disabled}
          onChangeCommitted={(_, value) =>
            onChange({ target: { name: "serviceRating", value } })
          }
        /> */}
      </Grid>
      <Grid item xs={12} mt={0}>
        <TextField
          name="feedback"
          placeholder="Additional Feedback (optional)"
          value={values?.feedback}
          onChange={onChange}
          variant="standard"
          error={Boolean(errors?.feedback)}
          helperText={Boolean(errors?.feedback) && errors?.feedback}
          sx={{
            "&.MuiInput-input": {
              paddingLeft: 0,
              paddingBottom: "5px",
            },
          }}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} mt={3}>
        <FloatingButton
          sx={{
            width: "100",
            padding: "11px",
            backgroundColor: (theme) => `${theme.palette.primary.contrastText}`,
            color: (theme) => theme.palette.primary.main,
            "&:hover": {
              backgroundColor: (theme) =>
                `${theme.palette.primary.contrastText}`,
            },
          }}
          onClick={handleSubmit}
          buttonProps={{ disabled: disableSubmitButton }}
        >
          Submit Feedback
        </FloatingButton>
      </Grid>
    </Grid>
  );
}

FeedbackForm.propTypes = {};

export default FeedbackForm;

import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  FilledInput as MuiTextField,
  Box,
  Slide,
  ClickAwayListener,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Currency from "@components/currency";

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    color: theme.palette.primary.contrastText,
    backgroundColor: `${theme.palette.tertiary.main} !important`,
    borderRadius: "2rem",
    "&::before": {
      border: "none !important",
    },
    "&::after": {
      border: "none !important",
    },
    "& input": {
      padding: "0 20px",
      textAlign: "center",
      fontWeight: 600,
      fontSize: "3.75rem",
      color: theme.palette.primary.contrastText,
    },
    "& .Mui-focused": {
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.primary.contrastText,
    },
  };
});

const inputProps = {
  type: "currency",
  pattern: "d*",
  inputmode: "decimal",
  id: "paymentAmt",
};

function CustomTipInput({ animationRef, onChange, value, ...props }) {
  const [focused, toggleFocus] = useState(false);

  const onTipChange = (event) => {
    const { target } = event;
    const { value } = target;
    const [int, decimal] = String(value).split(".");
    if ((value !== "." && isNaN(value)) || value < 0 || value > 99999.99) {
      return;
    }

    if (decimal && decimal.length > 2) {
      return;
    }
    onChange(value, "customTip");
  };

  const onBlur = (e) => {
    if (!e.target.value) {
      onTipChange({ target: { value: "0" } });
    }
    toggleFocus(false);
  };

  return (
    <Slide direction="down" timeout={1000} container={animationRef.current} in>
      <Box height="88px">
        <>
          {focused && (
            <TextField
              {...props}
              variant="standard"
              inputProps={inputProps}
              value={value}
              type="number"
              onChange={onTipChange}
              onBlur={onBlur}
              sx={{
                backgroundColor: (theme) => `#242424 !important`,
              }}
              autoFocus
            />
          )}
          {!focused && (
            <Typography
              variant="h3"
              onClick={() => toggleFocus(true)}
              align="center"
              color={(theme) => theme.palette.primary.contrastText}
            >
              <Currency value={value} />
            </Typography>
          )}
        </>
      </Box>
    </Slide>
  );
}

CustomTipInput.propTypes = {};

export default CustomTipInput;

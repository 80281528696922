// ==============================|| OVERRIDES - MUI CHECKBOX ||============================== //

export default function MuiCheckbox() {
  return {
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiSvgIcon-root": {
            color: `${theme.palette.primary.contrastText} !important`,
          },
        }),
      },
    },
  };
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  otpRequested: false,
};
const signInPage = createSlice({
  name: "signInPage",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    updateOtpRequested: (state, { payload }) => ({
      ...state,
      otpRequested: payload,
    }),
  },
});

export const actions = signInPage.actions;

export default signInPage;

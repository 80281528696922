import React from "react";
import PropTypes from "prop-types";
import OutletOverview from "@components/outlet-overview";
import { Grid, CardContent, Card, Box } from "@mui/material";
import ThankYouMessage from "@components/thank-you-message";
import EmailForm from "@components/email-form/EmailForm";
import FeedbackForm from "@components/feedback-form";
import Background from "@components/background";
import FetchLogo from "@components/fetch-logo";
import FooterLogo from "@components/footer-logo";
import Feedback from "@root/feature/v2/feedback/feedback";

function FeedbackPage(props) {
  return (
    <Background>
      <Grid container rowGap={2} sx={{ minHeight: "100vh" }}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mb={8} mt={2}>
            <FetchLogo />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ marginTop: 4, borderRadius: " 2rem 2rem 0 0" }}>
            <CardContent sx={{ padding: "1.25rem" }}>
              <EmailForm />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent
              sx={{
                padding: "1.25rem",
                marginTop: 4,
                borderRadius: " 2rem 2rem 0 0",
              }}
            >
              <ThankYouMessage />
              <Feedback />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <FooterLogo />
          </Box>
        </Grid>
      </Grid>
    </Background>
  );
}

FeedbackPage.propTypes = {};

export default FeedbackPage;

import React, { useState } from "react";
import { CREATE_TIP_FEEDBACK } from "./query";
import GraphqlClient from "@services/GraphqlService";

const GET_VARIABLE = ({ fields: input, teamId }) => ({
  variables: {
    input,
    teamId,
  },
});

export const addCustomFieldFeedback = (input) => {
  return GraphqlClient.get(CREATE_TIP_FEEDBACK, GET_VARIABLE(input))
    .then(({ data, errors }) => {
      if (errors) {
        throw errors[0];
      }
      const dataResponse = data?.tipFeedback ?? {};
      return dataResponse;
    })
    .catch((error) => {
      throw error;
    });
};

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TipHistoryCard from "@components/tip-history-card";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { getMonthsStartAndEndDate } from "@root/util/dates";
import { getTeam, getTipsInformation } from "@store/team/api";
import Loading from "@components/loading";

function TeamTipHistory(props) {
  const { teamId } = useParams();

  const dispatch = useDispatch();
  const {
    isLoading,
    error,
    tips,
    id,
    teamImageUrl,
    teamName,
    tipsTotal,
    // outlet: { outletId },
  } = useSelector((state) => state.team);

  const { startDate, endDate } = getMonthsStartAndEndDate();

  const handleFailure = (error) => {
    console.log(error);
    // navigate("/not-found");
  };

  const getTipsHistoryInformation = (team) => {
    const { outlet, id: teamId } = team;
    const { id: outletId } = outlet;

    dispatch(
      getTipsInformation({
        startDate,
        endDate,
        teamId,
        outletId: parseInt(outletId),
      })
    );
  };

  useEffect(() => {
    dispatch(getTeam(teamId))
      .then(getTipsHistoryInformation)
      .catch(handleFailure);
  }, []);

  if (isLoading || !tips) {
    return <Loading />;
  }

  if (error) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TipHistoryCard
          name={teamName}
          totalTipsAmount={tipsTotal}
          tips={tips}
          imageUrl={teamImageUrl}
          variant="team"
        />
      </Grid>
    </Grid>
  );
}

TeamTipHistory.propTypes = {};

export default TeamTipHistory;

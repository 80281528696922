import React from "react";
import PropTypes from "prop-types";
import { TextField, Grid } from "@mui/material";
import Padding from "@components/padding";

function TeamComment({ onChange, value }) {
  const errors = {};
  const inputLength = value?.specialMessage
    ? String(value?.specialMessage).length
    : 0;

  const label = "Special Mention (leave a message for our staff!)";
  return (
    <Grid container>
      <Grid xs={12} item>
        <TextField
          name="specialMessage"
          label={Boolean(inputLength) ? label : ""}
          placeholder={label}
          value={value?.specialMessage}
          onChange={onChange}
          variant="standard"
          error={Boolean(errors?.specialMessage)}
          helperText={Boolean(errors?.specialMessage) && errors?.specialMessage}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

TeamComment.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TeamComment;

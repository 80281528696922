import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const fetchMyTipsLogo = "/assets/images/fetch-logo-grey.svg";
const fetchPayFooter = "/assets/images/fetch-pay-footer.svg";
const fetchFooterLogo =
  "https://fmb-assets-prod.s3.eu-west-1.amazonaws.com/fmb-prod-multimedia/4968b180-5b85-11ee-a6c9-7d5f0bdc8e7a.Fetch_Logo.png";

function FooterLogo() {
  const { product } = useSelector((state) => state.ui);
  const logo = product === "fetchPay" ? fetchPayFooter : fetchMyTipsLogo;

  return <img src={fetchFooterLogo} alt="grey-logo" height="14px" />;
}

export default FooterLogo;

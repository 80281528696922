import React from "react";
import { Box } from "@mui/material";
import OutletOverview from "@components/outlet-overview";
import FetchLogo from "@components/fetch-logo";

function PageHeader({ type }) {
  return (
    <>
      {type === "team" && <OutletOverview />}
      <Box display="flex" justifyContent="center" pt={2} pb={4}>
        <FetchLogo variant="dark" height="17px" />
      </Box>
    </>
  );
}

export default PageHeader;

import UAParser from "ua-parser-js";

const appleDevices = ["iOS", "macOS", "Mac OS"];

export const detectDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  let parser = new UAParser(navigator.userAgent); // you need to pass the user-agent for nodejs
  const { name: osName } = parser.getOS();
  const { name: browserName } = parser.getBrowser();

  if (appleDevices.includes(osName) && browserName.includes("Safari")) {
    return "iOS";
  }

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  return "unknown";
};

import React from "react";
import PropTypes from "prop-types";
import { Box, Modal, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "./styles";

function NoPaymentGatewayModal({ classes }) {
  const handleClose = () => {};

  return (
    <Modal open onClose={handleClose}>
      <Box className={classes.modalStyle}>
        <Box paddingTop={1}>
          <img src="/assets/images/oops.png" style={{ height: "3rem" }} />
        </Box>
        <Box style={{ padding: "0px 16px" }}>
          <Typography align="center" className={classes.desc}>
            This venue has not completed payment setup yet. Please check with
            the venue manager
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
}

NoPaymentGatewayModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(NoPaymentGatewayModal);

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { TipActions } from "@store/tip/reducer";
import { errorIfTouched } from "@root/util/form";
import RoundedTextField from "@components/rounded-text-field";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { OrderActions } from "@store/order/reducer";
import { useFormik } from "formik";
import { initialValues, formSchema } from "./constants";

const getPlaceholder = ({ label, promptMessage }) => {
  let placeholder = "";
  if (promptMessage) {
    placeholder = promptMessage;
  }
  return placeholder;
};

function CustomFields(props) {
  const { customFields = [] } = useSelector((state) => state.team);
  const { customFieldsError } = useSelector((state) => state.tip);
  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: initialValues(customFields),
    validationSchema: formSchema(customFields),
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: console.log,
  });

  const onCustomFieldChange = ({ target }, data) => {
    const { value, name } = target;
    const newVal = { ...form.values, [name]: { ...data, value } };
    dispatch(TipActions.updateCustomFields(newVal));
    form.handleChange({
      target: { name, value: { value, ...data } },
    });
  };

  const onCustomFieldBlur = (e) => {
    form.handleBlur(e);
    onPaymentReferenceChange(e);
  };

  const onPaymentReferenceChange = ({ target }) => {
    const { name, value } = target;
    if (name !== "paymentReference") {
      return;
    }
    dispatch(OrderActions.updatePaymentReference(value));
  };

  const { errors, values, touched } = form;

  useEffect(() => {
    const hasError = Boolean(Object.keys(errors).length);
    if (customFieldsError !== hasError) {
      dispatch(TipActions.updateCustomFieldsError(hasError));
    }
  }, [errors]);

  return customFields
    .filter(({ isEnable }) => isEnable)
    .map((field) => (
      <Grid item xs={12} mt="9px">
        <RoundedTextField
          placeholder={`${getPlaceholder(field)} ${
            field.isMandatory ? "*" : ""
          }`}
          name={`${field.name}`}
          onChange={(e) => onCustomFieldChange(e, field)}
          onBlur={onCustomFieldBlur}
          error={Boolean(errorIfTouched(form, field.name))}
          helperText={errorIfTouched(form, field.name)}
          sx={{ color: ({ palette }) => palette.textShades.title }}
          fullWidth
        ></RoundedTextField>
      </Grid>
    ));
}

CustomFields.propTypes = {};

export default CustomFields;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardPayment from "./card-payment";
import PaymentButton from "./payment-button";
import { useStripeIntent } from "@hooks";
import { Grid, Button, Typography, Box } from "@mui/material";
import StripeButton from "./stripe-button";
import Collapse from "@mui/material/Collapse";
import { useSelector } from "react-redux";
import { alpha } from "@mui/material/styles";
import useIsAllowedToPay from "@hooks/is-allowed-to-pay";
import { detectDevice } from "@root/util/device";
import { buttons } from "./constants";

const PUB_KEY = `${process.env.STRIPE_PUB_KEY}`;
const stripePromise = loadStripe(PUB_KEY);
const stripeElementsOptions = {
  mode: "payment",
  amount: 3123, // Dummy amount for elements to load
  currency: "usd",
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

function Stripe({ showCardPayment, handleTogglePaymentMode, ...props }) {
  const [error, setError] = useState(null);
  const { handlePaymentIntent } = useStripeIntent(props.totalAmount);
  const { currencySymbol } = useSelector((state) => state.outlet);
  const { isAllowedToPay } = useIsAllowedToPay();
  const { serviceChargeAmount } = useSelector((state) => state.order);
  const { selectedPaymentMode } = useSelector((state) => state.payment);

  const message = `A service fee of ${currencySymbol}${parseFloat(
    serviceChargeAmount
  ).toFixed(2)} has been added to your total`;

  return (
    <Elements
      stripe={stripePromise}
      options={stripeElementsOptions}
      id="payment-service"
    >
      <Grid container rowGap={5} mt={3}>
        {selectedPaymentMode === "paymentButton" && (
          <Grid item xs={12}>
            {Boolean(serviceChargeAmount) && (
              <Typography
                variant="subText6"
                align="center"
                color={(theme) =>
                  alpha(theme.palette.primary.contrastText, 0.6)
                }
                mt={2}
                mb={2}
              >
                {message}
              </Typography>
            )}
            <PaymentButton
              handlePaymentIntent={handlePaymentIntent}
              {...props}
              setError={setError}
            >
              <StripeButton error={error} />
            </PaymentButton>
          </Grid>
        )}
        {selectedPaymentMode === "card" && (
          <Grid item xs={12} id="card-payment">
            <Typography variant="subHeading5">Payment details</Typography>
            <Typography
              variant="subTitle6"
              color="rgba(255, 255, 255, 0.60)"
              mb={5}
            >
              All payments are secure and encrypted
            </Typography>

            <CardPayment
              handlePaymentIntent={handlePaymentIntent}
              {...props}
              serviceFeeMessage={Boolean(serviceChargeAmount) ? message : null}
            />
          </Grid>
        )}
      </Grid>
    </Elements>
  );
}

Stripe.propTypes = {
  showCardPayment: PropTypes.bool,
};

Stripe.defaultProp = {
  showCardPayment: false,
};

export default Stripe;

import React from "react";
import QRNotAvailable from "@components/qr-not-available";
import FullHeightLayout from "@root/layouts/FullHeightLayout";
import { Box } from "@mui/material";

function UnavailableQR(props) {
  return (
    <FullHeightLayout className="qr-not-available-page">
      <Box display="flex" alignItems="center" height="100%">
        <QRNotAvailable />
      </Box>
    </FullHeightLayout>
  );
}

export default UnavailableQR;

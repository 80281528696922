import React from "react";
import PropTypes from "prop-types";
import { Grid, Box, Avatar, Typography } from "@mui/material";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import FloatingButton from "@components/floating-button";
import { RoundedButton } from "@components/elements";
import { QRBox } from "./style";
import { useKeycloak } from "@react-keycloak/web";

function TeamOverview({ variant }) {
  const { keycloak } = useKeycloak();
  const { userId } = useParams();
  const navigateTo = useNavigate();
  const {
    teamImageUrl,
    id,
    teamName,
    qrCode: { qrCodeImageSVG, qrCodeImagePNG },
  } = useSelector((state) => state.team);
  const { isQREnable } = useSelector((state) => state.staff);

  const navigateToTipsHistory = () => navigateTo(`/team-tips-history/${id}`);

  return (
    <Grid container rowGap={5}>
      <Grid xs={5} item>
        <Box display="flex" justifyContent="center">
          <Avatar
            alt={teamName}
            src={teamImageUrl}
            sx={{ width: 100, height: 100 }}
          />
        </Box>
      </Grid>
      <Grid item xs={7}>
        <Typography variant="h2" fontWeight={500} gutterBottom>
          {teamName}
        </Typography>

        <Box width="138px">
          <RoundedButton
            variant="outlined"
            onClick={navigateToTipsHistory}
            fullWidth
          >
            My Team Tips
          </RoundedButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <QRBox minHeight="180px" minWidth="180px">
            <img src={qrCodeImagePNG} alt="team-qr" height="180" />
          </QRBox>
        </Box>
      </Grid>
      {isQREnable && (
        <Grid xs={12} item>
          <Box display="flex" justifyContent="center">
            <Box width="186px">
              <RoundedButton
                variant="outlined"
                onClick={() => navigateTo(`/user/${userId}`)}
                fullWidth
              >
                Go To My Profile
              </RoundedButton>
            </Box>
          </Box>
        </Grid>
      )}
      <FloatingButton onClick={() => keycloak.logout()}>Log Out</FloatingButton>
    </Grid>
  );
}

TeamOverview.propTypes = {};

export default TeamOverview;

import { Box } from "@mui/material";
import { formatDate } from "@root/util/dates";
import Currency from "@components/currency";
import { Then, Else, If } from "react-if";
import { tipPrecision } from "@components/tips-form/constants";

export const getTimelineNodes = (list) => {
  const emptyList = Array(5).fill(null);
  const node = [...list, ...emptyList].filter((item, index) => index < 5);

  return node.map((item) => {
    return Boolean(item) ? (
      <Box
        backgroundColor="#d0cccc5e"
        borderRadius="10px"
        position=" absolute"
        width="75%"
        top="-5px"
        p={1.2}
        pl="35px"
      >
        <Currency value={item.tipAmount} precision={tipPrecision} />
        <Box color="#8f8f8f" component="span">
          {" "}
          - {formatDate(new Date(item.paymentDate), "MMMM do yyyy")}
        </Box>
      </Box>
    ) : (
      <Box
        backgroundColor="#d0cccc5e"
        borderRadius="10px"
        position=" absolute"
        width="75%"
        top="-5px"
        p={1.2}
        pl="35px"
        height="20px"
      >
        {" "}
      </Box>
    );
  });
};

import { getTeamDetail } from "@graphql/team/hooks";
import { actions as TeamActions } from "./reducer";
import { actions as OutletActions } from "@store/outlet/reducer";
import { getTips, getTipsTotal } from "@graphql/tips/hooks";
import { OrderActions } from "@store/order/reducer";
import { getConfiguration } from "@graphql/configuration/Configuration";
import { feedbackActions } from "@store/feedback/reducer";

const extractDefaultPaymentLabels = {};

export const getTeam = (input) => async (dispatch) => {
  dispatch(TeamActions.startLoading());
  try {
    const getTeamResponse = await getTeamDetail(input);
    const getConfigurationResponse = await getConfiguration("TEAM", input);
    const { outlet, tipsDefaultValue, isFeedbackEnabled } = getTeamResponse;
    const team = getConfigurationResponse?.team ?? {};
    const { defaultPaymentLabels = [] } = team;

    let paymentLabels = tipsDefaultValue.map((item) => ({
      label: "",
      amount: item,
    }));

    if (defaultPaymentLabels.length === 3) {
      paymentLabels = defaultPaymentLabels;
    }

    //Setting up value in redux-team-store
    dispatch(TeamActions.updateTeam({ ...getTeamResponse, paymentLabels }));
    dispatch(OutletActions.updateOutlet(outlet));
    dispatch(OrderActions.updateServiceCharge(outlet?.serviceCharge));
    dispatch(
      feedbackActions.updateFeedbackSetting({
        isFeedbackEnabled,
      })
    );
    return { ...getTeamResponse, ...getConfigurationResponse, paymentLabels };
  } catch (error) {
    dispatch(TeamActions.updateError(error));
    throw error;
  } finally {
    dispatch(TeamActions.endLoading());
  }
};

export const getTipsInformation = (input) => async (dispatch) => {
  const { teamId, startDate, endDate, outletId } = input;
  try {
    const lastFiveTips = await getTips({
      teamId,
      outletId,
      startDate: "1992-01-20",
      endDate: "2092-01-31",
      page: { pageNumber: 1, perPage: 5 },
    });
    const tipsTotal = await getTipsTotal({
      startDate,
      endDate,
      teamId,
    });
    dispatch(
      TeamActions.updateTipsInformation({ tips: lastFiveTips, tipsTotal })
    );
  } catch (e) {
    dispatch(TeamActions.updateError(e));
    throw e;
  } finally {
    dispatch(TeamActions.endLoading());
  }
};

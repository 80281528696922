import { createTheme } from "@mui/material/styles";
import { lighten } from "@mui/material/styles";
import ComponentsOverrides from "./overrides";
import typography from "./typography";
import { grey } from "@mui/material/colors";

const fontFamily = ["'Manrope'", "sans-serif"].join(",");
const primaryMain = "#111111";
const secondaryMain = "#BE0358";

const textShades = {
  title: lighten(primaryMain, 0.473),
};

const theme = createTheme({
  fontFamily,
  mobile: "576px",
  palette: {
    mode: "dark",
    tonalOffset: 0.145,
    primary: {
      main: primaryMain,
      contrastText: "#fff",
    },
    secondary: {
      main: secondaryMain,
      contrastText: "#fff",
    },
    tertiary: {
      main: lighten(primaryMain, 0.08),
      secondary: lighten(primaryMain, 0.125),
    },
    textShades,
    text: {
      primary: "#fff",
    },
    success: {
      main: "#34A853",
    },
  },

  typography: typography(fontFamily),
});

theme.components = ComponentsOverrides(theme);

export default theme;

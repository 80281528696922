import React from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";
import Typography from "@components/typography";
import FloatingButton from "@components/floating-button";
import { useSelector } from "react-redux";

const color = { color: "#5A5A5A" };
function UnexpectedError({ hideButton, message }) {
  const { landingPageURL } = useSelector(({ ui }) => ui);
  return (
    <Grid container rowSpacing={14} alignItems="center">
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <img
            src="/assets/images/fetch-logo-black.svg"
            alt="fetch-logo-black"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{ fontSize: 140, fontWeight: 700, ...color }}
          align="center"
        >
          !
        </Typography>
        {message.map((text) => (
          <Typography variant="subHeading5" align="center" sx={color}>
            {text}
          </Typography>
        ))}
        {/* 
        <Typography variant="h2" align="center" sx={color}>
          // Please try again later. //{" "}
        </Typography> */}
      </Grid>
      <Grid item xs={12}>
        {!hideButton && (
          <FloatingButton position="unset">
            <Typography
              variant="subHeading5"
              onClick={() => window.location.replace(`${landingPageURL}`)}
            >
              Go To Homepage
            </Typography>
          </FloatingButton>
        )}
      </Grid>
    </Grid>
  );
}

UnexpectedError.prototype = {
  message: PropTypes.array,
  hideButton: PropTypes.bool,
};

UnexpectedError.defaultProps = {
  message: ["Something went wrong.", "Please try again later."],

  hideButton: false,
};

export default UnexpectedError;

const fontWeight = {
  bold: 700,
  semiBold: 600,
  medium: 500,
  regular: 400,
};

const heading = (fontFamily) => ({
  h1: {
    fontFamily,
    fontWeight: fontWeight.semiBold,
    fontSize: "6.25rem",
  },
  h2: {
    fontFamily,
    fontWeight: fontWeight.semiBold,
    fontSize: "4.375rem",
  },
  h3: {
    fontFamily,
    fontWeight: fontWeight.semiBold,
    fontSize: "3.75rem",
  },
  h4: {
    fontFamily,
    fontWeight: fontWeight.semiBold,
    fontSize: "2.5rem",
  },
  h5: {
    fontFamily,
    fontWeight: fontWeight.semiBold,
    fontSize: "2.5rem",
  },
  h6: {
    fontFamily,
    fontWeight: fontWeight.semiBold,
    fontSize: "1.125rem",
  },
});

const subHeading = (fontFamily) => ({
  subHeading1: {
    fontFamily,
    fontWeight: fontWeight.bold,
    fontSize: "2.625rem",
  },
  subHeading2: {
    fontFamily,
    fontWeight: fontWeight.bold,
    fontSize: "2.188rem",
  },
  subHeading3: {
    fontFamily,
    fontWeight: fontWeight.bold,
    fontSize: "2rem",
  },
  subHeading4: {
    fontFamily,
    fontWeight: fontWeight.bold,
    fontSize: "1.5rem",
  },
  subHeading5: {
    fontFamily,
    fontWeight: fontWeight.bold,
    fontSize: "1.25rem",
  },
  subHeading6: {
    fontFamily,
    fontWeight: fontWeight.bold,
    fontSize: "1.125rem",
  },
});

const subTitle = (fontFamily) => ({
  subTitle1: {
    fontFamily,
    fontWeight: fontWeight.medium,
    fontSize: "2rem",
  },
  subTitle2: {
    fontFamily,
    fontWeight: fontWeight.medium,
    fontSize: "1.25rem",
  },
  subTitle3: {
    fontFamily,
    fontWeight: fontWeight.medium,
    fontSize: "1.125rem",
  },
  subTitle4: {
    fontFamily,
    fontWeight: fontWeight.medium,
    fontSize: "0.875rem",
  },
  subTitle5: {
    fontFamily,
    fontWeight: fontWeight.medium,
    fontSize: "0.813rem",
  },
  subTitle6: {
    fontFamily,
    fontWeight: fontWeight.medium,
    fontSize: "0.75rem",
  },
});

const subText = (fontFamily) => ({
  subText1: {
    fontFamily,
    fontWeight: fontWeight.regular,
    fontSize: "2rem",
  },
  subText2: {
    fontFamily,
    fontWeight: fontWeight.regular,
    fontSize: "1.5rem",
  },
  subText3: {
    fontFamily,
    fontWeight: fontWeight.regular,
    fontSize: "1.125rem",
  },
  subText4: {
    fontFamily,
    fontWeight: fontWeight.regular,
    fontSize: "1rem",
  },
  subText5: {
    fontFamily,
    fontWeight: fontWeight.regular,
    fontSize: "0.875rem",
  },
  subText6: {
    fontFamily,
    fontWeight: fontWeight.regular,
    fontSize: "0.75rem",
  },
});

const Typography = (fontFamily) => ({
  fontSize: 12,
  fontFamily,
  ...heading(fontFamily),
  ...subHeading(fontFamily),
  ...subTitle(fontFamily),
  ...subText(fontFamily),
});

export default Typography;

import React from "react";
import { Grid, Typography, TextField, IconButton } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import FloatingButton from "@components/floating-button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { initialValues, loginFormSchema } from "./constants";

function LoginForm(props) {
  const navigateTo = useNavigate();
  const form = useFormik({
    initialValues,
    validationSchema: loginFormSchema,
    onSubmit: () => navigateTo("/profile/1"),
  });

  const { values, handleChange: onChange, errors } = form;

  return (
    <Grid container rowGap={4}>
      <Grid item xs={12}>
        <KeyboardBackspaceIcon onClick={() => navigateTo(-1)} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" color="404040" fontWeight="500">
          Log In
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="username"
          placeholder="User name *"
          value={values?.username}
          onChange={onChange}
          variant="standard"
          error={Boolean(errors?.username)}
          helperText={Boolean(errors?.username) && errors?.username}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="password"
          type="password"
          placeholder="Password *"
          value={values?.password}
          onChange={onChange}
          variant="standard"
          error={Boolean(errors?.password)}
          helperText={Boolean(errors?.password) && errors?.password}
          fullWidth
        />
      </Grid>
      <FloatingButton position="relative" onClick={form.handleSubmit}>
        Log In
      </FloatingButton>
    </Grid>
  );
}

LoginForm.propTypes = {};

export default LoginForm;

import * as yup from "yup";
import { min } from "mathjs";
import { styled } from "@mui/material/styles";
import { Link as MuiLink } from "@mui/material";

export const initialValues = (tipsAmount) => ({
  tipAmount: tipsAmount,
  specialMessage: "",
  selectedStaff: null,
  customTip: undefined,
  customFields: {},
  preDefinedTip: tipsAmount,
});

export const tipFormSchema = yup.object({
  tipAmount: yup.number().max(99999.99).required().label("Tip"),
});

export const tipPrecision = 2;

const inputStyle = {
  backgroundColor: "#424242",
  borderRadius: "21px",
  border: "0px",
  boxShadow: "none",
  height: "45px",
  "::placeholder": {
    color: "red",
  },
  marginBottom: "18px",
  marginTop: "10px",
  padding: "2px 10px",
  color: "#fff",
};

const inputLabelStyle = {
  color: " #fff",
  fontFamily: " Manrope",
  fontSize: " 14px",
  fontStyle: " normal",
  fontWeight: " 600",
  lineHeight: " normal",
  letterSpacing: " 0.28px",
};

export const getDojoConfig = ({ clientSecret, onIframeLoaded }) => ({
  paymentDetails: {
    paymentToken: clientSecret,
  },
  containerId: "dojo-card-payment",
  onIframeLoaded: (...params) => onIframeLoaded(true),
  fontCss: ["https://fonts.googleapis.com/css?family=Manrope"],
  text: {
    cardName: {
      label: "Name on card",
      placeholder: " ",
    },
    cardNumber: {
      placeholder: " ",
    },
    expiryDate: {
      placeholder: " ",
    },
    cv2: {
      placeholder: " ",
      label: "CVV",
    },
  },
  styles: {
    base: {
      focus: {
        outlineWidth: "0px",
        outlineColor: "none",
        outlineStyle: "none",
      },
    },

    cardNumber: {
      container: {
        width: "100%",
      },
      default: {
        ...inputStyle,
      },
      label: inputLabelStyle,
    },
    cardName: {
      label: inputLabelStyle,
      default: {
        ...inputStyle,
      },
      container: {
        width: "100%",
      },
    },
    cardIcon: { display: "none" },
    expiryDate: {
      label: inputLabelStyle,
      container: {
        width: "48%",
      },
      default: {
        ...inputStyle,
      },
    },
    cv2: {
      label: inputLabelStyle,
      container: {
        width: "48%",
      },
      default: {
        ...inputStyle,
      },
    },
    form: {
      display: "flex",
      flexWrap: "wrap",
      gap: "4%",
      fontFamily: "Manrope",
    },
  },
});

export const getDojoConfig = ({
  clientSecret,
  onIframeLoaded,
  setLoading,
}) => ({
  containerId: "demo-payment-wallet",
  paymentDetails: {
    paymentToken: clientSecret,
  },
  buttonConfig: {
    color: "white",
    type: "plain",
  },
  styles: {
    base: {
      width: "100vh",
    },
  },
  emailRequired: true,
  billingAddressRequired: false,
  shippingAddressRequired: false,
  onIframeLoadFailed: (e) => onIframeLoaded(true),
  onIframeLoaded: (e) => onIframeLoaded(true),
  callbacks: {
    onPaymentInitiated: () => setLoading(true),
    onPaymentCancelled: () => setLoading(false),
  },
});

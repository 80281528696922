import React, { useState } from "react";
import { CREATE_TIP_PAYMENT } from "./query";
import GraphqlClient from "@services/GraphqlService";

const GET_VARIABLE = (input) => ({
  variables: {
    input,
  },
});

export const createTipPayment = (input) => {
  return GraphqlClient.get(CREATE_TIP_PAYMENT, GET_VARIABLE(input))
    .then(({ data, errors }) => {
      if (errors) {
        throw errors[0];
      }
      const dataResponse = data?.createTipPayment ?? {};
      return dataResponse;
    })
    .catch((error) => {
      throw error;
    });
};

import React from "react";
import { useFormik } from "formik";
import { errorIfTouched } from "@root/util/form";
import {
  customerFeedbackSchema,
  MuiTextField,
  PhoneNumberInput,
} from "./constant";
import { Grid, Button, Typography } from "@mui/material";
import { PrimaryButton } from "@root/feature/v2/feedback/feedback/constant";
import RoundedTextField from "@components/rounded-text-field";

function CustomerDetail({ customerContactInfoSettings, ...props }) {
  const sendContactDetail = (values) => {
    props.sendContactDetail({ customerDetailFromValues: values });
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      tableId: "",
      customerNumber: "",
      customerEmail: "",
      isPosTableId: false,
    },
    validationSchema: customerFeedbackSchema(customerContactInfoSettings),
    onSubmit: sendContactDetail,
    validateOnMount: true,
  });

  const {
    isNameEnabled,
    isNameMandatory,
    isEmailEnabled,
    isEmailMandatory,
    isMobileNoEnabled,
    isMobileMandatory,
    isTableEnabled,
    isTableMandatory,
  } = customerContactInfoSettings;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" align="center">
          We’re sorry to hear this
        </Typography>
        <Typography variant="h6" align="center">
          please leave your details
        </Typography>
      </Grid>
      {isNameEnabled && (
        <Grid item xs={12}>
          <MuiTextField
            placeholder={`Name ${!isNameMandatory ? "(optional)" : ""}`}
            variant="standard"
            onChange={formik.handleChange}
            value={formik.values?.username}
            onBlur={formik.handleBlur}
            name="username"
            error={Boolean(errorIfTouched(formik, "username"))}
            helperText={errorIfTouched(formik, "username")}
            InputProps={{ disableUnderline: true }}
            fullWidth
          />
        </Grid>
      )}
      {isTableEnabled && (
        <Grid item xs={12}>
          <MuiTextField
            placeholder={`Table Number ${
              !isTableMandatory ? "(optional)" : ""
            }`}
            variant="standard"
            onChange={formik.handleChange}
            value={formik.values?.tableId}
            name="tableId"
            onBlur={formik.handleBlur}
            error={Boolean(errorIfTouched(formik, "tableId"))}
            helperText={errorIfTouched(formik, "tableId")}
            InputProps={{ disableUnderline: true }}
            fullWidth
          />
        </Grid>
      )}
      {isMobileNoEnabled && (
        <Grid item xs={12}>
          <MuiTextField
            placeholder={`Mobile Number ${
              !isMobileMandatory ? "(optional)" : ""
            }`}
            variant="standard"
            onChange={formik.handleChange}
            value={formik.values?.customerNumber}
            name="customerNumber"
            onBlur={formik.handleBlur}
            error={Boolean(errorIfTouched(formik, "customerNumber"))}
            helperText={errorIfTouched(formik, "customerNumber")}
            InputProps={{
              disableUnderline: true,
              inputComponent: PhoneNumberInput,
            }}
            fullWidth
          />
        </Grid>
      )}

      {isEmailEnabled && (
        <Grid item xs={12}>
          <MuiTextField
            placeholder={`Email ${!isEmailMandatory ? "(optional)" : ""}`}
            variant="standard"
            onChange={formik.handleChange}
            value={formik.values?.customerEmail}
            name="customerEmail"
            onBlur={formik.handleBlur}
            error={Boolean(errorIfTouched(formik, "customerEmail"))}
            helperText={errorIfTouched(formik, "customerEmail")}
            InputProps={{ disableUnderline: true }}
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <PrimaryButton disabled={!formik.isValid} onClick={formik.handleSubmit}>
          Submit Feedback
        </PrimaryButton>
        {/* <Button
          variant="rounded"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid}
          fullWidth
        >
          Submit Feedback
        </Button> */}
      </Grid>
    </Grid>
  );
}

CustomerDetail.propTypes = {};

export default CustomerDetail;

import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function CircularIndeterminate({ height, message, style }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height,
        ...style,
      }}
    >
      <Stack spacing={2} className="loader-stack">
        <Typography align="center">
          <CircularProgress
            sx={{ color: (theme) => theme.palette.primary.contrastText }}
          />
        </Typography>
        <Typography variant="subTitle4" align="center">
          {message}
        </Typography>
      </Stack>
    </Box>
  );
}

CircularIndeterminate.prototype = {
  height: PropTypes.string,
};

CircularIndeterminate.defaultProps = {
  height: "100vh",
  message: "Loading",
  style: {},
};

export default CircularIndeterminate;

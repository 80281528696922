import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Grid } from "@mui/material";
import Padding from "@components/padding";
import Staff from "@components/staff/Staff";

import { useSelector } from "react-redux";
import FloatingButton from "@components/floating-button";
import { useNavigate } from "react-router-dom";

function StaffSelection() {
  const [selectedStaff, onStaffSelection] = useState(null);
  const { staff: normalizedStaff } = useSelector((state) => state.team);
  const navigate = useNavigate();

  const onContinue = () => {
    if (!selectedStaff) {
      return false;
    }
    navigate(`/users/${selectedStaff}`);
  };
  const { staff } = normalizedStaff.entities || [];
  return (
    <>
      <Padding paddingBottom="100px">
        <Grid container justifyContent="space-around" rowGap={3}>
          <Grid item xs={12}>
            <Typography variant="h2" fontWeight={500} fontSize="23px">
              Who would you like to tip?
            </Typography>
            <Typography variant="h2" fontWeight={500} color="#A6A6A6">
              Car Valet
            </Typography>
          </Grid>

          {Object.values(staff).map((employee) => (
            <Grid item xs={5} key={employee.id}>
              <Staff
                selected={employee.id === selectedStaff}
                onSelect={onStaffSelection}
                {...employee}
              />
            </Grid>
          ))}
        </Grid>
      </Padding>
      <FloatingButton onClick={onContinue} position="fixed">
        Continue
      </FloatingButton>
    </>
  );
}

StaffSelection.propTypes = {};

export default StaffSelection;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField as MuiTextField,
  Box,
  Typography,
} from "@mui/material";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import FloatingButton from "@components/floating-button";
import StripeInput from "./stripeElements";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Currency from "@components/currency";
import CircularProgress from "@mui/material/CircularProgress";
import { alpha } from "@mui/material/styles";
import { PaymentActions } from "@store/payment/reducer";

const TextField = styled(MuiTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    backgroundColor: `${theme.palette.tertiary.secondary} !important`,
    borderRadius: "2rem",
  },
  "& .MuiFormLabel-root": {
    color: `${theme.palette.primary.contrastText} !important`,
  },
}));

function StripeCardElements({ onSubmit, totalAmount, ...props }) {
  const [name, setName] = React.useState("");
  const { error, stripeIntentLoading, errorType } = useSelector(
    (state) => state.payment
  );
  const dispatch = useDispatch();

  const onCardNumberChange = (event) => {
    dispatch(PaymentActions.updateCardType(event.brand));
  };

  const submitCardDetails = (e) => {
    if (stripeIntentLoading) {
      return;
    }
    if (error && errorType !== "validation_error") {
      return;
    }
    onSubmit(e, name);
  };

  useEffect(() => {
    const element = document.getElementById("card-payment");
    element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
  }, []);

  return (
    <Grid container rowGap={3} spacing={2} id="card-payment">
      <Grid item xs={12} style={{ marginBottom: 9 }}>
        <TextField
          label="Card Number"
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement,
              onChange: onCardNumberChange,
            },
          }}
          sx={{
            "& .MuiFormLabel-root": {
              fontWeight: "600 !important",
            },
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 9 }}>
        <TextField
          label="Cardholder Name"
          fullWidth
          value={name}
          onChange={({ target: { value } }) => setName(value)}
          sx={{
            "& .MuiFormLabel-root": {
              fontWeight: "600 !important",
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Expiry Date"
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardExpiryElement,
            },
          }}
          sx={{
            "& .MuiFormLabel-root": {
              fontWeight: "600 !important",
            },
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="CVV / CVC"
          fullWidth
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardCvcElement,
            },
          }}
          sx={{
            "& .MuiFormLabel-root": {
              fontWeight: "600 !important",
            },
          }}
        />
      </Grid>
      <Grid xs={12} item>
        {props.serviceFeeMessage && (
          <Typography
            variant="subText6"
            align="center"
            color={(theme) => alpha(theme.palette.primary.contrastText, 0.6)}
            mb={4}
          >
            {props.serviceFeeMessage}
          </Typography>
        )}
        <FloatingButton
          onClick={submitCardDetails}
          position="relative"
          color="secondary"
          error={error}
        >
          {!stripeIntentLoading && "Pay -  "}
          {stripeIntentLoading ? (
            <CircularProgress size="1.8rem" />
          ) : (
            <Box pl={0.5} component="span">
              <Currency value={totalAmount} />
            </Box>
          )}
        </FloatingButton>
      </Grid>
    </Grid>
  );
}

export default StripeCardElements;

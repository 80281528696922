import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  CardContent,
  Typography,
  Grid,
  Card,
  Box,
  Button,
  Link as MuiLink,
} from "@mui/material";
import Currency from "@components/currency";
import CircleButton from "@components/circle-button";
import { titleText, customAmountText } from "./styles";
import CustomTipInput from "@components/custom-tip-input";
import { useSelector, useDispatch } from "react-redux";
import { initialValues, tipFormSchema } from "./constants";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { actions as TeamActions } from "@store/team/reducer";
import { OrderActions } from "@store/order/reducer";
import CustomFields from "@components/custom-fields";
import { lengthOfHighestInteger } from "@root/util/currency";
import { PaymentActions } from "@store/payment/reducer";

function TeamTipForm({ variant }) {
  const { product } = useSelector((state) => state.ui);
  const {
    tipsDisplayLabel,
    tipsDefaultValue,
    isDefaultValueEnabled,
    isCustomAmountEnabled,
    urlLinkButton,
    paymentLabels,
  } = useSelector((state) => state.team);
  const [showCustomTipInput, toggleCustomTipInputVisibility] = useState(
    !isDefaultValueEnabled
  );
  const containerRef = React.useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const payTipAmount = (values) => {
    dispatch(TeamActions.updateTeamPaymentInfo(values));
    // dispatch(CheckoutActions.updateContinueToPay(true));
    navigate("/payment/card");
  };

  const form = useFormik({
    initialValues: initialValues,
    validationSchema: tipFormSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: payTipAmount,
  });

  const onTipAmountChange = (tipAmount, name) => {
    form.handleChange({ target: { name: "tipAmount", value: tipAmount } });
    form.handleChange({ target: { name, value: tipAmount } });

    if (!tipAmount) {
      return;
    }

    dispatch(
      OrderActions.updateTipsAmount({
        selectedTipsOption: 0,
        tipsAmount: tipAmount,
      })
    );
    dispatch(PaymentActions.updateError(null));
  };

  const toggleCustomTip = () => {
    if (showCustomTipInput) {
      dispatch(
        OrderActions.updateTipsAmount({
          selectedTipsOption: 0,
          tipsAmount: values.preDefinedTip,
        })
      );
    } else {
      if (!Boolean(values.customTip)) {
        dispatch(
          OrderActions.updateTipsAmount({
            selectedTipsOption: 0,
            tipsAmount: 0,
          })
        );
      } else {
        dispatch(
          OrderActions.updateTipsAmount({
            selectedTipsOption: 0,
            tipsAmount: values.customTip,
          })
        );
      }
    }

    toggleCustomTipInputVisibility((state) => !state);
  };
  const { values } = form;

  useEffect(() => {
    if (isDefaultValueEnabled) {
      dispatch(
        OrderActions.updateTipsAmount({
          selectedTipsOption: 0,
          tipsAmount: 0,
        })
      );
    }
  }, []);

  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container rowGap={2}>
              <Grid item xs={12}>
                <Typography
                  variant="subText5"
                  align="center"
                  sx={titleText}
                  mb="15px"
                >
                  {tipsDisplayLabel}
                </Typography>
              </Grid>
              {!showCustomTipInput && (
                <>
                  {paymentLabels.map(({ label, amount }) => (
                    <Grid item xs={4}>
                      <CircleButton
                        color="secondary"
                        selected={amount === values?.preDefinedTip}
                        onClick={() =>
                          onTipAmountChange(amount, "preDefinedTip")
                        }
                        fullWidth
                      >
                        <Typography variant="subHeading6">
                          <Currency value={amount} variant="wholeNumber" />
                        </Typography>
                      </CircleButton>
                      <Typography
                        variant="subText5"
                        align="center"
                        sx={{ marginTop: 1.5 }}
                      >
                        {label}
                      </Typography>
                    </Grid>
                  ))}
                </>
              )}
              {showCustomTipInput && (
                <Grid item xs={12}>
                  <CustomTipInput
                    animationRef={containerRef}
                    onChange={onTipAmountChange}
                    value={values?.customTip}
                  />
                </Grid>
              )}
              {isCustomAmountEnabled && isDefaultValueEnabled && (
                <Grid item xs={12}>
                  <Typography
                    variant="subTitle4"
                    align="center"
                    sx={customAmountText}
                  >
                    <Box component="span" onClick={toggleCustomTip} mt="15px">
                      {showCustomTipInput
                        ? "Use predefined amount"
                        : "Leave Custom Amount"}
                    </Box>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <CustomFields />

      {urlLinkButton?.isEnable && (
        <Grid container item xs={12} justifyContent="center">
          <MuiLink variant="link" href={urlLinkButton.url} target="_blank">
            {urlLinkButton.label}
          </MuiLink>
        </Grid>
      )}
    </Grid>
  );
}

TeamTipForm.propTypes = {};

export default TeamTipForm;

import PaymentService from "@services/PaymentService";
import { actions as paymentActions } from "@store/payment/reducer";

const {
  startLoading,
  updateAdyenConfiguration,
  updateAllowedCards,
  updateError,
  endLoading,
} = paymentActions;

export const getAdyenPaymentSession = (payload) => (dispatch) => {
  dispatch(startLoading());
  return PaymentService.getAdyenPaymentSession(payload)
    .then((res) => {
      dispatch(updateAdyenConfiguration(res));
      return res;
    })
    .catch((error) => {
      console.error(error);
      dispatch(updateError(error));
      throw error;
    })
    .finally(() => {
      dispatch(endLoading());
    });
};

export const getAllowedCards = (payload) => (dispatch) => {
  dispatch(startLoading());
  return PaymentService.getAllowedCards(payload)
    .then((res) => {
      dispatch(updateAllowedCards(res));
      return res;
    })
    .catch((error) => {
      console.error(error);
      dispatch(updateError(error));
      throw error;
    })
    .finally(() => {
      dispatch(endLoading());
    });
};

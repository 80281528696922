import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

function Padding({ children, ...props }) {
  return (
    <Box {...props} paddingRight={3} paddingLeft={3} height="100%">
      {children}
    </Box>
  );
}

Padding.propTypes = {};

export default Padding;

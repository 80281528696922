import addFeedback from "@root/graphql/feedback/hooks/addFeedback";
import getFeedbackSetting from "@root/graphql/feedback/get-setting";
import { feedbackActions } from "./reducer";

export const updateFeedback = (params) => (dispatch) => {
  dispatch(feedbackActions.isLoading(true));
  return addFeedback(params)
    .then((res) => {
      dispatch(feedbackActions.updateFeedbackResponse({ ...res, ...params }));
      return { ...res, ...params };
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => dispatch(feedbackActions.isLoading(false)));
};

export const fetchFeedbackSetting = ({ outletId, ...params }) => (dispatch) => {
  dispatch(feedbackActions.isLoading(true));

  // createAPILog(`calling Feedback setting api`, {
  //   requestPayload: params,
  // });

  return getFeedbackSetting({
    entityId: parseInt(outletId),
    entityName: "Outlet",
    ...params,
  })
    .then((res) => {
      // createAPILog(`Feedback setting api request completed`, {
      //   responsePayload: res,
      // });
      dispatch(feedbackActions.updateFeedbackSetting(res));

      return res;
    })
    .catch((error) => {
      // createAPILog(`Feedback setting api request failed`, {
      //   responsePayload: error,
      // });
      throw error;
    })
    .finally(() => dispatch(feedbackActions.isLoading(false)));
};

export default {
  updateFeedback,
  fetchFeedbackSetting,
};

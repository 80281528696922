import * as yup from "yup";
import { min } from "mathjs";

export const initialValues = (customFields = []) => {
  return customFields.reduce((acc, item) => ({ ...acc, [item.name]: "" }), {});
};

export const generateSchema = ({ label }) => {
  return yup
    .string()
    .transform((val) => val.value)
    .required()
    .label(label);
};

export const formSchema = (customFields = []) => {
  let customFieldSchema = {};

  customFields.forEach((fields) => {
    const { isMandatory, name } = fields;
    if (isMandatory) {
      customFieldSchema = {
        ...customFieldSchema,
        [name]: generateSchema(fields),
      };
    }
  });

  return yup.object(customFieldSchema);
};

export const tipPrecision = 2;

import React, { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { actions as PaymentActions } from "@store/payment/reducer";
import useIsAllowedToPay from "@hooks/is-allowed-to-pay";
import { detectDevice } from "@root/util/device";

// Flag to stop multiple payment confirm calls
let isOnPaymentMethodCalled = false;

function PaymentButton({
  onPaymentSuccess,
  setLoading,
  totalAmount,
  setError,
  children,
  beforePayment,
  handlePaymentIntent,
}) {
  const stripe = useStripe();
  const dispatch = useDispatch();

  const [paymentRequest, setPaymentRequest] = useState(null);
  const { stripeIntentLoading, error } = useSelector((state) => state.payment);
  const {
    isAllowedToPay,
    validGiftAidData,
    customFieldsValidationPassed,
    isOrderDataValid,
  } = useIsAllowedToPay();
  const { currencyCode, countryCode } = useSelector((state) => state.outlet);

  const createPaymentRequest = () => {
    const device = detectDevice();
    if (stripe) {
      const payLabel = device === "iOS" ? "" : "Pay";
      const pr = stripe.paymentRequest({
        country: countryCode,
        currency: currencyCode.toLowerCase(),
        total: {
          label: payLabel,
          amount: Math.round(totalAmount * 10 * 10),
        },
        requestPayerName: true,
        requestPayerEmail: false,
      });
      //   setLoading(true);
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
      // .finally(() => setLoading(false));
    }
  };

  const onPaymentMethod = async (ev) => {
    if (isOnPaymentMethodCalled) {
      console.log(
        "PaymentMethod :- Exiting onPaymentMethod as onPaymentMethod is still processing "
      );
      return false;
    }
    isOnPaymentMethodCalled = true;
    console.log("PaymentMethod :- Starting payment process");
    // Confirm the PaymentIntent without handling potential next actions (yet).
    console.log("PaymentMethod :- Starting loader screen");
    setLoading(true);
    dispatch(PaymentActions.setMessage("Your payment is processing."));

    try {
      console.log("PaymentMethod :- Starting beforePayment operations");
      const beforePaymentPassed = await beforePayment();
      console.log(beforePaymentPassed);
      console.log("PaymentMethod :-  beforePayment operations completed");
      console.log(
        "PaymentMethod :- Starting stripe confirmCardPayment operations"
      );
      const { clientSecret, paymentId } = await handlePaymentIntent();
      const confirmResult = await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );
      console.log(confirmResult);
      console.log(
        "PaymentMethod :- Stripe confirmCardPayment operations completed"
      );
      if (confirmResult.error) {
        console.log(
          "PaymentMethod :- Stripe confirmCardPayment error detected",
          confirmResult.error
        );
        throw Error(confirmResult.error);
      } else {
        if (confirmResult.paymentIntent.status === "requires_capture") {
          // initiatePaymentConfirmation();
        }
        console.log("PaymentMethod :- Starting onPaymentSuccess operations");
        onPaymentSuccess(paymentId);
        console.log("PaymentMethod :- onPaymentSuccess operations completed");
        console.log(
          "PaymentMethod :- Starting  ev.complete('success') operations"
        );
        ev.complete("success");
      }
    } catch (e) {
      console.log("PaymentMethod :- Operation failed, caught error");
      console.log(e);
      ev.complete("fail");
      console.log(
        "PaymentMethod :- Starting stripe   ev.complete('fail') operation"
      );
    } finally {
      isOnPaymentMethodCalled = false;
      //setLoading(false);
    }
  };
  const handleNotSupported = () => {
    setError("Currently this payment method is not supported by your browser");
    setTimeout(() => setError(null), 3000);
  };
  useEffect(() => createPaymentRequest(), [stripe, totalAmount]);

  const openPaymentMethod = () => {
    console.log("PaymentMethod :- Pay button clicked");
    if (!isAllowedToPay) {
      if (!isOrderDataValid) {
        setError("Please enter a valid amount");
        return setTimeout(() => setError(""), 2500);
      }
      if (!validGiftAidData) {
        setError("Please enter gift aid details");
        return setTimeout(() => setError(""), 2500);
      }
      if (!customFieldsValidationPassed) {
        setError("Please fill required fields");
        return setTimeout(() => setError(""), 2500);
      }
      setTimeout(() => setError(""), 2500);
      return;
    }

    if (error) {
      setError(error);
      setTimeout(() => setError(""), 2500);
      return;
    }

    if (paymentRequest) {
      setLoading(true);
    }
    console.log("PaymentMethod :- Pay button clicked validation completed");

    console.log(
      "PaymentMethod :- Starting payment method screen via stripe  paymentRequest.show() fn"
    );

    return !paymentRequest ? false : paymentRequest.show();
  };

  const delayedOnClick = () => {
    //TODO
    // This will work if user click on payment button while intent is loading
    // Idea behind this is we will delay method call until value of intent loading parameter is set to false
  };

  if (paymentRequest) {
    paymentRequest.on("paymentmethod", onPaymentMethod);
    paymentRequest.on("cancel", () => setLoading(false));
    return (
      <span onClick={stripeIntentLoading ? delayedOnClick : openPaymentMethod}>
        {children}
      </span>
    );
    //  <PaymentRequestButtonElement options={{ paymentRequest }} />;
  }
  return <span onClick={onPaymentMethod}>{children}</span>;
}

PaymentButton.propTypes = {};

export default PaymentButton;

import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as AmountUnderline } from "./icons/amount-underline.svg";

function Icon({ variant, ...props }) {
  return (
    <SvgIcon {...props}>
      <AmountUnderline />
    </SvgIcon>
  );
}

Icon.propTypes = {};

export default Icon;

import { gql } from "@apollo/client";
export const GET_FEEDBACK_SETTING_QUERY = gql`
  query getFeedbackSetting($entityId: Int!, $entityName: String!) {
    getFeedbackSetting(entityId: $entityId, entityName: $entityName) {
      foodFeedbackRatingLabel
      serviceFeedbackRatingLabel
      hygieneFeedbackRatingLabel
      customerContactInfoSettings
      recommendationFeedbackRatingLabel
      food
      foodFeedbackRatingThreshold
      foodSmsNotificationEnabled
      foodEmailNotificationEnabled
      service
      serviceFeedbackRatingThreshold
      serviceSmsNotificationEnabled
      serviceEmailNotificationEnabled
      hygiene
      hygieneFeedbackRatingThreshold
      hygieneSmsNotificationEnabled
      hygieneEmailNotificationEnabled
      recommendation
      recommendationFeedbackRatingThreshold
      recommendationSmsNotificationEnabled
      recommendationEmailNotificationEnabled
      foodFeedbackRatingLabel
      serviceFeedbackRatingLabel
      hygieneFeedbackRatingLabel
      recommendationFeedbackRatingLabel
      feedbackContacts {
        mobileNumber
        email
      }
      smsNotificationCountryCode
      smsNotificationReceiver
      smsBody
      feedbackSharingEnabled
      feedbackSharingRatingThreshold
      feedbackSharingPlatforms {
        displayName
        url
        logoUrl
      }
      additionalFeedbackEnabled
      feedbackHeader
      feedbackSubHeader
      positiveFeedbackResponseMessage
      negativeFeedbackResponseMessage
      feedbackResponsePersonName
      feedbackResponsePersonRole
      feedbackResponsePersonProfileImageUrl
      requestComplaintNumber
      isTippingEnabled
      tippingEnabledForTeamId
      positiveFeedbackReviewMessage
      positiveFeedbackTipsMessage
    }
  }
`;

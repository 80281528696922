import { createSlice, current } from "@reduxjs/toolkit";
import {
  feedbackOptions,
  transformFeedbackSetting,
  getAverageFeedback,
} from "./constant";

const feedback = createSlice({
  name: "feedback",
  initialState: {
    feedbackOptions: feedbackOptions(),
    averageRating: null,
    feedbackSetting: {},
    feedbackSubmitted: false,
    additionalFeedbackSubmitted: false,
    customerNumber: "",
    feedbackId: null,
    isLoading: false,
    error: null,
    errorData: {},
    id: null,
    socialPlatformsClicked: [],
    comment: "",
  },
  reducers: {
    updateAverageRating: (state, { payload }) => ({
      ...state,
      averageRating: payload,
    }),
    updateFeedbackSubmitted: (state, { payload }) => ({
      ...state,
      feedbackSubmitted: payload,
    }),
    updateContactNumber: (state, { payload }) => ({
      ...state,
      customerNumber: payload,
    }),
    setSocialPlatformsClicked: (state, { payload }) => ({
      ...state,
      socialPlatformsClicked: [...state.socialPlatformsClicked, payload],
    }),
    updateAdditionalFeedbackSubmitted: (state, { payload }) => ({
      ...state,
      additionalFeedbackSubmitted: payload,
    }),
    updateFeedbackSetting: (state, { payload }) => ({
      ...state,
      feedbackSetting: {
        ...state?.feedbackSetting,
        ...transformFeedbackSetting(payload),
      },
      feedbackOptions: feedbackOptions(payload),
    }),

    updateRating: (state, { payload }) => {
      const { feedbackOptions } = state;
      const { key, rating } = payload;
      const updatedFeedback = feedbackOptions.map((item) => {
        if (item.key === key) {
          return { ...item, rating };
        }
        return item;
      });

      ////

      const newState = { ...state, feedbackOptions: updatedFeedback };

      const averageRating = getAverageFeedback(newState);

      if (!isNaN(averageRating)) {
        return {
          ...newState,
          averageRating,
        };
      }
      return newState;
    },
    setFeedbackId: (state, { payload }) => ({
      ...state,
      feedbackId: payload,
    }),
    updateFeedbackComment: (state, { payload }) => ({
      ...state,
      comment: payload,
    }),
    updateFeedbackResponse: (state, { payload }) => {
      const newState = { ...state, ...payload };

      const averageRating = getAverageFeedback(state);

      if (!isNaN(averageRating)) {
        return {
          ...newState,
          averageRating,
        };
      }
      return newState;
    },
    isLoading: (state, { payload }) => {
      return { ...state, isLoading: payload };
    },
    error: (state, { payload }) => ({ ...state, error: payload, bill: {} }),
  },
});

export const action = feedback.actions;
export const feedbackActions = action;

export default feedback;

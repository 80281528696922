import * as yup from "yup";
import { NumericFormat as NumberFormat } from "react-number-format";
import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import isEmailValidator from "validator/lib/isEmail";

export const MuiTextField = styled(TextField)(({ theme, ...props }) => ({
  "& .MuiFormLabel-root": {
    top: "-27px",
    left: "-1px",
    transform: "none",
    color: "#818181 !important",
    fontSize: "14px",
    fontWeight: 500,
  },
  "& .MuiInputBase-input": {
    padding: "9px 14px !important",
    fontSize: "14px",
    fontWeight: 500,
    color: "#FFF",
  },
  "& .StripeElement": {
    padding: "10px 14px 8px 14px !important",
    height: "unset !important",
  },
  "& input::placeholder": {
    textAlign: "left",
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#ffffff1f",
    borderRadius: "21px",
    height: "42px",
  },
  "& .MuiFormHelperText-root": {
    fontSize: "10px",
    paddingLeft: "5px",
  },
  "& .Mui-error:first-child": {
    border: "0.5px solid #EB001B",
    background: "rgba(235, 0, 27, 0.10) !important",
  },
}));

export const PhoneNumberInput = React.forwardRef(function PhoneNumberInput(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        if (values.value != other.value) {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }
      }}
      format="###############"
    />
  );
});

PhoneNumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const customerFeedbackSchema = (customerContactInfoSettings) => {
  let schema = {
    username: yup.string().nullable().label("Name"),
    tableId: yup.string().nullable().label("Table Number"),
    customerNumber: yup
      .number()
      .positive()
      .integer()
      .nullable()
      .label("Mobile Number"),
    customerEmail: yup
      .string()
      .email()
      .nullable()
      .test(
        "is-valid",
        (message) => `${message.path} is invalid`,
        (value) =>
          value
            ? isEmailValidator(value)
            : new yup.ValidationError("Invalid Email")
      )
      .label("Email"),
  };

  const {
    isNameEnabled,
    isNameMandatory,
    isEmailEnabled,
    isEmailMandatory,
    isMobileNoEnabled,
    isMobileMandatory,
    isTableEnabled,
    isTableMandatory,
  } = customerContactInfoSettings;

  if (isNameEnabled && isNameMandatory) {
    schema.username = schema.username.required();
  }
  if (isEmailEnabled && isEmailMandatory) {
    schema.customerEmail = schema.customerEmail.required();
  }
  if (isMobileNoEnabled && isMobileMandatory) {
    schema.customerNumber = schema.customerNumber.required();
  }
  if (isTableEnabled && isTableMandatory) {
    schema.tableId = schema.tableId.required();
  }

  return yup.object(schema);
};

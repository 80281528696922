import React from "react";
import NotFound from "@components/not-found";
import FullHeightLayout from "@root/layouts/FullHeightLayout";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

function NotFoundPage() {
  const { message1, message2 } = useParams();
  return (
    <FullHeightLayout className="not-found-page">
      <Box display="flex" alignItems="center" height="100%">
        <NotFound message1={message1} message2={message2} />
      </Box>
    </FullHeightLayout>
  );
}

export default NotFoundPage;

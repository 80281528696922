import { formatToCurrency } from "@root/util/currency";
import { multiply, add } from "mathjs";

export const backdropStyle = {
  top: "-209px",
  left: "-24px",
  position: "absolute",
  zIndex: 999999,
  width: "100vw",
  height: "100vh",
  background: "#9b9a9a75",
};
export const loaderStyle = {
  position: "fixed",
  zIndex: 9999,
  top: 0,
  left: 0,
  width: "100vw",
  background: "#0000008c",
  color: "#fff",
  "& .MuiCircularProgress-root": {
    color: "#fff",
    height: "80px !important",
    width: "80px !important",
  },
  "& .loader-stack": {
    paddingBottom: "172px !important",
  },
};

export const getTotalPayment = ({ totalPrice, tipsAmount }) =>
  parseInt(
    formatToCurrency(
      multiply(
        add(formatToCurrency(totalPrice), formatToCurrency(tipsAmount)),
        100
      )
    )
  );

export const getTotalAmount = ({ totalPrice, tipsAmount }) =>
  formatToCurrency(
    add(formatToCurrency(totalPrice), formatToCurrency(tipsAmount))
  );

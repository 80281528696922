import { createSlice } from "@reduxjs/toolkit";

const details = {
  name: "Waldorf Astoria Orlando",
  qr: "./assets/demo/user-logo.svg",
  tips: null,
  tipsTotal: 0,
  team: {
    name: "Car valet",
  },
};

const initialState = {
  isLoading: true,
  error: null,
  apiResponse: {},
  ...details,
};

const staff = createSlice({
  name: "staff",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    updateTipsInformation: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        error: null,
      };
    },
    startLoading: (state, { payload }) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    updateStaffDetails: (state, { payload }) => ({
      ...state,
      ...payload,
      apiResponse: payload,
    }),
    endLoading: (state, { payload }) => ({ ...state, isLoading: false }),

    updateError: (state, { payload }) => ({ ...state, error: payload }),
  },
});

export const actions = staff.actions;

export default staff;

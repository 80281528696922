import APIService from "./APIService";

export default class PaymentGatewayService extends APIService {
  static getConnectedGateway(params) {
    const queryParameter = this.buildQuery({
      ...params,
    });

    return this.get(
      `/paymgr/api/v1/outlet-kyc/getByOutletIdAndPaymentGateway${queryParameter}`
    )
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
}

import React from "react";
import PropTypes from "prop-types";
import { useGetSelectedStaffMember } from "@store/team/reducer";
import { useSelector } from "react-redux";
import { Grid, Box, Avatar, Typography, Paper } from "@mui/material";
import Padding from "@components/padding";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { makeStyles } from "@mui/styles";

const LINES_TO_SHOW = 2;
const useStyles = makeStyles({
  container: {
    maxWidth: 500,
  },
  multiLineEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": LINES_TO_SHOW,
    "-webkit-box-orient": "vertical",
    "&:hover": {
      textOverflow: "clip",
      whiteSpace: "normal",
      wordBreak: "break-all",
      "-webkit-line-clamp": "inherit",
    },
  },
});
function TeamTips(props) {
  const { teamName, teamMessage, description, teamImageUrl } = useSelector(
    (state) => state.team
  );
  const classes = useStyles();

  return (
    <Padding>
      <Grid container rowSpacing={3} alignItems="center">
        <Grid item xs={teamImageUrl ? 6 : 12}>
          <Typography variant="h1" fontSize="23px">
            <Box fontWeight="500">{teamName}</Box>
            <Box
              className={classes.multiLineEllipsis}
              component="span"
              color="#B6B6B6"
              fontSize="18px"
            >
              {description}
            </Box>
          </Typography>
        </Grid>
        {teamImageUrl && (
          <Grid xs={6} item>
            <img src={teamImageUrl} height="109" />
          </Grid>
        )}
        <Grid item xs={12}>
          <Box position="relative" mb={3}>
            <Box position="absolute" top="-5px" left="-5px">
              <FavoriteIcon sx={{ transform: "rotate(320deg)" }} />
            </Box>
            <Paper elevation={2} sx={{ borderRadius: "15px" }}>
              <Typography variant="h5" align="center" p={2}>
                <Box
                  className={classes.multiLineEllipsis}
                  fontStyle="italic"
                  component="span"
                >
                  " {teamMessage} "{" "}
                </Box>
                - {teamName}
              </Typography>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Padding>
  );
}

TeamTips.propTypes = {};

export default TeamTips;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderTypes: [],
  orderTotals: {},
  providers: [],
  isLoading: true,
  error: null,
  continueToPay: false,
  apiResponse: {},
  orderDetails: {},
  isOrderSetting: false,
  orderSettingError: null,
};

const checkout = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    startLoading: (state, { payload }) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    endLoading: (state, { payload }) => ({ ...state, isLoading: false }),
    updateCheckoutOptions: (state, { payload }) => {
      const { orderTypes } = payload;

      return {
        ...state,
        orderTypes,
        apiResponse: payload,
      };
    },

    // Order Api
    startOrderSetting: (state, { payload }) => ({
      ...state,
      isOrderSetting: true,
      error: null,
    }),
    endOrderSetting: (state, { payload }) => ({
      ...state,
      isOrderSetting: false,
    }),
    updateOrderDetails: (state, { payload }) => {
      return {
        ...state,
        orderDetails: payload,
      };
    },
    updateOrderError: (state, { payload }) => ({
      ...state,
      orderSettingError: payload,
    }),
    // End -> Order Api

    updatePaymentMethod: (state, { payload }) => {
      const { providers } = payload;

      return {
        ...state,
        providers,
      };
    },
    updateOrderTotals: (state, { payload }) => {
      console.log(payload);
      return {
        ...state,
        orderTotals: payload,
      };
    },
    updateError: (state, { payload }) => ({ ...state, error: payload }),
    updateContinueToPay: (state, { payload }) => ({
      ...state,
      continueToPay: payload,
    }),
  },
});

export const actions = checkout.actions;

export default checkout;

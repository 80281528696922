import React from "react";
import PropTypes from "prop-types";
import { Slider, Typography, Box } from "@mui/material";
import classes from "./styles";
import "./style.css";

function valuetext(value) {
  return `${value}°C`;
}

function valueLabelFormat(value) {
  return marks.findIndex((mark) => mark.value === value) + 1;
}

function SliderInput({ title, marks, defaultValue, onChange, ...props }) {
  return (
    <div className={classes.root}>
      <Typography variant="subTitle3" mt={3}>
        <Box fontSize={18} fontWeight={500}>
          {title}
        </Box>
      </Typography>
      <Slider
        component="div"
        valueLabelFormat={valueLabelFormat}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-restrict"
        step={10}
        scale={(value) => value * 5}
        valueLabelDisplay="off"
        marks={marks}
        {...props}
        defaultValue={defaultValue * 10}
        track={false}
      />
    </div>
  );
}

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 10,
    label: "1",
  },
  {
    value: 20,
    label: "2",
  },
  {
    value: 30,
    label: "3",
  },
  {
    value: 40,
    label: "4",
  },
  {
    value: 50,
    label: "5",
  },
  {
    value: 60,
    label: "6",
  },
  {
    value: 70,
    label: "7",
  },
  {
    value: 80,
    label: "8",
  },
  {
    value: 90,
    label: "9",
  },
  {
    value: 100,
    label: "10",
  },
];

SliderInput.propTypes = {
  marks: PropTypes.object,
  defaultValue: PropTypes.number,
  title: PropTypes.string.isRequired,
  classes: PropTypes.object,
};

Slider.defaultProps = {
  marks,
  defaultValue: 10,
};

export default SliderInput;

import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { tooltipContentText } from "./constant";
import { textColor } from "./styles";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Tooltip } from "./styles";
import { formatToCurrency } from "@root/util/currency";

function GiftAid(props) {
  const [openTooltips, setToolTips] = useState(false);
  const { tipsAmount } = useSelector((state) => state.order);
  const { isGiftAidEnable } = useSelector((state) => state.team);

  const percentage = (tipsAmount * 25) / 100;
  const newTotalAmount = percentage + tipsAmount;

  if (!isGiftAidEnable) {
    return null;
  }

  return (
    <Grid container sx={{ pb: 3 }}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          {" "}
          <img src="/assets/images/gift-aid-logo.png" alt="gift-aid-logo" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {Boolean(tipsAmount) && (
          <Typography variant="subHeading6" align="center">
            Turn that into
            <Typography variant="subHeading5" component="span">
              {" "}
              £
            </Typography>
            <Typography variant="subHeading4" component="span">
              {formatToCurrency(newTotalAmount)}{" "}
            </Typography>
            with Gift Aid
          </Typography>
        )}
        <Typography
          variant="subTitle4"
          align="center"
          sx={textColor}
          display="flex"
          justifyContent="center"
        >
          Boost your donation by 25% at no cost to you!
          <Tooltip
            title={tooltipContentText}
            placement="top-end"
            open={openTooltips}
            sx={{
              "& .MuiTooltip-popper": {
                backgroundColor: "#000",
              },
              "& .MuiTooltip-tooltip": {
                backgroundColor: "#000",
              },
            }}
            arrow
          >
            <Box
              onClick={() => setToolTips((state) => !state)}
              color={({ palette }) => palette.primary.contrastText}
              component="span"
              paddingLeft={1}
            >
              {"  "}
              <img src="/assets/images/information-icon.svg" />
            </Box>
          </Tooltip>
        </Typography>
      </Grid>
    </Grid>
  );
}

GiftAid.propTypes = {};

export default GiftAid;

import { createSlice } from "@reduxjs/toolkit";

const details = {
  eventBackground: "./assets/demo/demo-landing-image.svg",
  eventLogo: "./assets/demo/outlet-logo.svg",
  outletLogo: "./assets/demo/outlet.svg",
  name: "Fetch Conference",
  eventStartDate: "06th June",
  eventEndDate: "",
  eventStartTime: "13:00PM",
  eventEndTime: "17:00PM",
  outlet: {},
};

const initialState = {
  detail: details,
  isLoading: true,
  error: null,
  name: "",
  outletType: "",
  apiResponse: {},
  outletId: 549,
  currencySymbol: "",
};
const event = createSlice({
  name: "event",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    startLoading: (state, { payload }) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    endLoading: (state, { payload }) => ({ ...state, isLoading: false }),

    updateOutlet: (state, { payload }) => {
      const { outlets } = payload;
      const [outlet] = outlets;
      return {
        ...state,
        detail: outlet,
        outletId: outlet.outletId,
        outletType: outlet.outletType,
        orderTypes: outlet.orderTypes,
        name: outlet.name,
        image: outlet.imageUrl,
        apiResponse: payload,
        currencySymbol: outlet.currencySymbol,
      };
    },
    updateError: (state, { payload }) => ({ ...state, error: payload }),
  },
});

export const actions = event.actions;

export default event;

import React, { useState } from "react";
import { GET_STAFF_QUERY } from "../query";
import GraphqlClient from "@services/GraphqlService";

const GET_VARIABLE = (userId) => ({
  variables: {
    userId,
  },
});

export const getStaffDetail = (input) => {
  return GraphqlClient.get(GET_STAFF_QUERY, GET_VARIABLE(input))
    .then(({ data, errors }) => {
      if (errors) {
        throw errors[0];
      }
      const dataResponse = data?.getStaffDetails ?? {};
      return dataResponse;
    })
    .catch((error) => {
      throw error;
    });
};

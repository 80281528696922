import APIService from "./APIService";

export default class PaymentService extends APIService {
  static getAdyenPaymentSession(params) {
    const queryParameter = this.buildQuery(params);
    return this.get(`/PaymentSession${queryParameter}`)
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static getPaymentMethod(params) {
    const queryParameter = this.buildQuery(params);
    return this.get(`/PaymentMethodsV2${queryParameter}`)
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static getOrderTotals(params) {
    return this.post(`/OrderTotals`, {
      body: JSON.stringify(params),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static simphonyPayment(params) {
    return this.post(`/PaymentV2`, {
      body: JSON.stringify({ ...params, providerName: "Simphony Room Charge" }),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static adyenPayment(params) {
    return this.post(`/PaymentV2`, {
      body: JSON.stringify({ ...params, providerName: "Adyen" }),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static getAllowedCards(params) {
    // required value referenceId i.e outlet id
    const queryParameter = this.buildQuery({
      referenceType: "OUTLET",
      ...params,
    });
    return this.get(`/paymgr/api/v1/payment/allowed-cards${queryParameter}`)
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
}

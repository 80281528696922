import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  isLoading: true,
  adyenConfiguration: {},
  error: null,
  selectedTab: 0,
  selectedItem: null,
  isProcessing: false,
  stripeIntentLoading: false,
  isStripeIntentValid: false,
  processingStage: "initial",
  allowedCardBrands: [],
  errorType: null,
  cardType: null,
  cardRejected: false,
  // This can be any unique identifier that can be used to confirm payment form server
  // Currently used for stripe to confirm restricted card brand validation check
  paymentId: null,
  selectedPaymentMode: "",
};
const payment = createSlice({
  name: "payment",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setStripeIntent: (state, { payload }) => ({
      ...state,
      isStripeIntentValid: payload,
    }),
    startStripeIntentLoading: (state, { payload }) => ({
      ...state,
      stripeIntentLoading: true,
      error: null,
    }),
    endStripeIntentLoading: (state, { payload }) => ({
      ...state,
      stripeIntentLoading: false,
    }),
    startProcessing: (state, { payload }) => ({
      ...state,
      isProcessing: true,
      error: null,
    }),
    endProcessing: (state, { payload }) => ({
      ...state,
      isProcessing: false,
    }),
    startLoading: (state, { payload }) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    setMessage: (state, { payload }) => ({
      ...state,
      message: payload,
    }),
    setError: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    endLoading: (state, { payload }) => ({ ...state, isLoading: false }),
    updateAdyenConfiguration: (state, { payload }) => ({
      ...state,
      adyenConfiguration: payload,
    }),
    updateCardType: (state, { payload }) => ({
      ...state,
      cardType: payload,
    }),
    updatePaymentId: (state, { payload }) => ({
      ...state,
      paymentId: payload,
    }),
    updateAllowedCards: (state, { payload }) => ({
      ...state,
      allowedCardBrands: extractCardBrands(payload),
    }),
    updateError: (state, { payload }) => ({
      ...state,
      error: payload,
      isProcessing: false,
      errorType: null,
    }),

    updateErrorType: (state, { payload }) => ({
      ...state,
      errorType: payload,
    }),

    updateCardRejected: (state, { payload }) => ({
      ...state,
      cardRejected: payload,
    }),
    setSelectedPaymentMode: (state, { payload }) => {
      return {
        ...state,
        selectedPaymentMode: payload,
      };
    },
  },
});

export const actions = payment.actions;
export const PaymentActions = payment.actions;

export default payment;

const extractCardBrands = (cardsResponse) => {
  const { contractTypeCardRates = [] } = cardsResponse;
  const cardBrands = contractTypeCardRates.map((card) => card?.cardProvider);
  const uniqueCardBrands = [...new Set(cardBrands)];
  const smallCaseCardBrands = uniqueCardBrands.map((item) =>
    String(item).toLocaleLowerCase()
  );
  return [...uniqueCardBrands, ...smallCaseCardBrands];
};

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { actions as PaymentActions } from "@store/payment/reducer";
import StripeService from "@root/services/StripeService";

function useRoomChargePayment(props) {
  const dispatch = useDispatch();
  const { isAllowedToPay } = useSelector((state) => state.order);
  const { error, errorType } = useSelector((state) => state.payment);

  const handleRoomChargeSubmit = async (params) => {
    console.log("RoomChargePayment :- Starting handleRoomChargeSubmit fn ");

    if (!isAllowedToPay) {
      return dispatch(PaymentActions.updateError("Please enter valid  amount"));
    }
    console.log("RoomChargePayment :- isAllowedToPay to pay check passed");

    if (errorType && errorType !== "validation_error") {
      return;
    }

    console.log("RoomChargePayment :- no other error present in system");

    try {
      console.log(
        "RoomChargePayment :- Starting to confirm with fetch"
      );
      dispatch(PaymentActions.startProcessing());
      const response = await StripeService.confirmFetchPayment(params);
      console.log(
        "RoomChargePayment :- Completed api call to confirm with fetch"
      );
      console.log(
        "RoomChargePayment :- Starting error check to confirm with fetch"
      );

      console.log("RoomChargePayment :- Starting stripe confirm fn");
      console.log("RoomChargePayment :- Completed stripe confirm fn");
      return response;
    } catch (error) {
      console.log(error);
      if (error?.type === "card_error" || error?.type === "validation_error") {
        dispatch(PaymentActions.updateError(error.message));
        dispatch(PaymentActions.updateErrorType("validation_error"));
      } else {
        dispatch(PaymentActions.updateErrorType(null));
      }
    }
  };

  return { handleRoomChargeSubmit };
}

export default useRoomChargePayment;

import React from "react";
import PropTypes from "prop-types";
import { useGetSelectedStaffMember } from "@store/team/reducer";
import { useSelector } from "react-redux";
import { Grid, Box, Avatar, Typography, Paper } from "@mui/material";
import Padding from "@components/padding";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { nameInitials } from "@root/util/ui";

function StaffTips(props) {
  const { staffImageUrl, displayName, staffMessage } = useSelector(
    (state) => state.staff
  );
  const { teamName } = useSelector((state) => state.team);
  const [firstName] = String(displayName).split(" ");
  return (
    <Padding>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <Avatar
              src={staffImageUrl}
              alt={displayName}
              sx={{ width: 160, height: 160 }}
            >
              <Typography fontSize="36px" textTransform="uppercase">
                {nameInitials(displayName)}
              </Typography>
            </Avatar>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            fontWeight="700"
            fontSize="35px"
            align="center"
          >
            {displayName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            fontWeight="500"
            fontSize="21px"
            align="center"
            color="#B6B6B6"
          >
            {teamName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box position="relative" mb={3}>
            <Box position="absolute" top="-5px" left="-5px">
              <FavoriteIcon sx={{ transform: "rotate(320deg)" }} />
            </Box>
            <Paper elevation={2} sx={{ borderRadius: "15px" }}>
              <Typography variant="h5" align="center" p={2}>
                <Box fontStyle="italic" component="span">
                  " {staffMessage} "{" "}
                </Box>
                - {firstName}
              </Typography>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Padding>
  );
}

StaffTips.propTypes = {};

export default StaffTips;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import PageHeader from "@components/page-header";

import UserOverview from "@components/user-overview";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getStaff } from "@store/staff/api";
import Loading from "@components/loading";

import useGetUserId from "@hooks/use-get-user-id";

function UserLandingPage(props) {
  const { isLoading } = useSelector((state) => state.staff);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Grid container rowGap={4}>
      <Grid item xs={12}>
        <PageHeader />
      </Grid>
      <Grid item xs={12}>
        <UserOverview />
      </Grid>
    </Grid>
  );
}

UserLandingPage.propTypes = {};

export default UserLandingPage;

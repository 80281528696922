import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useKeycloak } from "@react-keycloak/web";
import jwt from "jwt-decode";
import { useNavigate } from "react-router-dom";
import useGetUserId from "@hooks/use-get-user-id";
import { useSelector, useDispatch } from "react-redux";
import { getStaff } from "@store/staff/api";
import Dialog from "@components/dialog";
import UnexpectedError from "@components/unexpected-error";
import Loading from "@components/loading";
import { Grid } from "@mui/material";

function Authorized({ children }) {
  const userId = useGetUserId();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { keycloak } = useKeycloak();
  window.keycloak = keycloak;
  const { error } = useSelector((state) => state.staff);

  const handleFailure = (error) => {
    setTimeout(() => {
      console.log("User not a staff logging out ");
      keycloak
        .logout({ redirectUri: `${process.env.PUBLIC_URL}` })
        .then((res) => {
          console.log("Logged Out");
        });
    }, 4000);
  };

  useEffect(() => {
    dispatch(getStaff(userId))
      .then((res) => {
        const {
          isQREnable,
          team: { id: teamId },
          userId,
        } = res;
        if (isQREnable) navigate(`/user/${userId}`);
        else navigateTo(`/team/${teamId}/user/${userId}`);
      })
      .catch(handleFailure);
  }, []);

  if (error) {
    return (
      <Dialog>
        <Grid container height="100%">
          <UnexpectedError
            message={["User not a staff yet.", "Redirecting to login page"]}
            hideButton={true}
          />
        </Grid>
      </Dialog>
    );
  }

  return children;
}

Authorized.propTypes = {};

export default Authorized;

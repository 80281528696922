import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import DojoCard from "@components/dojo-card";
import DojoWallet from "@components/dojo-wallet";
import useStripeIntent from "@hooks/use-stripe-intent";
import useIsAllowedToPay from "@hooks/is-allowed-to-pay";
import "./style.css";
import { UIAction } from "@root/store/ui/reducer";
import { PaymentActions } from "@store/payment/reducer";

const intentOption = {
  skipMultipleRequestCheck: true,
};
function DojoPaymentGateway({ clientSecret, ...props }) {
  const [intent, setIntent] = useState({});
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { selectedPaymentMode, stripeIntentLoading: isIntentLoading } =
    useSelector((state) => state?.payment);
  const { handlePaymentIntent } = useStripeIntent(
    props.totalAmount,
    "dojo",
    intentOption
  );
  const { currencySymbol } = useSelector((state) => state.outlet);
  const { isAllowedToPay } = useIsAllowedToPay();
  const { serviceChargeAmount } = useSelector((state) => state.order);

  const dispatch = useDispatch();

  const onIframeLoaded = (val) => {
    setErrors((state) => []);
    setLoaded(val);
  };

  const createIntent = async () => {
    try {
      setIntent({});
      const intent = await handlePaymentIntent(props.totalAmount);
      setIntent(intent);
    } catch (e) {
      console.log(e);
      // dispatch(PaymentActions.setSelectedPaymentMode(""));
    }
  };

  useEffect(() => {
    if (selectedPaymentMode) {
      createIntent();
    }
  }, [props.totalAmount, selectedPaymentMode]);

  useEffect(() => {
    createIntent();
  }, []);

  useEffect(() => {
    setErrors([]);
    setLoaded((state) => false);
  }, [selectedPaymentMode]);

  const handleSubmitError = (error) => {
    const errorMessage = handleErrorsCallback(error);
    dispatch(UIAction.setSnackBarError(errorMessage));
    // setTimeout(() => dispatch(UIAction.setSnackBarError(errorMessage)), 10000);
    return createIntent();
  };

  const handleErrorsCallback = (errors) => {
    console.log("Payment Request failed: ", errors);
    if (errors && !Array.isArray(errors) && typeof errors === "object") {
      setErrors([errors.message]);
      return errors?.message;
    }
    if (Array.isArray(errors)) {
      setErrors(errors.map((error) => error.message));
      return errors[0]?.message;
    }

    console.log([errors], errors);
    setErrors([errors]);
    return errors;
  };

  const message = `A service fee of ${currencySymbol}${parseFloat(
    serviceChargeAmount
  ).toFixed(2)} has been added to your total`;

  console.log("errors => ", errors);

  const componentsProps = {
    clientSecret,
    serviceFeeMessage: Boolean(serviceChargeAmount) ? message : null,
    displayErrorsCallback: handleErrorsCallback,
    handleSubmitError,
    onIframeLoaded,
    setLoaded,
    isIntentLoading,
    loaded,
    errors,

    ...intent,
    ...props,
  };

  if (!intent?.clientSecret) {
    return null;
  }

  console.log({ errors, selectedPaymentMode });

  if (selectedPaymentMode === "card") {
    return <DojoCard {...componentsProps} />;
  }

  return <DojoWallet {...componentsProps} />;
}

DojoPaymentGateway.propTypes = {};

export default DojoPaymentGateway;

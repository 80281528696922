import * as React from "react";
import MuiTimeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import "./style.css";

function Timeline({ nodes }) {
  const lastIndex = nodes.length - 1;
  return (
    <MuiTimeline className="timeline">
      {nodes.map((node, index) => (
        <TimelineItem
          sx={{ minHeight: Boolean(lastIndex !== index) ? "63px" : "unset" }}
        >
          <TimelineSeparator>
            <TimelineDot
            //   sx={{
            //     mt: index === 0 ? "11.5px" : 0,
            //     py: index !== 0 && ? "11.5px" : 0,
            //     mb: index === lastIndex ? "11.5px" : 0,
            //   }}
            />
            {Boolean(lastIndex !== index) && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent position="relative">{node}</TimelineContent>
        </TimelineItem>
      ))}
    </MuiTimeline>
  );
}

export default Timeline;

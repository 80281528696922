import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Zoom from "@mui/material/Zoom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Zoom
      style={{ transitionDelay: props.in ? "100ms" : "0ms" }}
      ref={ref}
      {...props}
    />
  );

  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ onClose, children }) {
  return (
    <Dialog fullScreen onClose={onClose} TransitionComponent={Transition} open>
      {children}
    </Dialog>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import UserDashboard from "@components/user-dashboard";
import Authenticate from "@components/authenticated/Authenticate";

function UserDashboardPage(props) {
  return (
    <Authenticate>
      <Grid container>
        <Grid item xs={12}>
          <UserDashboard />
        </Grid>
      </Grid>
    </Authenticate>
  );
}

UserDashboardPage.propTypes = {};

export default UserDashboardPage;

import * as yup from "yup";

export const validationSchema = yup.object().shape({
  title: yup.string().required().label("Title"),
  firstName: yup.string().required().label("First name"),
  lastName: yup.string().required().label("Last name"),
  houseNumber: yup.string().required().label("House number"),
  postcode: yup.string().required().label("Postcode"),
});

export const initialValues = {
  title: "Mr",
  firstName: "",
  lastName: "",
  houseNumber: "",
  postcode: "",
};

export const titles = ["Mr", "Mrs", "Miss", "Ms", "Dr"];

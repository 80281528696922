import React from "react";
import PropTypes from "prop-types";
import { Grid, Box, Avatar, Typography } from "@mui/material";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import FloatingButton from "@components/floating-button";
import { RoundedButton } from "@components/elements";
import { QRBox } from "./style";
import { useKeycloak } from "@react-keycloak/web";

function UserOverview({ variant }) {
  const { keycloak } = useKeycloak();
  const navigateTo = useNavigate();

  const {
    staffImageUrl,
    id,
    displayName,
    userId,
    qrCode: { qrCodeImageSVG, qrCodeImagePNG },
    team: { teamName, id: teamId },
  } = useSelector((state) => state.staff);

  const navigateToTipsHistory = () => navigateTo(`/tips-history/${id}`);

  return (
    <Grid container rowGap={5}>
      <Grid xs={5} item>
        <Box display="flex" justifyContent="center">
          <Avatar
            alt={displayName}
            src={staffImageUrl}
            sx={{ width: 100, height: 100 }}
          />
        </Box>
      </Grid>
      <Grid item xs={7}>
        <Typography variant="h2" fontWeight={500} gutterBottom>
          {displayName}
        </Typography>
        <Typography variant="h4" fontWeight={400} color="#909090" gutterBottom>
          {teamName}
        </Typography>
        <Box width="138px">
          <RoundedButton
            variant="outlined"
            onClick={navigateToTipsHistory}
            fullWidth
          >
            My Tips
          </RoundedButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <QRBox minHeight="180px" minWidth="180px">
            <img src={qrCodeImagePNG} alt="staff-qr" height="180" />
          </QRBox>
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Box display="flex" justifyContent="center">
          <Box width="186px">
            <RoundedButton
              variant="outlined"
              onClick={() => navigateTo(`/team/${teamId}/user/${userId}`)}
              fullWidth
            >
              Go To My Team
            </RoundedButton>
          </Box>
        </Box>
      </Grid>
      <FloatingButton position="relative" onClick={() => keycloak.logout()}>
        Log Out
      </FloatingButton>
    </Grid>
  );
}

UserOverview.propTypes = {};

export default UserOverview;

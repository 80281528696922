import React from "react";
import PaymentHandler from "@containers/payment";

export const transformName = (name) => {
  let paymentGatewayName = name;

  if (paymentGatewayName === "tiger-tms") {
    paymentGatewayName = "room-charge";
  }

  return `Pay with ${String(paymentGatewayName).replace("-", " ")}`;
};

export const disableAccordion = (val) => Boolean(val === "unverified");

export const stripe = ["stripe", "stripe-us"];

export const paymentGatewaySelector = (name, props) => {
  if (stripe.includes(name)) {
    return <PaymentHandler variant="stripe" {...props} />;
  }
  if (name === "tiger-tms") {
    return <PaymentHandler variant="room-charge" {...props} />;
  }
};

import React from "react";
import RoomChargesForm from "@components/room-charges-form";
import Stripe from "@containers/stripe";
import DojoPayment from "@containers/dojo-payment";

import { useSelector } from "react-redux";
import { CircularProgress, Box } from "@mui/material";
import { paymentGatewaySelector, stripe } from "./constants";

export default function PaymentGateway({ ...props }) {
  const { paymentGateways, isLoading, selectedPaymentGateway } = useSelector(
    (state) => state.paymentGateway
  );

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  // if (paymentGateways.length === 1) {
  //   const [gateway] = paymentGateways;
  //   return paymentGatewaySelector(gateway?.paymentGateway, props);
  // }

  console.log({ selectedPaymentGateway });

  if (selectedPaymentGateway === "tiger-tms") {
    return <RoomChargesForm {...props} />;
  }

  if (stripe.includes(selectedPaymentGateway)) {
    return <Stripe {...props} />;
  }

  if (selectedPaymentGateway === "dojo") {
    return <DojoPayment {...props} />;
  }

  return null;
}

export const titleText = {
  color: (theme) => {
    return theme.palette.textShades.title;
  },
};

export const customAmountText = {
  color: (theme) => {
    return theme.palette.primary.contrastText;
  },
};

import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import { Typography, Grid, Button } from "@mui/material";
import { Box } from "@mui/material";
import FetchLogo from "@components/fetch-logo";

function PaymentFailed({ classes }) {
  const { cardRejected } = useSelector((state) => state.payment);
  if (!cardRejected) {
    return null;
  }

  return (
    <Dialog
      onClose={() => {}}
      aria-labelledby="simple-dialog-title"
      open
      fullScreen
    >
      <Box
        justifyContent="center"
        height="100%"
        display="flex"
        alignItems="center"
        backgroundColor="#000"
        color="#fff"
      >
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs="12" container justifyContent="center">
            <Typography align="center">
              <FetchLogo />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h5" color="#fff">
              Unable to confirm payment
            </Typography>
            <Typography
              align="center"
              variant="h5"
              color="#fff"
              fontWeight={500}
              fontSize=""
            >
              <Box fontSize="16px"> Card not supported</Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center">
              <Button
                variant="contained"
                onClick={() => window.location.reload()}
              >
                Try again
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

PaymentFailed.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default PaymentFailed;

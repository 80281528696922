import React from "react";
import PropTypes from "prop-types";
import { Button as MuiButton, Box, Typography, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

function FloatingButton({
  position,
  onClick,
  message,
  error,
  color,
  children,
  buttonProps,
  sx,
}) {
  return (
    <Box position={position} bottom="18px" width="100%" left="0px">
      <Box position="relative" display="flex" justifyContent="center">
        <Grid container>
          <Grid item xs={12}>
            {message && (
              <Typography
                variant="subText6"
                sx={{ marginBottom: "10px" }}
                align="center"
              >
                {message}
              </Typography>
            )}
            {error && (
              <Typography
                variant="subText6"
                sx={{ marginBottom: "10px", color: "red" }}
                align="center"
              >
                {error}
              </Typography>
            )}
          </Grid>

          <Grid container item xs={12} justifyContent="center">
            <MuiButton
              variant="contained"
              fullWidth
              sx={{
                borderRadius: "100px",
                padding: "16px",
                fontWeight: 600,
                fontSize: 16,
                textTransform: "unset",
                width: "100%",
                ...sx,
              }}
              size="small"
              color={color}
              {...buttonProps}
              onClick={onClick}
            >
              {children}
            </MuiButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

FloatingButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]).isRequired,
  message: PropTypes.string,
  position: PropTypes.string,
  color: PropTypes.oneOfType([
    PropTypes.oneOf([
      "inherit",
      "primary",
      "secondary",
      "success",
      "error",
      "info",
      "warning",
    ]),
    PropTypes.string,
  ]),
};
FloatingButton.defaultProp = {
  onClick: () => {},
  message: null,
  position: "fixed",
  buttonProps: {},
  color: "primary",
};

export default FloatingButton;

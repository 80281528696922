import * as yup from "yup";
import { min } from "mathjs";

export const initialValues = (tipsAmount) => ({
  tipAmount: tipsAmount,
  specialMessage: "",
  selectedStaff: null,
  customTip: undefined,
  preDefinedTip: tipsAmount,
  customFields: {},
});

export const tipFormSchema = yup.object({
  tipAmount: yup.number().max(99999.99).required().label("Tip"),
});

export const tipPrecision = 2;

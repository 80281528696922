import { useSelector, useDispatch } from "react-redux";
import { UIAction } from "@store/ui/reducer";

function useGiftAidValidator() {
  const { tipsAmount } = useSelector((state) => state.order);
  const { giftAidData, isGiftAidOptedIn } = useSelector((state) => state.tip);
  const team = useSelector((state) => state.team);

  const { isGiftAidEnable } = team;

  if (!isGiftAidEnable || !isGiftAidOptedIn) {
    return true;
  }

  if (tipsAmount <= 20) {
    return true;
  }

  const data = Object.values(giftAidData).filter((item) => Boolean(item));
  // Number of fields
  return data.length === 5;
}

function useMandatoryCustomFieldsValidation() {
  const { customFieldsError } = useSelector((state) => state.tip);
  // const dispatch = useDispatch();
  // if (customFieldsError) {
  //   dispatch(UIAction.setSnackBarError("Please fill required fields"));
  // }
  return !customFieldsError;
}

function useIsAllowedToPay() {
  const { isAllowedToPay: isOrderDataValid } = useSelector(
    (state) => state.order
  );

  const customFieldsValidationPassed = useMandatoryCustomFieldsValidation();
  const validGiftAidData = useGiftAidValidator();
  const isAllowedToPay =
    isOrderDataValid && validGiftAidData && customFieldsValidationPassed;

  return {
    isAllowedToPay,
    validGiftAidData,
    isOrderDataValid,
    customFieldsValidationPassed,
  };
}

export default useIsAllowedToPay;

function getSubdomain(hostname) {
  var regexParse = new RegExp("[a-z-0-9]{2,63}.[a-z.]{2,5}$");
  var urlParts = regexParse.exec(hostname);
  return hostname.replace(urlParts[0], "").slice(0, -1);
}

export const getRedirectUrl = (teamId) => {
  let subDomain = getSubdomain(window.location.hostname) || "dev";
  if (subDomain !== "app") {
    subDomain = `app-${subDomain}`;
  }
  return `https://${subDomain}.fetchpay.com/get-payment/team/${teamId}`;
};

import React from "react";
import { GET_CONFIGURATION_QUERY } from "./GetConfigurationQuery";
import GraphqlClient from "@root/services/GraphqlService";

export const GET_CONFIGURATION_VARIABLE = (params) => ({
  variables: {
    ...params,
  },
});

export const getConfiguration = (type, entityId) => {
  return GraphqlClient.getConfiguration(
    GET_CONFIGURATION_QUERY,
    GET_CONFIGURATION_VARIABLE({ type, entityId })
  )
    .then(({ data, errors }) => {
      if (errors) {
        throw errors[0];
      }
      const dataResponse = data?.configuration?.configuration ?? {};
      return dataResponse;
    })
    .catch((error) => {
      throw error;
    });
};

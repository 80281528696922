import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Avatar,
  Link,
  Divider,
  Box,
  Button,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import copy from "copy-to-clipboard";
import { UIAction } from "@root/store/ui/reducer";
import { getRedirectUrl } from "./constant";
import { PrimaryButton } from "@root/feature/v2/feedback/feedback/constant";

function PositiveFeedbackResponse({ onSocialPlatFormClick }) {
  const { feedbackSetting, comment } = useSelector((state) => state.feedback);
  const dispatch = useDispatch();

  const {
    feedbackSharingPlatforms = [],
    feedbackSharingEnabled,
    additionalFeedbackEnabled,
    isTippingEnabled,
    tippingEnabledForTeamId,
    positiveFeedbackReviewMessage,
    positiveFeedbackTipsMessage,
  } = feedbackSetting;

  const handleCopy = (feedback) => {
    copy(feedback);
    dispatch(UIAction.setSnackBarSuccess("Feedback copied to clipboard"));
  };

  const feedbackIsEnabled =
    feedbackSharingEnabled && feedbackSharingPlatforms.length;

  if (!feedbackIsEnabled && !isTippingEnabled) {
    return null;
  }
  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        {isTippingEnabled && (
          <Grid item xs={12}>
            <Link
              href={getRedirectUrl(tippingEnabledForTeamId)}
              target="_blank"
            >
              <PrimaryButton color="primary" variant="rounded" fullWidth>
                {" "}
                Leave a tip{" "}
              </PrimaryButton>
              <Typography
                sx={{
                  fontSize: "16px!important",
                  color: "#818181",
                  paddingTop: "20px",
                }}
                align="center"
              >
                {positiveFeedbackTipsMessage}
              </Typography>
            </Link>
            <Box display="flex" justifyContent="center">
              <Divider style={{ width: "50%", marginTop: "30px" }} />
            </Box>
          </Grid>
        )}
        {feedbackIsEnabled && (
          <Grid xs={12} item>
            <Typography variant="subTitle5" align="center">
              <Box sx={{ fontSize: "16px!important", color: "#818181" }}>
                {positiveFeedbackReviewMessage}
              </Box>
            </Typography>
          </Grid>
        )}
        {feedbackSharingEnabled && (
          <Grid item xs={12}>
            <Grid container justifyContent="space-evenly" spacing={2}>
              {feedbackSharingPlatforms.map((platform) => (
                <Grid
                  item
                  xs={12 / feedbackSharingPlatforms.length}
                  onClick={() => onSocialPlatFormClick(platform.displayName)}
                >
                  <Link href={platform.url} target="_blank">
                    <Box
                      display="flex"
                      justifyContent="center"
                      style={{
                        backgroundColor: "#111111",
                        borderRadius: "31px",
                        padding: "8px 20px",
                      }}
                    >
                      <Avatar src={platform.logoUrl} />
                    </Box>
                  </Link>
                </Grid>
              ))}
            </Grid>
            {comment.length > 0 && (
              <Divider style={{ width: "100%", marginTop: "30px" }} />
            )}
          </Grid>
        )}

        {comment.length > 0 && (
          <Grid item xs={8}>
            <Typography
              variant="h3"
              style={{ fontSize: "0.938rem", color: "#818181" }}
            >
              Save time, copy and paste your comment
            </Typography>
          </Grid>
        )}
        {comment.length > 0 && (
          <Grid item xs={4}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              fullWidth
              onClick={() => handleCopy(comment)}
              style={{
                borderRadius: "13px",
                height: 25,
                marginRight: "8px",
              }}
            >
              Copy
            </Button>
          </Grid>
        )}
        {comment.length > 0 && (
          <Grid item xs={12}>
            <Box
              style={{
                backgroundColor: "#626262",
                borderRadius: "12px",
                minHeight: "104px",
                color: "#fff",
              }}
              padding="16px"
            >
              {comment}
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

PositiveFeedbackResponse.propTypes = {};

export default PositiveFeedbackResponse;

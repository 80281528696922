// ==============================|| OVERRIDES - CARD ||============================== //
import { alpha } from "@mui/material/styles";

export default function MuiCard() {
  return {
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          // boxShadow: " 0px 0px 10px #D5D0C5",
          backgroundColor: theme.palette.tertiary.main,
          backgroundImage: "none",
          borderRadius: "2rem",
        }),
      },
    },
  };
}

import React from "react";
import PropTypes from "prop-types";
import StripeCardElements from "@components/stripe-card-elements";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { useStripeCardPayment } from "@hooks";

function CardPayment({
  onPaymentSuccess,
  setLoading,
  totalAmount,
  serviceFeeMessage,
  beforePayment,
  handlePaymentIntent,
  ...props
}) {
  const stripe = useStripe();
  const elements = useElements();
  const stripeParams = { stripe, elements, CardNumberElement };
  const paymentHandlerPayload = { onPaymentSuccess };

  const { handleCardSubmit } = useStripeCardPayment({
    ...stripeParams,
    ...paymentHandlerPayload,
  });

  const onSubmit = async (params, name) => {
    console.log("CardPayment :- Starting card payment ");
    console.log("CardPayment :- Starting card payment loader screen ");
    setLoading(true);
    try {
      console.log("CardPayment :- Starting before card payment checks ");
      await beforePayment();
      console.log("CardPayment :- Completed before card payment checks ");
      console.log("CardPayment :- Starting to create intent  ");
      const { clientSecret, paymentId } = await handlePaymentIntent();
      console.log("CardPayment :- Completed intent creation ");
      console.log("CardPayment :- Starting handleCardSubmit fn ");
      const response = await handleCardSubmit({
        e: params,
        name,
        clientSecret,
      });
      console.log("CardPayment :- Completed handleCardSubmit fn ");
      if (response) {
        onPaymentSuccess(paymentId);
      }
      setLoading(false);
      console.log("CardPayment :- Completed card payment loader screen ");
      console.log("CardPayment :- Completed card payment ");
    } catch (e) {
      console.log("CardPayment :- Error in card payment", e);
      console.log(e);
      setLoading(false);
    }
  };

  if (!stripe || !elements) {
    return null;
  }

  return (
    <StripeCardElements
      onSubmit={onSubmit}
      totalAmount={totalAmount}
      serviceFeeMessage={serviceFeeMessage}
      {...props}
    />
  );
}

CardPayment.propTypes = {};

export default CardPayment;

import React, { useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import Payment from "@containers/payment";
import { useDispatch, useSelector } from "react-redux";
import { action as UIActions } from "@store/ui/reducer";
import useIsAllowedToPay from "@hooks/is-allowed-to-pay";
import PaymentGateways from "@containers/payment-gateways";
import { getConnectedPaymentGateways } from "@root/store/payment-gateway/api";
import PaymentMethodButton from "@components/payment-method-button";
import PaymentGateway from "@containers/payment-gateways/PaymentGateways";

function PaymentContainer(props) {
  const [showCardPayment, setShowCardPayment] = useState(false);
  const dispatch = useDispatch();
  const { showWhen } = props;
  const { paymentGateways } = useSelector((state) => state.paymentGateway);
  const { outletId } = useSelector((state) => state.outlet);

  React.useEffect(() => {
    dispatch(getConnectedPaymentGateways({ outletId }));
  }, []);

  const {
    isAllowedToPay,
    validGiftAidData,
    isOrderDataValid,
    customFieldsValidationPassed,
  } = useIsAllowedToPay();

  const verifyAllRequiredDataIsFilled = () => {
    if (!isAllowedToPay) {
      if (!isOrderDataValid) {
        dispatch(UIActions.setSnackBarError("Please enter a valid amount"));
      }
      if (!validGiftAidData) {
        dispatch(UIActions.setSnackBarError("Please enter gift aid details"));
      }
      if (!customFieldsValidationPassed) {
        dispatch(UIActions.setSnackBarError("Please fill required fields"));
      }

      return;
    }
    return true;
  };

  const checkIfNoGatewayConnected = paymentGateways.filter(
    ({ kycStatus }) => kycStatus !== "unverified"
  );

  return (
    <Grid container spacing={2} mt={2}>
      <Grid item xs={12}>
        <PaymentMethodButton
          verifyAllRequiredDataIsFilled={verifyAllRequiredDataIsFilled}
        />
      </Grid>

      <Grid item xs={12}>
        <Payment>
          <PaymentGateway
            verifyAllRequiredDataIsFilled={verifyAllRequiredDataIsFilled}
          />
        </Payment>
      </Grid>
    </Grid>
  );
}

PaymentContainer.propTypes = {};

export default PaymentContainer;

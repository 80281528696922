import React from "react";
import PropTypes from "prop-types";
import { Button as MuiButton, Box } from "@mui/material";
import { alpha, styled, useTheme } from "@mui/material/styles";

const Button = styled(MuiButton)(({ theme, state }) => {
  console.log(state);
  return {
    borderRadius: "50%",
    height: "88px",
    width: "88px",
    textTransform: "none",
    border: `1px solid ${alpha(theme.palette.secondary.contrastText, 0.4)}`,

    color: theme.palette.secondary.contrastText,
  };
});

function CircleButton({ selected, ...props }) {
  const theme = useTheme();
  const activeBorderColor = theme.palette.secondary.main;
  const inActiveBorderColor = theme.palette.primary.contrastText;
  return (
    <Box display="flex" justifyContent="center">
      <Button
        {...props}
        variant={selected ? "contained" : "outlined"}
        sx={{
          borderColor: selected ? activeBorderColor : inActiveBorderColor,
          "&:hover": {
            borderColor: selected ? activeBorderColor : inActiveBorderColor,
          },
        }}
      >
        {props.children}
      </Button>
    </Box>
  );
}

CircleButton.propTypes = {
  selected: PropTypes.bool,
};

CircleButton.defaultProp = {
  selected: false,
};

export default CircleButton;

import React from "react";
import { Grid } from "@mui/material";
import PageHeader from "@components/page-header";
import LoginForm from "@components/login-form";
import Padding from "@components/padding";

function SignIn() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader />
      </Grid>
      <Grid item xs={12}>
        <Padding>
          <LoginForm />
        </Padding>
      </Grid>
    </Grid>
  );
}

SignIn.propTypes = {};

export default SignIn;

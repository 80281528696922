// ==============================|| OVERRIDES - MuiTypography ||============================== //

export default function MuiTypography(theme) {
  return {
    MuiTypography: {
      defaultProps: {
        color: theme.palette.primary.contrastText,
        variantMapping: {
          subHeading1: "p",
          subHeading2: "p",
          subHeading3: "p",
          subHeading4: "p",
          subHeading5: "p",
          subHeading6: "p",

          subTitle1: "p",
          subTitle2: "p",
          subTitle3: "p",
          subTitle4: "p",
          subTitle5: "p",
          subTitle6: "p",

          subText1: "p",
          subText2: "p",
          subText3: "p",
          subText4: "p",
          subText5: "p",
          subText6: "p",

          notetext: "p",
          smalltext: "p",
        },
      },
    },
  };
}

/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import {
  unstable_HistoryRouter as HistoryRouter,
  Route,
  BrowserRouter,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import Routes from "./route";
import Snackbar from "@components/snackbar";
import { SnackBar } from "@components";
import { Box } from "@mui/material";

import logo from "./logo.svg";
import "./App.css";

export const history = createBrowserHistory();

function App() {
  const { snackBarInfo, product } = useSelector((state) => state.ui);
  const { message, severity } = snackBarInfo;

  useEffect(() => {
    document.title = product === "fetchPay" ? "Fetch Pay" : "Fetch My Tip";
  }, [product]);

  return (
    <React.Fragment>
      <Box className="portrait" component="div">
        <BrowserRouter history={history}>
          <Routes />
        </BrowserRouter>
      </Box>
      {Boolean(message) && <Snackbar text={message} severity={severity} />}
    </React.Fragment>
  );
}

export default App;

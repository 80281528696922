import React from "react";
import PropTypes from "prop-types";
import { Avatar, Grid, Typography, Box } from "@mui/material";
import { RoundedButton } from "@components/elements";

function Staff({
  displayName,
  staffImageUrl,
  id,
  selected,
  onSelect,
  ...props
}) {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Avatar
            alt={displayName}
            src={staffImageUrl}
            sx={{ width: 134, height: 134 }}
          />
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Typography variant="h3" align="center" pt={2} pb={2}>
          {displayName}
        </Typography>
      </Grid>
      <Grid xs={9} item>
        <Box display="flex" justifyContent="center">
          <RoundedButton
            variant={selected ? "contained" : "outlined"}
            onClick={() => onSelect(id)}
            size="small"
            fullWidth
          >
            Select
          </RoundedButton>
        </Box>
      </Grid>
    </Grid>
  );
}

Staff.propTypes = {
  displayName: PropTypes.string.isRequired,
  staffImageUrl: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Staff;

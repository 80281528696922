import { gql } from "@apollo/client";

export const GET_TEAM_DETAILS_QUERY = gql`
  query getTeamDetails($teamId: Int!) {
    getTeamDetails(teamId: $teamId) {
      id
      teamName
      description
      tipsDisplayLabel
      isFeedbackEnabled
      teamMessage
      tipsDefaultValue
      isPartialTippingEnable
      teamImageUrl
      paymentMode
      isGiftAidEnable
      customFields {
        name
        label
        promptMessage
        isEnable
        isMandatory
      }
      isDefaultValueEnabled
      isCustomAmountEnabled
      outlet {
        name
        id
        logoUrl
        feedbackSetting {
          food
          service
          hygiene
          recommendation
          feedbackSharingEnabled
          additionalFeedbackEnabled
          feedbackSharingRatingThreshold
          feedbackSharingPlatforms {
            displayName
            url
            logoUrl
          }
          feedbackResponsePersonProfileImageUrl
          feedbackResponsePersonRole
          feedbackResponsePersonName
          negativeFeedbackResponseMessage
          positiveFeedbackResponseMessage
          feedbackSubHeader
          feedbackHeader
          foodFeedbackRatingLabel
          serviceFeedbackRatingLabel
          hygieneFeedbackRatingLabel
          recommendationFeedbackRatingLabel
        }
        outletCurrency {
          currencyCode
          currencySymbol
        }
        outletCountry {
          countryName
          countryCode
        }
        serviceCharge {
          serviceChargeFeePercentage
          serviceChargeFeeAmount
          isServiceChargeEnabled
          isServiceChargeOptable
        }
        tenant {
          id
          name
        }
      }
      staff {
        displayName
        id
        userId
        staffImageUrl
      }
      qrCode {
        id
        qrCodeUUId
        qrCodeImagePNG
        qrCodeImageSVG
        qrCodeURL
      }
      urlLinkButton {
        label
        url
        isEnable
      }
    }
  }
`;

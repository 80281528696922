import { validateEmail, validatePhoneNumber } from "@root/util/validation";

export const positiveFeedbackMessage =
  "Amazing, we are so glad to hear that you had a great time with us.";
export const negativeFeedbackMessage =
  "We’re sorry things haven’t been up to the standards we’d expect, our manager has been informed and will be in touch soon  ";
export const normalFeedbackMessage =
  "Thanks for the feedback. We hope you enjoyed your visit, and hopefully we’ll see you again soon";

export const feedbackMessageType = ({
  negativeFeedbackResponseMessage,
  positiveFeedbackResponseMessage,
}) => ({
  negative: negativeFeedbackResponseMessage ?? negativeFeedbackMessage,
  positive: positiveFeedbackResponseMessage ?? positiveFeedbackMessage,
  normal: normalFeedbackMessage,
});

export const getTypeOfFeedback = ({
  averageRating,
  feedbackSharingRatingThreshold,
  negativeFeedbackAverageThreshold,
  negativeFeedbackThresholds,
  ...params
}) => {
  const { food, hygiene, service, recommendation } = params;
  const isRatingEnabled = food || hygiene || service || recommendation;
  const { feedbackOptions } = params;
  const ratings = feedbackOptions.reduce(
    (acc, val) => ({ ...acc, [val.name]: val.rating }),
    {}
  );

  let isNegativeRating = false;

  if (ratings?.foodRating <= negativeFeedbackThresholds?.food) {
    isNegativeRating = true;
  }
  if (ratings?.serviceRating <= negativeFeedbackThresholds?.service) {
    isNegativeRating = true;
  }
  if (ratings?.hygieneRating <= negativeFeedbackThresholds?.hygiene) {
    isNegativeRating = true;
  }
  if (ratings?.recommendRating <= negativeFeedbackThresholds?.recommendation) {
    isNegativeRating = true;
  }

  if (isNegativeRating) {
    return "negative";
  }

  if (averageRating >= feedbackSharingRatingThreshold) {
    return "positive";
  }

  return "normal";
};

export const verifyDetail = (contactDetail) => {
  const sanitizedContactDetail = String(contactDetail).trim();
  if (isNaN(sanitizedContactDetail[0])) {
    //email validation
    const isValidEmail = validateEmail(sanitizedContactDetail);
    return {
      isValidDetail: isValidEmail,
      errorMessage: "Please enter valid email address",
    };
  }

  const isValidPhoneNumber = validatePhoneNumber(sanitizedContactDetail);

  return {
    isValidDetail: isValidPhoneNumber,
    errorMessage: "Please enter valid phone number",
  };
};

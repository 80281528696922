import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";
import PageHeader from "@components/page-header";
import StaffTips from "@components/staff-tips";
import Tips from "@components/tips";
import { getStaff } from "@store/staff/api";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TipForm from "@feature/v2/tip-form";
import PaymentMethodOptions from "@components/payment-method-options";
import Loading from "@components/loading";
import Padding from "@components/padding";
import StaffOverview from "@root/feature/v2/staff-overview/StaffOverview";
import PaymentContainer from "@root/feature/v2/payment-container";
import Background from "@components/background/Background";
import FooterLogo from "@components/footer-logo";

function GetStaffTips(props) {
  const { staffId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.staff);

  const handleFailure = (error) => {
    if (error === "Staff is disabled") {
      navigate("/not-found/Account is deactivated/Please contact manager");
    }
  };

  useEffect(() => {
    window.localStorage.clear();
    dispatch(getStaff(staffId)).catch(handleFailure);
  }, []);

  if (isLoading) {
    return <Loading message="Loading details" />;
  }

  window.localStorage.setItem("redirectUrl", `/get-tips/staff/${staffId}`);

  return (
    <Padding>
      <Grid container>
        <Grid item xs={12}>
          <StaffOverview />
        </Grid>
        <Grid item xs={12}>
          <TipForm variant="staff" />
        </Grid>
        <Grid item xs={12}>
          <PaymentContainer />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <FooterLogo />
          </Box>
        </Grid>
      </Grid>
    </Padding>
  );
}

GetStaffTips.propTypes = {};

export default GetStaffTips;

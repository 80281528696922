import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { getDojoConfig } from "./constant";
import { alpha } from "@mui/material/styles";
import "./style.css";

const DojoWallet = ({ clientSecret, amount, ...props }) => {
  const { onIframeLoaded, loaded, setLoaded } = props;
  const [card, setCard] = useState(null);
  const isLoading = props.isIntentLoading || !loaded;
  async function paymentComplete(response) {
    try {
      if (response?.statusCode === 0) {
        return await props.onPaymentSuccess(props.paymentId);
      }
      setLoaded(true);
      return props.handleSubmitError([response?.message]);
    } catch (e) {
      console.log("sadnksabdhksab ", e);
      props.handleSubmitError(e);
    }
  }

  useEffect(() => {
    props.setLoaded((state) => false);
    const config = getDojoConfig({
      clientSecret,
      onIframeLoaded,
      setLoading: props.setLoading,
    });
    document.getElementById(config?.containerId).innerHTML = "";
    const newCard = new window.dojo.WalletPayment(
      config,
      props.displayErrorsCallback,
      paymentComplete
    );
    setCard(newCard);
  }, [clientSecret]);

  return (
    <div>
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "80px" }}
          flexDirection="column"
          color="#fff"
        >
          <CircularProgress sx={{ color: "#fff" }} />

          <p>Loading payment options</p>
        </Box>
      )}

      <Box>
        {props.serviceFeeMessage && (
          <Typography
            variant="subText6"
            align="center"
            color={(theme) => alpha(theme.palette.primary.contrastText, 0.6)}
            mt={2}
            mb={1}
          >
            {props.serviceFeeMessage}
          </Typography>
        )}
      </Box>
      {props.errors.length > 0 && (
        <Grid item xs={12}>
          {props.errors.map((error, index) => (
            <Typography
              variant="subText6"
              color="red"
              key={index}
              align="center"
            >
              {error}
            </Typography>
          ))}
        </Grid>
      )}

      <Box
        id="demo-payment-wallet"
        sx={{ display: "flex", justifyContent: "center", mt: 1 }}
      >
        {/* Render your payment UI here */}
        {/* You can also add a button for payment */}
      </Box>
    </div>
  );
};

export default DojoWallet;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { feedbackMessageType } from "./constant";
import { getTypeOfFeedback } from "@root/util/feedback";
import NegativeFeedbackResponse from "@components/negative-feedback-response";
import PositiveFeedbackResponse from "@components/positive-feedback-response";
import { useParams } from "react-router-dom";
import updateFeedback from "@graphql/feedback/hooks/updateFeedback";
import { feedbackActions } from "@root/store/feedback/reducer";
import { UIActions } from "@root/store/ui/reducer";
import FooterLogo from "@components/footer-logo";
import FetchLogo from "@components/fetch-logo";

function FeedbackResponse(props) {
  const {
    averageRating,
    feedbackSetting,
    socialPlatformsClicked,
    feedbackOptions,
  } = useSelector((state) => state.feedback);

  const dispatch = useDispatch();

  const typeOfFeedback = getTypeOfFeedback({
    ...feedbackSetting,
    averageRating,
    feedbackOptions,
  });

  const { feedbackId } = useParams();

  const saveSocialPlatForms = (clickedPlatform) => {
    if (socialPlatformsClicked.includes(clickedPlatform)) {
      return false;
    }
    updateFeedback({
      feedbackId,
      socialPlatformsSharedOn: [...socialPlatformsClicked, clickedPlatform],
    });
    dispatch(feedbackActions.setSocialPlatformsClicked(clickedPlatform));
  };

  return (
    <Grid
      container
      p={3}
      sx={{
        minHeight: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
        backgroundColor: "#111111",
      }}
    >
      <Grid item xs={12} sx={{ flexBasis: 0 }}>
        <Box display="flex" justifyContent="center" mb={8} mt={2}>
          <FetchLogo />
        </Box>
        <Card>
          <CardContent>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ marginBottom: "30px", paddingTop: "20px" }}
              >
                <Typography
                  variant="subTitle2"
                  align="center"
                  style={{ fontSize: "1.5rem", color: "#8E8E8E" }}
                >
                  {feedbackMessageType(feedbackSetting)[typeOfFeedback]}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {typeOfFeedback === "positive" && (
                  <PositiveFeedbackResponse
                    onSocialPlatFormClick={saveSocialPlatForms}
                  />
                )}
                {typeOfFeedback === "negative" && <NegativeFeedbackResponse />}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sx={{ flexBasis: 0 }}>
        <Box display="flex" justifyContent="center" mt={2} mb={2}>
          <FooterLogo />
        </Box>
      </Grid>
    </Grid>
  );
}

FeedbackResponse.propTypes = {};

export default FeedbackResponse;

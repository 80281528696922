import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { action as UiActions } from "@store/ui/reducer";
import { addCustomFieldFeedback } from "@graphql/tips/add-custom-field-feedback";

function useCustomField() {
  const dispatch = useDispatch();
  const { id: tipId, customFields } = useSelector((state) => state.tip);
  const { id: teamId } = useSelector((state) => state.team);

  const addCustomFields = async () => {
    const fields = Object.values(customFields)
      .filter(({ value }) => Boolean(value))
      .map(({ name, value }) => ({
        tipPaymentId: tipId,
        name,
        value,
      }));

    if (!fields.length) {
      return;
    }

    try {
      await addCustomFieldFeedback({ fields, teamId });
    } catch (error) {
      dispatch(UiActions.setSnackBarError("Unable to save custom fields"));
      throw error;
    }
  };

  return { addCustomFields };
}

export default useCustomField;

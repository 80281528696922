import { createTipPayment } from "@graphql/tips/create-tip-payment";
import { addGiftAidData } from "@graphql/tips/add-gift-aid-data";
import { actions as TipActions } from "./reducer";

export const createTipRecord = (params) => (dispatch) => {
  dispatch(TipActions.startLoading());
  return createTipPayment(params)
    .then((res) => {
      dispatch(TipActions.setTip(res));
    })
    .catch((error) => {
      dispatch(TipActions.updateError(error));
    })
    .finally(() => {
      dispatch(TipActions.endLoading());
    });
};

export const addGiftAidRecord = (params) => (dispatch) => {
  return addGiftAidData(params)
    .then((res) => {})
    .catch((error) => {})
    .finally(() => {});
};

import FloatingButton from "@components/floating-button";

export const getValidCustomerDetailFromValues = (customerDetailFromValues) => {
  return Object.entries(customerDetailFromValues).reduce((acc, entries) => {
    const [key, value] = entries;
    if (Boolean(value)) {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {});
};

export const transformRatingPayload = (feedbackOptions) =>
  feedbackOptions.reduce(
    (acc, val) => ({ ...acc, [val.name]: val.rating }),
    {}
  );

export const PrimaryButton = ({
  onClick,
  disableSubmitButton,
  title = " Submit Feedback",
  ...props
}) => (
  <FloatingButton
    color="secondary"
    sx={{
      width: "100",
      padding: "11px",

      // backgroundColor: (theme) => `${theme.palette.primary.contrastText}`,
      // color: (theme) => theme.palette.primary.main,
      // "&:hover": {
      //   backgroundColor: (theme) => `${theme.palette.primary.contrastText}`,
      // },
    }}
    onClick={onClick}
    buttonProps={{ disabled: disableSubmitButton }}
  >
    {props.children ? props.children : title}
  </FloatingButton>
);

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TipHistoryCard from "@components/tip-history-card";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getMonthsStartAndEndDate } from "@root/util/dates";
import { useKeycloak } from "@react-keycloak/web";
import jwt from "jwt-decode";
import { getStaff, getTipsInformation } from "@store/staff/api";
import Loading from "@components/loading";

function TipHistory(props) {
  const { userId: staffId } = useParams();
  const dispatch = useDispatch();
  const {
    isLoading,
    error,
    tips,
    id,
    staffImageUrl,
    displayName,
    tipsTotal,
    // outlet: { outletId },
  } = useSelector((state) => state.staff);

  const { keycloak } = useKeycloak();
  const parsedToken = jwt(keycloak.token);
  const { sub: userId } = parsedToken;

  const { startDate, endDate } = getMonthsStartAndEndDate();

  const handleFailure = (error) => {
    console.log(error);
    // navigate("/not-found");
  };

  const getTipsHistoryInformation = (staff) => {
    const { id: staffId, team } = staff;
    const { outlet } = team;
    const { id: outletId } = outlet;

    dispatch(
      getTipsInformation({
        startDate,
        endDate,
        staffId,
        outletId: parseInt(outletId),
      })
    );
  };

  useEffect(() => {
    dispatch(getStaff(userId))
      .then(getTipsHistoryInformation)
      .catch(handleFailure);
  }, []);

  if (isLoading || !tips) {
    return <Loading />;
  }

  if (error) {
    return null;
  }

  console.log("Loading ends");

  return (
    <Grid container>
      <Grid item xs={12}>
        <TipHistoryCard
          name={displayName}
          totalTipsAmount={tipsTotal}
          tips={tips}
          imageUrl={staffImageUrl}
          variant="staff"
        />
      </Grid>
    </Grid>
  );
}

TipHistory.propTypes = {};

export default TipHistory;

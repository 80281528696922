import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { actions as PaymentActions } from "@store/payment/reducer";

function useStripeCardPayment(props) {
  const dispatch = useDispatch();
  const { stripe, elements, CardNumberElement } = props;
  const { onPaymentSuccess } = props;
  const { isAllowedToPay } = useSelector((state) => state.order);
  const { error, errorType } = useSelector((state) => state.payment);

  const confirm = ({ paymentIntent: intent }) => {
    if (!stripe) {
      return;
    }

    const { client_secret: clientSecret } = intent;

    if (!clientSecret) {
      return;
    }
    console.log("CardPayment :- Starting stripe confirm api call");
    return stripe.retrievePaymentIntent(clientSecret).then((response) => {
      const { paymentIntent } = response;
      console.log(
        "CardPayment :-  Stripe confirm api call completed",
        paymentIntent
      );

      const { status: intentStatus } = paymentIntent;

      if (intentStatus === "succeeded" || intentStatus === "requires_capture") {
        console.log(
          "CardPayment :- Status is succeeded, calling OnPaymentSuccess Fn",
          paymentIntent
        );
        return response;
      }

      switch (paymentIntent.status) {
        case "processing":
          dispatch(PaymentActions.setMessage("Your payment is processing."));
          break;
        case "requires_payment_method":
          dispatch(
            PaymentActions.updateError(
              "Your payment was not successful, please try again."
            )
          );
          break;
        default:
          dispatch(PaymentActions.updateError("Something went wrong."));
          break;
      }
      return false;
    });
  };

  const handleCardSubmit = async ({ e, name, clientSecret }) => {
    console.log("CardPayment :- Starting handleCardSubmit fn ");
    e.preventDefault();

    if (!isAllowedToPay) {
      return dispatch(PaymentActions.updateError("Please enter valid  amount"));
    }
    console.log("CardPayment :- isAllowedToPay to pay check passed");
    if (!stripe || !elements) {
      return;
    }

    if (!name) {
      dispatch(PaymentActions.updateError("Please enter cardholder name"));
      dispatch(PaymentActions.updateErrorType("validation_error"));

      setTimeout(() => {
        dispatch(PaymentActions.updateError(""));
      }, 2500);

      return;
    } else if (!!error && name) {
      dispatch(PaymentActions.updateError(""));
    } else {
      dispatch(PaymentActions.updateErrorType(null));
    }
    console.log("CardPayment :- name check passed");
    if (error && errorType !== "validation_error") {
      return;
    }
    console.log("CardPayment :- no other error present in system");

    try {
      console.log("CardPayment :- Starting to confirm card payment on stripe");
      dispatch(PaymentActions.startProcessing());
      const response = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name,
          },
        },
      });
      console.log(
        "CardPayment :- Completed api call to confirm card payment on stripe"
      );
      console.log(
        "CardPayment :- Starting error check to confirm card payment on stripe"
      );
      if (response?.error) {
        console.log(
          "CardPayment :- Found error in confirm card payment on stripe",
          response?.error
        );
        dispatch(PaymentActions.updateError(response?.error));
        throw response?.error;
      }
      console.log(
        "CardPayment :- Completed error check to confirm card payment on stripe"
      );

      console.log("CardPayment :- Starting stripe confirm fn");
      const confirmResult = await confirm(response);
      console.log("CardPayment :- Completed stripe confirm fn");
      return confirmResult;
    } catch (error) {
      console.log(error);
      if (error?.type === "card_error" || error?.type === "validation_error") {
        dispatch(PaymentActions.updateError(error.message));
        dispatch(PaymentActions.updateErrorType("validation_error"));
      } else {
        dispatch(PaymentActions.updateErrorType(null));
      }
    }
  };

  return { handleCardSubmit };
}

export default useStripeCardPayment;

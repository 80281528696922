import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useKeycloak } from "@react-keycloak/web";
import jwt from "jwt-decode";
import { useNavigate } from "react-router-dom";
import useGetUserId from "@hooks/use-get-user-id";
import { useSelector, useDispatch } from "react-redux";
import { getStaff } from "@store/staff/api";
import Dialog from "@components/dialog";
import UnexpectedError from "@components/unexpected-error";
import Loading from "@components/loading";
import { Grid } from "@mui/material";

function RedirectToUserProfile(props) {
  const userId = useGetUserId();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  window.keycloak = keycloak;
  const { error } = useSelector((state) => state.staff);

  const handleFailure = (error) => {
    setTimeout(() => {
      keycloak.logout({ redirectUri: process.env.PUBLIC_URL }).then((res) => {
        console.log("Logged Out");
      });
    }, 10000);
  };

  useEffect(() => {
    dispatch(getStaff(userId))
      .then((res) => {
        navigate(`/user/${userId}`);
      })
      .catch(handleFailure);
  }, []);

  if (error) {
    return (
      <Dialog>
        <Grid container height="100%">
          <UnexpectedError
            message={[
              "Invalid credentials please try again",
              "Redirecting to login page",
            ]}
            hideButton={true}
          />
        </Grid>
      </Dialog>
    );
  }

  return <Loading message="Please wait while we authenticate you" />;
}

RedirectToUserProfile.propTypes = {};

export default RedirectToUserProfile;

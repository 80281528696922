import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Box } from "@mui/material";

function NotFound({ message1, message2 }) {
  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <img src="/assets/images/404.svg" alt="404-page" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          align="center"
          sx={{ fontSize: 40, fontWeight: "bold" }}
          color="#242424"
          gutterBottom
        >
          Oops!
        </Typography>
        <Typography variant="subHeading5" align="center" color="#242424">
          {message1}
        </Typography>
        <Typography variant="subHeading5" align="center" color="#242424">
          {message2}
        </Typography>
      </Grid>
    </Grid>
  );
}

NotFound.propTypes = {
  message1: PropTypes.string,
  message2: PropTypes.string,
};

NotFound.defaultProps = {
  message1: "The page you are looking",
  message2: "for is not available!",
};

export default NotFound;

import React from "react";
import PropTypes from "prop-types";
import { Grid, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

function OutletOverview(props) {
  const { detail, name } = useSelector((state) => state.outlet);
  const { logoUrl } = detail;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" padding="5px" mt={3}>
          <img src={logoUrl} alt="outlet-logo" height="69" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          {name}
        </Typography>
      </Grid>
    </Grid>
  );
}

OutletOverview.propTypes = {};

export default OutletOverview;

export const getAverageFeedback = ({ feedbackOptions }) => {
  const averageRating =
    feedbackOptions.reduce((acc, val) => acc + val.rating, 0) /
    feedbackOptions.length;

  return averageRating;
};

const getIsEnabled = (feedbackSetting, key) => {
  try {
    return feedbackSetting[key];
  } catch (e) {
    return false;
  }
};

export const feedbackOptions = (feedbackSetting) => {
  const defaultRating = 5;
  const option = [
    {
      key: "food",
      title: feedbackSetting?.foodFeedbackRatingLabel ?? "Food",
      name: "foodRating",
      rating: defaultRating,
      get isEnabled() {
        return getIsEnabled(feedbackSetting, this.key);
      },
    },
    {
      key: "service",
      name: "serviceRating",
      title: feedbackSetting?.serviceFeedbackRatingLabel ?? "Service",
      rating: defaultRating,
      get isEnabled() {
        return getIsEnabled(feedbackSetting, this.key);
      },
    },
    {
      key: "hygiene",
      name: "hygieneRating",
      title: feedbackSetting?.hygieneFeedbackRatingLabel ?? "Atmosphere",
      rating: defaultRating,
      get isEnabled() {
        return getIsEnabled(feedbackSetting, this.key);
      },
    },
    {
      key: "recommendation",
      name: "recommendRating",
      title:
        feedbackSetting?.recommendationFeedbackRatingLabel ?? "Recommendation",
      rating: defaultRating,
      get isEnabled() {
        return getIsEnabled(feedbackSetting, this.key);
      },
    },
  ];

  return option.filter((item) => item.isEnabled);
};

export const transformFeedbackSetting = ({
  customerContactInfoSettings,
  ...setting
}) => {
  // Transforming  customer detail information
  let isCustomerContactInfoEnabled = false;
  let parsedDetail = null;

  if (Boolean(customerContactInfoSettings)) {
    parsedDetail = JSON.parse(customerContactInfoSettings);
    const { isNameEnabled, isEmailEnabled, isMobileNoEnabled, isTableEnabled } =
      parsedDetail;

    if (
      isNameEnabled ||
      isEmailEnabled ||
      isMobileNoEnabled ||
      isTableEnabled
    ) {
      isCustomerContactInfoEnabled = true;
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Transforming negative feedback information
  const negativeFeedbackThresholds = {};

  if (getIsEnabled(setting, "service")) {
    negativeFeedbackThresholds["service"] =
      setting?.serviceFeedbackRatingThreshold;
  }
  if (getIsEnabled(setting, "food")) {
    negativeFeedbackThresholds["food"] = setting?.foodFeedbackRatingThreshold;
  }
  if (getIsEnabled(setting, "hygiene")) {
    negativeFeedbackThresholds["hygiene"] =
      setting?.hygieneFeedbackRatingThreshold;
  }
  if (getIsEnabled(setting, "recommendation")) {
    negativeFeedbackThresholds["recommendation"] =
      setting?.recommendationFeedbackRatingThreshold;
  }
  /////////////////////////////////////////////////////////////////////////////
  return {
    ...setting,
    customerContactInfoSettings: parsedDetail,
    isCustomerContactInfoEnabled,
    negativeFeedbackThresholds,
  };
};

import { gql } from "@apollo/client";

export const CREATE_TIP_FEEDBACK = gql`
  mutation addCustomFieldFeedback(
    $teamId: Int
    $input: [CustomFieldFeedbackInput]
  ) {
    addCustomFieldFeedback(input: $input, teamId: $teamId) {
      id
    }
  }
`;

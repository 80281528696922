import React from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@mui/material";
import { detectDevice } from "@root/util/device";
import FloatingButton from "@components/floating-button";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";

const applePay = "/assets/images/apple-pay.svg";
const googlePay = "/assets/images/google-pay.svg";

function StripeButton({ error, message }) {
  const device = detectDevice();
  const { stripeIntentLoading } = useSelector((state) => state.payment);
  return (
    <FloatingButton
      error={error}
      message={message}
      sx={{
        backgroundColor: (theme) => `${theme.palette.common.white} !important`,
        minHeight: 61,
      }}
    >
      {stripeIntentLoading ? (
        <CircularProgress color="secondary" size="1.8rem" />
      ) : (
        <img
          src={device === "iOS" ? applePay : googlePay}
          style={{ height: 23, paddingLeft: "5px" }}
          alt="payment-button"
        />
      )}
    </FloatingButton>
  );
}

StripeButton.propTypes = {};

export default StripeButton;

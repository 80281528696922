import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, Radio, Typography } from "@mui/material";
import { action as paymentAction } from "@store/payment/reducer";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "./constant";

import {
  activeTabButtonStyle,
  buttons,
  getCards,
  tabButtonStyle,
  getDevice,
  getCardButtonStyle,
  availableCardBrandType,
  getCardBrandIcon,
} from "./constant";
import { PaymentActions } from "@root/store/payment/reducer";
import { PaymentGatewayActions } from "@root/store/payment-gateway/reducer";

function PaymentMethodButton(props) {
  const { selectedPaymentMode, allowedCardBrands } = useSelector(
    (state) => state.payment
  );
  const { activePaymentGateways, isLoading } = useSelector(
    (state) => state.paymentGateway
  );

  const dispatch = useDispatch();
  const device = getDevice();

  const isRoomChargeEnabled = activePaymentGateways.some(
    (item) => item?.paymentGateway === "tiger-tms" && Boolean(item?.isEnabled)
  );

  const isStripeEnabled = activePaymentGateways.some((item) =>
    ["stripe"].includes(item?.paymentGateway)
  );

  const isStripeUSEnabled = activePaymentGateways.some((item) =>
    ["stripe-us"].includes(item?.paymentGateway)
  );

  const isDojoEnabled = activePaymentGateways.some((item) =>
    ["dojo"].includes(item?.paymentGateway)
  );

  const googlePay = buttons?.isGooglePayActive;
  const applePay = buttons?.isApplePayActive;

  const handleChange = (value) => {
    if (!props.verifyAllRequiredDataIsFilled()) {
      return;
    }
    if (["card", "paymentButton"].includes(value)) {
      if (isStripeEnabled) {
        dispatch(PaymentGatewayActions.setSelectedPaymentGateway("stripe"));
      }
      if (isStripeUSEnabled) {
        dispatch(PaymentGatewayActions.setSelectedPaymentGateway("stripe-us"));
      }
      if (isDojoEnabled) {
        dispatch(PaymentGatewayActions.setSelectedPaymentGateway("dojo"));
      }
    }

    if (value === "roomCharge") {
      dispatch(PaymentGatewayActions.setSelectedPaymentGateway("tiger-tms"));
    }
    // dispatch(PaymentGatewayActions.setSelectedPaymentGateway(value));
    dispatch(PaymentActions.setSelectedPaymentMode(value));
    dispatch(PaymentActions.setMessage(""));
  };

  const isCardPaymentActive =
    selectedPaymentMode === "card" ? "active" : "deactive";
  const cardList = getCards("active");

  const disabledCards = availableCardBrandType.filter(
    (cardBrand) => !allowedCardBrands.includes(cardBrand)
  );

  const [disabledCardBrand1, ...disabledCardBrands] = disabledCards;

  useEffect(() => {
    console.log(isStripeEnabled, isStripeUSEnabled);
    if (isStripeEnabled || isStripeUSEnabled) {
      dispatch(PaymentGatewayActions.setSelectedPaymentGateway("stripe"));
      dispatch(PaymentActions.setSelectedPaymentMode("paymentButton"));
      dispatch(PaymentActions.setMessage(""));
      console.log("asdbjasvjhv");
    }
  }, [isStripeEnabled, isStripeUSEnabled]);

  return (
    <Grid container justifyContent="center" spacing={2.75}>
      <Grid item xs={12}>
        <Typography variant="subHeading5" color="text.primary">
          Choose payment method
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Container
          padding="0 32px 0 51px"
          onClick={() => handleChange("paymentButton")}
          isActive={selectedPaymentMode === "paymentButton"}
        >
          <Box
            display="flex"
            gap="14px"
            alignItems="center"
            height="100%"
            justifyContent="center"
          >
            <img
              src={device === "apple" ? applePay : googlePay}
              style={{ height: 30 }}
              alt="payment-button"
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography align="right" variant="subTitle2">
              {device === "apple" ? "Apple Pay" : "Google Pay"}
            </Typography>
          </Box>
        </Container>
      </Grid>
      <Grid item xs={12}>
        <Container
          onClick={() => handleChange("card")}
          isActive={selectedPaymentMode === "card"}
        >
          <Box
            display="flex"
            gap="14px"
            alignItems="center"
            height="100%"
            justifyContent="center"
          >
            {cardList.map((cardIcon) => (
              <img src={cardIcon} style={{ height: 20 }} alt="payment-button" />
            ))}
          </Box>
          <Box display="flex" alignItems="center">
            <Typography align="right" variant="subTitle2">
              Card
            </Typography>
          </Box>
        </Container>
      </Grid>

      {isRoomChargeEnabled && (
        <Grid item xs={12}>
          <Container
            padding="0 32px 0 55px"
            onClick={() => handleChange("roomCharge")}
            isActive={selectedPaymentMode === "roomCharge"}
          >
            <Box
              display="flex"
              gap="14px"
              alignItems="center"
              height="100%"
              justifyContent="center"
            >
              <img
                src="/assets/images/room-charge.svg"
                style={{ height: 30 }}
                alt="payment-button"
              />
            </Box>
            <Box display="flex" alignItems="center">
              <Typography align="right" variant="subTitle2">
                Charge to room
              </Typography>
            </Box>
          </Container>
        </Grid>
      )}
      {disabledCardBrand1 && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            align="center"
            sx={{
              color: "#818181",
              fontSize: "14px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {disabledCardBrands.map((disabledCardBrand, index) => (
              <>
                <img src={getCardBrandIcon(disabledCardBrand)}></img>
                <Box
                  component="span"
                  sx={{ textTransform: "capitalize" }}
                  p="0 3px"
                >
                  {" "}
                  {disabledCardBrand}
                  {disabledCardBrands.length !== index + 1 ? "," : ""}{" "}
                </Box>
              </>
            ))}
            {disabledCardBrand1 && (
              <>
                {disabledCardBrands.length > 0 ? (
                  <Box component="span" p="0 3px">
                    and
                  </Box>
                ) : (
                  ""
                )}
                <img src={getCardBrandIcon(disabledCardBrand1)} />{" "}
                <Box
                  component="span"
                  sx={{ textTransform: "capitalize" }}
                  p="0 3px"
                >
                  {disabledCardBrand1}
                </Box>
              </>
            )}
            {disabledCards.length > 1 ? "cards are " : "card is"} not supported
          </Typography>
        </Grid>
      )}
      {/* 
      <Grid item xs={12}>
        <Box
          borderRadius="100px"
          backgroundColor={({ palette }) => palette?.background?.paper}
          display="flex"
        >
          <Box
            {...tabButtonStyle}
            sx={getCardButtonStyle(selectedPaymentMode)}
            onClick={() => handleChange("card")}
          >
            <Box
              display="flex"
              gap="14px"
              alignItems="center"
              height="100%"
              justifyContent="center"
            >
              {cardList.map((cardIcon) => (
                <img
                  src={cardIcon}
                  style={{ height: 12 }}
                  alt="payment-button"
                />
              ))}
            </Box>
          </Box>
          <Box
            {...tabButtonStyle}
            sx={
              selectedPaymentMode === "paymentButton"
                ? activeTabButtonStyle
                : {}
            }
            onClick={() => handleChange("paymentButton")}
          >
            <Typography variant="subTitle5">
              <img
                src={device === "apple" ? applePay : googlePay}
                style={{ height: 20 }}
                alt="payment-button"
              />
            </Typography>
          </Box>
        </Box>
      </Grid>
      {disabledCardBrand1 && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            align="center"
            sx={{
              color: "#818181",
              fontSize: "14px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {disabledCardBrands.map((disabledCardBrand, index) => (
              <>
                <img src={getCardBrandIcon(disabledCardBrand)}></img>
                <Box
                  component="span"
                  sx={{ textTransform: "capitalize" }}
                  p="0 3px"
                >
                  {" "}
                  {disabledCardBrand}
                  {disabledCardBrands.length !== index + 1 ? "," : ""}{" "}
                </Box>
              </>
            ))}
            {disabledCardBrand1 && (
              <>
                {disabledCardBrands.length > 0 ? (
                  <Box component="span" p="0 3px">
                    and
                  </Box>
                ) : (
                  ""
                )}
                <img src={getCardBrandIcon(disabledCardBrand1)} />{" "}
                <Box
                  component="span"
                  sx={{ textTransform: "capitalize" }}
                  p="0 3px"
                >
                  {disabledCardBrand1}
                </Box>
              </>
            )}
            {disabledCards.length > 1 ? "cards are " : "card is"} not supported
          </Typography>
        </Grid>
      )} */}

      {/* {isRoomChargeEnabled && (
        <Grid item xs={12}>
          <div onClick={() => handleChange("roomCharge")}>
            <Button
              variant="rounded"
              fullWidth
              color={
                selectedPaymentMode === "roomCharge" ? "primary" : "disabled"
              }
              disabled={selectedPaymentMode !== "roomCharge"}
            >
              <Typography variant="h3" padding="5.5px 0px">
                Charge to room
              </Typography>
            </Button>
          </div>
        </Grid>
            )}*/}
    </Grid>
  );
}

PaymentMethodButton.propTypes = {};

export default PaymentMethodButton;

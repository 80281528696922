import PaymentGatewayService from "@root/services/PaymentGatewayService";
import { PaymentGatewayActions } from "./reducer";

const {
  setIsLoading,
  setPaymentGateways,
  setIsEnabled,
} = PaymentGatewayActions;

export const getConnectedPaymentGateways = (payload) => (dispatch) => {
  dispatch(setIsLoading(true));
  return PaymentGatewayService.getConnectedGateway(payload)
    .then((res) => {
      dispatch(setPaymentGateways(res));
      const noneEnabled = res.every((obj) => obj.isEnabled !== true);
      if (res.length === 0 || !noneEnabled) {
        dispatch(setIsEnabled(true));
      } else {
        dispatch(setPaymentGateways(res));
      }
    })
    .catch((error) => {
      console.error(`getConnectedPaymentGateways throws an ${error}`);
      throw error;
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};

import * as React from "react";

const StripeInput = React.forwardRef(function StripeInput(props, ref) {
  const { component: Component, options, ...other } = props;

  const [mountNode, setMountNode] = React.useState(null);

  React.useImperativeHandle(
    ref,
    () => ({
      focus: () => mountNode.focus(),
    }),
    [mountNode]
  );

  return (
    <Component
      onReady={setMountNode}
      options={{
        ...options,
        style: {
          base: {
            color: "#FFF",
            ":-webkit-autofill": {
              "-webkit-box-shadow": "0 0 0 100px #000 inset",
              "-webkit-text-fill-color": "#fff",
              color: "#fff",
            },
          },
        },
      }}
      {...other}
    />
  );
});

export default StripeInput;

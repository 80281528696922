import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import queryString from "query-string";

function FetchLogo({ variant, height, ...props }) {
  const { product } = useSelector((state) => state.ui);
  const fetchMyTipLogo = `/assets/images/fetchmytip-logo.svg`;
  const fetchPayLogo = "/assets/images/fetch-pay-header-logo.svg";
  let logo = product === "fetchPay" ? fetchPayLogo : fetchMyTipLogo;
  const { search } = window.location;
  const { logoUrl } = queryString.parse(search);
  const { detail } = useSelector((state) => state.outlet);

  if (Boolean(detail?.logoUrl)) {
    logo = detail.logoUrl;
  }

  if (logoUrl) {
    logo = logoUrl;
  }

  return (
    <img
      src={logo}
      style={{ height, maxWidth: "155px" }}
      alt="fetch-my-bill"
      {...props}
    />
  );
}

FetchLogo.propTypes = {
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(["black", "white", "dark"]),
    PropTypes.string,
  ]),
  height: PropTypes.string,
};

FetchLogo.defaultProps = {
  variant: "dark",
  // height: "24px",
};

export default FetchLogo;

import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Box, Avatar } from "@mui/material";
import FetchLogo from "@components/fetch-logo";
import { useSelector } from "react-redux";
import { alpha } from "@mui/material/styles";

function StaffOverview(props) {
  const { staffImageUrl, description, staffMessage } = useSelector(
    (state) => state.staff
  );
  return (
    <Grid container>
      <Grid item xs={12} mt="36px">
        <Box display="flex" justifyContent="center">
          <FetchLogo />
        </Box>
      </Grid>

      <Grid item xs={12} mt={staffImageUrl ? "75px" : "35px"} mb="52px">
        {staffImageUrl && (
          <Box display="flex" justifyContent="center">
            <Avatar src={staffImageUrl} sx={{ height: 162, width: 162 }} />
          </Box>
        )}
        <Typography
          variant={staffImageUrl ? "subHeading2" : "subHeading1"}
          align="center"
          color={(theme) => theme.palette.primary.contrastText}
        >
          {description}
        </Typography>
        <Typography
          variant={staffImageUrl ? "subText3" : "subText1"}
          align="center"
          color={(theme) => alpha(theme.palette.primary.contrastText, 0.7)}
        >
          {staffMessage}
        </Typography>
      </Grid>
    </Grid>
  );
}

StaffOverview.propTypes = {};

export default StaffOverview;

import React from "react";
import PropTypes from "prop-types";
import { useKeycloak } from "@react-keycloak/web";
import Loading from "@components/loading";

function Authenticate({ children }) {
  const { keycloak, initialized } = useKeycloak();

  if (initialized) {
    // TODO: Write a check for making sure that user belongs to FMT-feature
    //       For certain tenant
  }

  if (!initialized) {
    return <Loading message="Please wait while we authenticate you" />;
  }
  if (!keycloak?.authenticated) {
    keycloak.login();
  }

  return <div>{children}</div>;
}

Authenticate.propTypes = {};

export default Authenticate;

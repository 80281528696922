import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import PageHeader from "@components/page-header";
import FetchLogo from "@components/fetch-logo";
import StaffSelection from "@components/staff-selection";

function StaffSelectionPage(props) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader />
      </Grid>
      <Grid item xs={12}>
        <StaffSelection />
      </Grid>
    </Grid>
  );
}

StaffSelectionPage.propTypes = {};

export default StaffSelectionPage;

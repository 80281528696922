import { createSlice } from "@reduxjs/toolkit";

const items = createSlice({
  name: "payment-gateway",
  initialState: {
    isLoading: false,
    isFailed: false,
    paymentGateways: [],
    activePaymentGateways: [],
    noGatewayConnected: false,
    selectedPaymentGateway: "",
  },
  reducers: {
    setPaymentGateways: (state, { payload = [] }) => {
      return {
        ...state,
        paymentGateways: payload,
        activePaymentGateways: payload.filter((gateway) => gateway.isEnabled),
      };
    },
    setIsLoading: (state, { payload }) => {
      return {
        ...state,
        stage: 1,
        isLoading: payload,
      };
    },
    setIsFailed: (state, { payload }) => {
      return {
        ...state,
        isFailed: payload,
      };
    },
    setIsEnabled: (state, { payload }) => {
      return {
        ...state,
        noGatewayConnected: payload,
      };
    },
    setSelectedPaymentGateway: (state, { payload }) => ({
      ...state,
      selectedPaymentGateway: payload,
    }),
  },
});

export const action = items.actions;
export const PaymentGatewayActions = action;

export default items;

import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  formatToCurrency,
  formatToCurrencyInWholeNumber,
} from "@root/util/currency";
import { Box } from "@mui/material";

function Currency({ value, precision, symbolStyle, currencyStyle, variant }) {
  const { currencySymbol } = useSelector((state) => state.outlet);
  return (
    <>
      <Box component="span" sx={symbolStyle}>
        {currencySymbol}
      </Box>
      <Box component="span" sx={currencyStyle}>
        {variant === "wholeNumber"
          ? formatToCurrencyInWholeNumber(value)
          : formatToCurrency(value, precision)}
      </Box>
    </>
  );
}

Currency.propTypes = {
  precision: PropTypes.number,
  currencyStyle: PropTypes.object,
  symbolStyle: PropTypes.object,
};

Currency.defaultProps = {
  precision: 2,
  currencyStyle: {},
  symbolStyle: {},
};

export default Currency;

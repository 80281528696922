import KeyCloak from "keycloak-js";

const keyCloakConfig = {
  url: `${process.env.API_URL}/auth`,
  realm: `${process.env.REALM}`,
  clientId: `${process.env.KEYCLOAK_CLIENT_ID}`,
  redirectUri: `${process.env.PUBLIC_URL}`,
  //   onLoad: "login-required",
};

const keyCloak = new KeyCloak(keyCloakConfig);
export default keyCloak;

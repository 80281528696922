import { createSlice } from "@reduxjs/toolkit";

const removeEmptyItems = (menu) => {
  return menu;
};
const initialState = {
  isLoading: true,
  menu: null,
  error: null,
  selectedTab: 0,
  selectedItem: null,
};

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    startLoading: (state, { payload }) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    endLoading: (state, { payload }) => ({ ...state, isLoading: false }),
    updateMenu: (state, { payload }) => ({
      ...state,
      menu: removeEmptyItems(payload),
    }),
    updateSelectedItem: (state, { payload }) => ({
      ...state,
      selectedItem: payload,
    }),
    updateError: (state, { payload }) => ({ ...state, error: payload }),
    updateSelectedTab: (state, { payload }) => ({
      ...state,
      selectedTab: payload,
    }),
  },
});

export const actions = menu.actions;

export default menu;

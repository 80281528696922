import { format } from "mathjs";

export const formatToCurrency = (number, precision = 2) => {
  const [integer, float] = String(number).split(".");

  // if (!Boolean(float)) {
  //   return number;
  // }
  if (!isNaN(parseFloat(number).toFixed(2))) {
    // const rounded = Math.floor(number * 100) / 100;
    // Fix because sometime it returns a string in double quotes
    return parseFloat(
      String(format(number, { notation: "fixed", precision })).replaceAll(
        '"',
        " "
      )
    ).toFixed(precision);
  }
  return 0;
};

export const formatToCurrencyInWholeNumber = (number) => {
  if (!isNaN(number)) {
    if (Number.isInteger(number)) {
      return String(format(number, { notation: "fixed" })).replaceAll('"', " ");
    } else if (Number.isFinite(number)) {
      return parseFloat(
        String(format(number, { notation: "fixed" })).replaceAll('"', " ")
      ).toFixed(2);
    } else {
      return String(format(number, { notation: "fixed" })).replaceAll('"', " ");
    }
  }
  return 0;
};

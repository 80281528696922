import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function useTipTo(props) {
  const { displayName, ...staff } = useSelector((state) => state.staff);
  const { teamName, teamPaymentInfo, ...team } = useSelector(
    (state) => state.team
  );
  const { selectedStaff, specialMessage } = teamPaymentInfo;
  let payload = {};

  if (displayName) {
    payload = {
      staffId: staff.id,
    };
    return payload;
  }

  if (teamName) {
    payload = {
      teamId: team.id,
    };
    if (selectedStaff && props !== 2) {
      payload = { ...payload, selectedStaffIDs: [selectedStaff] };
    }
    if (specialMessage && props !== 2) {
      payload = { ...payload, comment: specialMessage };
    }
    return payload;
  }
}

useTipTo.propTypes = {};

export default useTipTo;

import { startOfMonth, endOfMonth, format as formatFn } from "date-fns";

const defaultDateFormat = "yyyy-MM-dd";

export const getMonthsStartAndEndDate = () => {
  const date = new Date();
  return {
    startDate: formatDate(startOfMonth(date)),
    endDate: formatDate(endOfMonth(date)),
  };
};

export const formatDate = (date, format = defaultDateFormat) => {
  return formatFn(date, format);
};

export const convertEpochToDate = (ms, format = defaultDateFormat) => {
  const intMs = parseInt(ms);
  return formatFn(new Date(intMs), format);
};

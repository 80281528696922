import * as React from "react";
import MuiSnackbar from "@mui/material/Snackbar";
import { useDispatch } from "react-redux";
import { action as UIActions } from "@store/ui/reducer";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const position = {
  vertical: "top",
  horizontal: "center",
};
export default function Snackbar({ text, severity }) {
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(UIActions.setSnackBarMessage(""));
  };

  return (
    <MuiSnackbar
      open
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={position}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          width: "100%",
          borderRadius: "3px",
          backgroundColor: ({ palette }) => {
            if (severity === "error") {
              return palette.error.main;
            }
            if (severity === "success") {
              return palette.success.main;
            }
          },
        }}
      >
        <IconButton>
          <Typography
            variant="subTitle6"
            fontWeight={500}
            fontSize="14px"
            component="span"
          >
            {text}
          </Typography>
        </IconButton>

        <IconButton>
          <CloseIcon onClick={handleClose} />
        </IconButton>
      </Box>
    </MuiSnackbar>
  );
}

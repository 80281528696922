import { css } from "@emotion/css";
const trackColor = "#626262";

export const root = css({
  "& .MuiSlider-root": {
    marginBottom: 0,
    marginTop: 30,
    "& .MuiSlider-markLabelActive": {
      "&:last-of-type": {
        color: "red",
      },
    },
  },
  "& .MuiSlider-rail": {
    height: 6,
    backgroundColor: trackColor,
    borderRadius: 100,
    opacity: 1,
  },
  "& .MuiSlider-track": {
    backgroundColor: trackColor,
  },
  "& .MuiSlider-mark": {
    backgroundColor: trackColor,
    color: trackColor,
  },
  "& .MuiSlider-markLabel": {
    top: -18,
    fontSize: 14,
    color: "#D7D7D7",
  },
  "& .MuiSlider-markLabelActive": {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1,
    color: "#D7D7D7",
  },
  "& .MuiSlider-thumb": {
    "& .MuiSlider-active": {
      boxShadow: "none",
    },
    backgroundColor: "#fff",
    // from 12px(default size) to 38px(design size) = increase 216 %
    height: "38px",
    width: "38px",
    top: "24px",
  },
});

export const eventName = (margin) => css`
  :after {
    position: absolute;
    content: "";
    height: 2px;
    bottom: ${margin}px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 20%;
    background: white;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
`;

const classes = { root };

export default classes;

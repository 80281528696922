import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import Currency from "@components/currency";
import { divide, multiply } from "mathjs";
import { formatToCurrency as formatNumber } from "@root/util/currency";

function ReviewOrder(props) {
  const { totalOrderAmount } = useSelector((state) => state.order);

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <Typography variant="h2" sx={{ fontWeight: 500, mb: 2 }}>
          Payment
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4">Total</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" align="right">
          <Currency value={totalOrderAmount} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}

ReviewOrder.propTypes = {};

export default ReviewOrder;

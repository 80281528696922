export function nameInitials(rawName) {
  const name = String(rawName);

  let [initial] = name;
  const splitted = name.split(" ");
  if (splitted.length > 1) {
    const last = splitted.pop();
    const [lastInitial] = last;
    initial += lastInitial;
  }

  return initial;
}

import React from "react";
import { GET_TEAM_DETAILS_QUERY } from "../query";
import GraphqlClient from "@root/services/GraphqlService";

export const GET_TEAM_DETAILS_VARIABLE = (teamId) => ({
  variables: {
    teamId,
  },
});

export const getTeamDetail = (teamId) => {
  return GraphqlClient.get(
    GET_TEAM_DETAILS_QUERY,
    GET_TEAM_DETAILS_VARIABLE(parseInt(teamId))
  )
    .then(({ errors, data }) => {
      if (errors) {
        console.log(errors);
        throw errors[0];
      }
      const dataResponse = data?.getTeamDetails ?? [];
      return dataResponse;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

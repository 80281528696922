import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";

// export const qrBoxStyle = css`
//   width: 100px;
//   height: 100px;

//   background: linear-gradient(to right, black 4px, transparent 4px) 0 0,
//     linear-gradient(to right, black 4px, transparent 4px) 0 100%,
//     linear-gradient(to left, black 4px, transparent 4px) 100% 0,
//     linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
//     linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
//     linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
//     linear-gradient(to top, black 4px, transparent 4px) 0 100%,
//     linear-gradient(to top, black 4px, transparent 4px) 100% 100%;

//   background-repeat: no-repeat;
//   background-size: 20px 20px;
// `;

// const classes = { qrBoxStyle };

export const QRBox = styled(Box)`
  padding: 2px;
  background: linear-gradient(to right, black 2px, transparent 2px) 0 0,
    linear-gradient(to right, black 2px, transparent 2px) 0 100%,
    linear-gradient(to left, black 2px, transparent 2px) 100% 0,
    linear-gradient(to left, black 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, black 2px, transparent 2px) 0 0,
    linear-gradient(to bottom, black 2px, transparent 2px) 100% 0,
    linear-gradient(to top, black 2px, transparent 2px) 0 100%,
    linear-gradient(to top, black 2px, transparent 2px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 20px 20px;
`;

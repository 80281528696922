import React from "react";
import PropTypes from "prop-types";

import { TextField as MuiTextField, FormHelperText } from "@mui/material";
import { styled } from "@mui/material/styles";

const TextField = styled(MuiTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    height: 64,
    color: theme.palette.primary.contrastText,
    backgroundColor: `${theme.palette.tertiary.main} !important`,
    borderRadius: "2rem",
  },
  "& .MuiSelect-select": {
    padding: "11px !important",
    color: `${theme.palette.primary.contrastText} !important`,
  },

  "&::before": {
    border: "none !important",
  },
  "&::after": {
    border: "none !important",
  },
  "& .MuiFormHelperText-root": {
    textAlign: "center",
  },
  "& input": {
    padding: "0 20px",
    fontWeight: 600,
    textAlign: "center",

    color: theme.palette.primary.contrastText,
  },
}));

function RoundedTextField(props) {
  return (
    <>
      <TextField {...props} />
    </>
  );
}

RoundedTextField.propTypes = {};

export default RoundedTextField;

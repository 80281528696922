import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function AfterPaymentRoute({ children }) {
  const { tipsAmount } = useSelector((state) => state.order);
  const redirectUri = window.localStorage.getItem("redirectUrl");
  if (!Boolean(tipsAmount)) {
    return <Navigate to={`${redirectUri}`} replace={true} />;
  }
  return children;
}

AfterPaymentRoute.propTypes = {};

export default AfterPaymentRoute;

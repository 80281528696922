import React from "react";
import { Grid, Box } from "@mui/material";
import Typography from "@components/typography";
import FloatingButton from "@components/floating-button";
import PageHeader from "@components/page-header";
const color = { color: "#5A5A5A" };

function QRNotAvailable(props) {
  return (
    <Grid container rowSpacing={5} alignItems="center">
      <Grid item xs={12}>
        <PageHeader />
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{ fontSize: 140, fontWeight: 700, ...color }}
          align="center"
        >
          !
        </Typography>
        <Typography variant="h1" align="center" fontWeight={500} gutterBottom>
          Sorry.
        </Typography>
        <Typography variant="h3" align="center" sx={color}>
          This QR is not available
        </Typography>
        <Typography variant="h3" align="center" sx={color}>
          Please check with the staff.
        </Typography>
      </Grid>
    </Grid>
  );
}

QRNotAvailable.propTypes = {};

export default QRNotAvailable;

import { gql } from "@apollo/client";

export const GET_CONFIGURATION_QUERY = gql`
  query configuration($type: ConfigurationType!, $entityId: ID!) {
    configuration(type: $type, entityId: $entityId) {
      entityId
      configuration
    }
  }
`;

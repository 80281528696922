import get from "lodash.get";

export const errorIfTouched = (form, name) => {
  const { errors, touched } = form;
  const isError = Boolean(get(errors, name));
  const isTouched = Boolean(get(touched, name));

  if (!isError || !isTouched) {
    return false;
  }

  if (isError && isTouched) {
    return get(errors, name);
  }

  return false;
};

import React from "react";
import PropTypes from "prop-types";
import { Typography as MuiTypography } from "@mui/material";

function Typography({ children, ...props }) {
  return <MuiTypography {...props}>{children}</MuiTypography>;
}

Typography.propTypes = {
  children: PropTypes.node,
};

export default Typography;

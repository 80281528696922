import { Box } from "@mui/material";
import { detectDevice } from "@utils/device";
export const buttons = {
  isCardActive: "/assets/images/button/active-credit-card.svg",
  isCardInActive: "/assets/images/button/inactive-credit-card.svg",
  isGooglePayInActive: "/assets/images/button/inactive-google-pay.svg",
  isGooglePayActive: "/assets/images/button/active-google-pay.svg",
  isApplePayInActive: "/assets/images/button/inactive-apple-pay.svg",
  isApplePayActive: "/assets/images/button/active-apple-pay.svg",
};

export const getCards = (state = "active") => [
  `/assets/images/button/${state}-card/visa.svg`,
  `/assets/images/button/${state}-card/master.svg`,
  `/assets/images/button/${state}-card/amex.svg`,
];

export const getCardBrandIcon = (type = "visa") =>
  `/assets/images/button/deactive-card/${type}.svg`;

export const availableCardBrandType = ["visa", "amex", "mastercard"];

export const activeTabButtonStyle = {
  borderRadius: "100px",
  backgroundColor: ({ palette }) => palette.primary.main,
  color: ({ palette }) => palette.primary.contrastText,
  "& .MuiTypography-root": {
    fontWeight: `700 !important`,
  },
};

export const tabButtonStyle = {
  padding: "24px 20px",
  flex: "1",
  textAlign: "center",
};

export const getDevice = () => {
  const detectedDevice = detectDevice();
  let device = "google";
  if (detectedDevice === "iOS") {
    device = "apple";
  }
  return device;
};

export const getCardButtonStyle = (selectedPaymentMode) => {
  if (selectedPaymentMode === "card") {
    return activeTabButtonStyle;
  }
  if (selectedPaymentMode === "roomCharge") {
    return {
      borderRadius: "100px",
      backgroundColor: ({ palette }) => palette.action.disabledBackground,
      color: ({ palette }) => palette.primary.contrastText,
      "& .MuiTypography-root": {
        fontWeight: `700 !important`,
      },
    };
  }
  return {};
};

export const Container = ({
  children,
  isActive,
  padding = "0 32px 0 32px",
  ...props
}) => {
  return (
    <Box
      height="80px"
      borderRadius="26px"
      backgroundColor={isActive ? "#424242" : "#242424"}
      border={isActive ? "1px solid #FFF" : null}
      display="flex"
      justifyContent="space-between"
      padding={padding}
      position="relative"
      sx={{
        "& .Mui-checked": {
          color: (theme) => `${theme.palette.primary.contrastText} !important`,
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

import { createSlice, createSelector } from "@reduxjs/toolkit";
import { schema, normalize } from "normalizr";

const staff = [
  {
    userId: "91a97ace-0329-4e4a-ade0-ebf6e986ec4d",
    id: 1,
    name: "Charlie Brown",
    staffImageUrl: "/assets/demo/anne-marry.svg",
    description:
      "“Thank you for your stay at Waldorf, it was a pleasure assisting you!” - Charlie",
  },
  {
    userId: 2,
    id: 2,
    name: "Bella Eve",
    staffImageUrl: "/assets/demo/bella-eve.svg",
  },
  {
    userId: 3,
    id: 3,
    name: "Charlie Brown",
    staffImageUrl: "/assets/demo/charlie-brown.svg",
  },
  {
    userId: 4,
    id: 4,
    name: "George Bell",
    staffImageUrl: "/assets/demo/george-bell.svg",
  },

  {
    userId: 5,
    id: 2,
    name: "Bella Eve",
    staffImageUrl: "/assets/demo/bella-eve.svg",
  },
];

const staffSchema = new schema.Entity("staff");
const normalizedStaff = normalize(staff, [staffSchema]);

function getCustomFields({ customFields }) {
  return customFields.filter(({ isEnable }) => Boolean(isEnable));
}

const details = {
  id: 1,
  teamName: "Car valet",
  tipsDefaultValue: [20, 30, 40],
  isTipsEnable: true,
  isStaffSelectionEnable: true,
  staff: normalizedStaff,
};

const initialState = {
  isLoading: true,
  error: null,
  apiResponse: {},
  selectedStaffMemberId: 1,
  teamPaymentInfo: {},
  tips: [],
  tipsTotal: 0,
  outlet: {},
  ...details,
};
const team = createSlice({
  name: "team",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    updateTipsInformation: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        error: null,
      };
    },
    startLoading: (state, { payload }) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    updateTeam: (state, { payload }) => ({
      ...state,
      ...payload,
      customFields: getCustomFields(payload),
      isGiftAidEnable:
        payload?.isGiftAidEnable && payload?.paymentMode === "donation",
      apiResponse: payload,
    }),
    updateTeamPaymentInfo: (state, { payload }) => ({
      ...state,
      teamPaymentInfo: payload,
    }),
    endLoading: (state, { payload }) => ({ ...state, isLoading: false }),

    updateError: (state, { payload }) => ({ ...state, error: payload }),
  },
});

export const actions = team.actions;

const teamSelector = (state) => state.team;
export const useGetSelectedStaffMember = createSelector(
  teamSelector,
  (team) => {
    const { staff, selectedStaffMemberId } = team;
    const { entities, result } = staff;
    if (result.includes(selectedStaffMemberId)) {
      return entities.staff[selectedStaffMemberId];
    }
    return {};
  }
);

export default team;

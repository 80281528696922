import React from "react";
import PropTypes from "prop-types";
import { Grid, Box, Typography } from "@mui/material";

import Payment from "@containers/payment";
import ReviewOrder from "@components/review-order";

import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";

import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { actions as CheckoutActions } from "@store/checkout/reducer";

import "./styles.css";
import { useEffect } from "react";

function CardPayment({ variant }) {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const goToMenu = () => {
    dispatch(CheckoutActions.updateContinueToPay(false));
    navigateTo(-1);
  };

  return (
    <Box p={3} pb="120px" position="relative">
      <Grid container rowSpacing={4}>
        <Grid item xs={12}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={goToMenu}
            aria-label="close"
          >
            <KeyboardBackspaceRoundedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <ReviewOrder />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3">Please enter card details</Typography>
        </Grid>
        <Grid item xs={12}>
          <Payment variant={variant} />
        </Grid>
      </Grid>
    </Box>
  );
}

CardPayment.propTypes = {
  variant: PropTypes.string.isRequired,
};

export default CardPayment;

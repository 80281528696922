import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Grid, Box, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";

function TeamMemberSelection({ onChange, values }) {
  const { staff } = useSelector((state) => state.team);

  const { selectedStaff } = values;

  const add = (userId) => {
    onChange({
      target: { name: "selectedStaff", value: userId },
    });
  };

  const remove = (userId) => {
    onChange({ target: { name: "selectedStaff", value: null } });
  };

  const onClick = (userId) => {
    if (selectedStaff === userId) {
      return remove(userId);
    }
    add(userId);
  };

  return (
    <Grid container rowGap={1.5}>
      <Grid xs={12}>
        <Typography variant="h4">Select Staff To Tip</Typography>
      </Grid>
      <Grid xs={12} item>
        <Box overflow="auto" className="staff-selection">
          <Box width={staff.length * 88}>
            <Grid container columnGap={1.5}>
              {staff.map((member) => (
                <Grid onClick={() => onClick(member.userId)} item>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    badgeContent={
                      <img
                        alt="selection-tick"
                        src="/assets/images/selection-tick.svg"
                      />
                    }
                    invisible={selectedStaff !== member.userId}
                  >
                    <Avatar
                      alt={member.displayName}
                      src={member.staffImageUrl}
                      sx={{ height: 78, width: 78 }}
                    />
                  </Badge>
                  <Typography variant="h6" align="center">
                    {member.displayName}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

TeamMemberSelection.propTypes = {};

export default TeamMemberSelection;

import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Avatar, Divider, Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useSelector } from "react-redux";

function NegativeFeedbackResponse({ classes }) {
  const { feedbackSetting } = useSelector((state) => state.feedback);
  const { feedbackResponsePersonProfileImageUrl } = feedbackSetting;
  const {
    feedbackResponsePersonName,
    feedbackResponsePersonRole,
    requestComplaintNumber,
  } = feedbackSetting ?? {};

  const isContactPersonNotAssigned =
    !feedbackResponsePersonProfileImageUrl && !feedbackResponsePersonName;

  if (isContactPersonNotAssigned && !requestComplaintNumber) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="center" spacing={3}>
        {!isContactPersonNotAssigned && (
          <>
            <Grid item xs={6}>
              <Divider style={{ width: "100%", marginTop: "30px" }} />
            </Grid>
            {feedbackResponsePersonProfileImageUrl && (
              <Grid item xs={8}>
                <Box display="flex" justifyContent="center">
                  <Avatar
                    src={feedbackResponsePersonProfileImageUrl}
                    style={{ height: "98px", width: "98px" }}
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography
                variant="h4"
                align="center"
                style={{ fontSize: "24px", color: "#121212" }}
              >
                {feedbackResponsePersonName}
              </Typography>
              {feedbackResponsePersonRole && (
                <Typography
                  variant="h5"
                  align="center"
                  style={{
                    fontSize: "18px",
                    color: "#818181",
                    marginBottom: "5px",
                  }}
                >
                  {feedbackResponsePersonRole}
                </Typography>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}

const Styles = () => ({
  textField: {
    "& .MuiInputBase-root": {
      borderRadius: "40px",
      height: "40px",
      backgroundColor: "#f2f2f2",
      color: "#8E8E8E",
      paddingLeft: "15px",
      textAlign: "left",
      "&:before": {
        border: "0px solid #000 !important",
      },
      "&:after": {
        border: "0px solid #000 !important",
      },
    },
  },
  button: {
    borderRadius: "13px",
    height: 25,
    marginRight: "8px",
    padding: "0px 16px !important",
    fontSize: "12px !important",
  },
});

NegativeFeedbackResponse.propTypes = {};

export default withStyles(Styles)(NegativeFeedbackResponse);

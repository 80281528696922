import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import Currency from "@components/currency";
import FloatingButton from "@components/floating-button";
import { useFormik } from "formik";
import { roomChargesSchema, TextField } from "./constants";
import { useRoomChargePayment } from "@hooks";
import { useSelector } from "react-redux";
import { alpha } from "@mui/material/styles";
import { useStripeIntent } from "@hooks/";

function RoomChargesForm({
  onPaymentSuccess,
  setLoading,
  totalAmount,
  beforePayment,
  verifyAllRequiredDataIsFilled,
}) {
  const { handleRoomChargeSubmit } = useRoomChargePayment({ onPaymentSuccess });
  const { currencySymbol } = useSelector((state) => state.outlet);
  const { handlePaymentIntent } = useStripeIntent(totalAmount, "tiger-tms");
  const { outletId } = useSelector((state) => state.outlet);
  const { serviceChargeAmount } = useSelector((state) => state.order);

  const onSubmit = async (values, name) => {
    console.log("sdjkasbkdbk", verifyAllRequiredDataIsFilled());
    if (!verifyAllRequiredDataIsFilled()) {
      return;
    }
    console.log("Room Charge :- Starting room charge payment ");
    console.log("Room Charge :- Starting room charge payment loader screen ");
    setLoading(true);
    try {
      console.log("Room Charge :- Starting before room charge payment checks ");
      await beforePayment();
      console.log(
        "Room Charge :- Completed before room charge payment checks "
      );
      console.log("Room Charge :- Starting to create intent");

      const roomChargeRequestPayload = {
        ...values,
      };

      const { paymentId } = await handlePaymentIntent(roomChargeRequestPayload);

      console.log("Room Charge :- Completed intent creation ");
      console.log("Room Charge :- Starting handleRoomChargeSubmit fn ");

      const confirmRequestPayload = {
        paymentId: paymentId,
        paymentGateway: "tiger-tms",
        outletId,
      };
      const response = await handleRoomChargeSubmit(confirmRequestPayload);

      console.log("Room Charge :- Completed handleRoomChargeSubmit fn ");

      if (response) {
        onPaymentSuccess(paymentId);
      }
      setLoading(false);
      console.log(
        "Room Charge :- Completed room charge payment loader screen "
      );
      console.log("Room Charge :- Completed room charge payment ");
    } catch (e) {
      console.log("Room Charge :- Error in room charge payment", e);
      console.log(e);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: { credential: "", roomNo: "" },
    validationSchema: roomChargesSchema,
    onSubmit: onSubmit,
  });

  const message = `A service fee of ${currencySymbol}${parseFloat(
    serviceChargeAmount
  ).toFixed(2)} has been added to your total`;

  return (
    <Grid container spacing={3} mt={2}>
      <Grid item xs={6}>
        <TextField
          placeholder="Last Name"
          onChange={formik.handleChange}
          value={formik.values?.credential}
          name="credential"
          error={Boolean(formik.errors?.credential)}
          helperText={
            Boolean(formik.errors?.credential)
              ? formik.errors?.credential
              : null
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          placeholder="Room Number"
          fullWidth
          onChange={formik.handleChange}
          value={formik.values?.roomNo}
          error={Boolean(formik.errors?.roomNo)}
          helperText={
            Boolean(formik.errors?.roomNo) ? formik.errors?.roomNo : null
          }
          name="roomNo"
        />
      </Grid>
      <Grid item xs={12}>
        {serviceChargeAmount && (
          <Typography
            variant="subText6"
            align="center"
            color={(theme) => alpha(theme.palette.primary.contrastText, 0.6)}
            mb={1}
          >
            {message}
          </Typography>
        )}
        <FloatingButton
          onClick={formik.handleSubmit}
          buttonProps={{ disabled: !Boolean(totalAmount) }}
          color="secondary"
        >
          Pay <Currency value={totalAmount} />
        </FloatingButton>
      </Grid>
    </Grid>
  );
}

RoomChargesForm.propTypes = {};

export default RoomChargesForm;

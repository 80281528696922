import { createSlice, original } from "@reduxjs/toolkit";
import { multiply, divide, add } from "mathjs";
import { formatToCurrency } from "@root/util/currency";

const initialState = {
  isLoading: true,
  selectedTipsOption: 0,
  order: null,
  items: [],
  summary: {
    total: 12,
    item: [],
  },
  serviceCharge: {},
  serviceChargeAmount: 0,
  shouldApplyServiceCharge: true,
  isAllowedToPay: false,
  itemIds: [],
  totalItemsAmount: 0,
  totalOrderAmount: 0,
  tipsAmount: 0,
  orderFor: {
    type: "",
    value: "",
    identifier: null,
  },
  error: null,
  comment: "",
  agree: false,
  paymentReference: "",
};

const calculateServiceCharges = (state, tipsAmount) => {
  let serviceChargeAmount = 0;
  try {
    const { serviceCharge, shouldApplyServiceCharge } = original(state);
    const { isServiceChargeEnabled } = serviceCharge;
    if (isServiceChargeEnabled && shouldApplyServiceCharge) {
      const {
        serviceChargeFeePercentage,
        serviceChargeFeeAmount,
      } = serviceCharge;
      if (Boolean(serviceChargeFeePercentage)) {
        const calculatedServiceCharge = divide(
          multiply(serviceChargeFeePercentage, tipsAmount),
          100
        );
        serviceChargeAmount += calculatedServiceCharge;
      }

      if (Boolean(serviceChargeFeeAmount)) {
        serviceChargeAmount += serviceChargeFeeAmount;
      }
    }
    return parseFloat(formatToCurrency(serviceChargeAmount));
  } catch (e) {
    return 0;
  }
};

const onTipAmountUpdate = (state, { payload }) => {
  const { tipsAmount } = payload;

  const stateToAdd = {
    tipsAmount: parseFloat(tipsAmount),
    selectedTipsOption: payload?.selectedTipsOption,
    serviceChargeAmount: 0,
    totalOrderAmount: tipsAmount,
  };

  if (!Boolean(Number(tipsAmount))) {
    return {
      ...state,
      ...stateToAdd,
      totalOrderAmount: 0,
      isAllowedToPay: false,
    };
  }

  const serviceChargeAmount = calculateServiceCharges(state, tipsAmount);

  if (Boolean(serviceChargeAmount)) {
    stateToAdd.serviceChargeAmount = parseFloat(
      formatToCurrency(serviceChargeAmount)
    );
  }

  const totalOrderAmount = add(serviceChargeAmount, tipsAmount);
  return {
    ...state,
    ...stateToAdd,
    totalOrderAmount: parseFloat(formatToCurrency(totalOrderAmount)),
    isAllowedToPay: Boolean(tipsAmount),
  };
};

const order = createSlice({
  name: "order",
  initialState,
  reducers: {
    reset: (state, { payload }) => {
      return { initialState, summary: { total: payload, items: state.items } };
    },
    startLoading: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    endLoading: (state) => ({ ...state, isLoading: false }),
    updateComment: (state, { payload }) => ({ ...state, comment: payload }),
    updateAgree: (state, { payload }) => ({ ...state, agree: payload }),
    updateOrder: (state, { payload }) => ({ ...state, order: payload }),
    addItem: (state, { payload }) => {
      const { items, itemIds } = state;
      const newItems = { ...items };
      const newIds = [...itemIds];
      newItems[payload.itemId] = { ...payload };
      newIds.push(payload.id);

      const totalPrice = Object.values(newItems).reduce(
        (acc, item) => acc + item.totalPrice,
        0
      );

      return {
        ...state,
        items: newItems,
        itemIds: newIds,
        totalItemsAmount: totalPrice,
        totalOrderAmount: totalPrice,
      };
    },
    emptyCart: (state, { payload }) => {
      return { ...state, items: [] };
    },
    updateServiceCharge: (state, { payload }) => {
      return {
        ...state,
        serviceChargeAmount: parseFloat(formatToCurrency(payload)),
      };
    },
    deleteItem: (state, { payload }) => {
      const { items, totalItemsAmount, totalOrderAmount } = state;
      const newItems = { ...items };
      if (!Boolean(newItems[payload])) {
        return state;
      }
      const { totalPrice } = newItems[payload];
      delete newItems[payload];
      return {
        ...state,
        items: newItems,
        totalItemsAmount: totalItemsAmount - totalPrice,
        totalOrderAmount: totalOrderAmount - totalPrice,
      };
    },
    updateTotalOrderAmount: (state, { payload }) => {},
    updateItemQuantity: (state, { payload }) => {
      const { items } = state;
      const { id, quantity } = payload;
      const newItem = items[id];
      newItem.quantity = quantity;
      return {
        state,
        items: { ...items, newItem },
      };
    },
    updateServiceCharge: (state, { payload }) => {
      return {
        ...state,
        serviceCharge: {
          ...payload,
        },
      };
    },
    updateTipsAmount: onTipAmountUpdate,
    updateOrderFor: (state, { payload }) => ({
      ...state,
      orderFor: payload,
    }),
    updatePaymentReference: (state, { payload }) => ({
      ...state,
      paymentReference: payload,
    }),
  },
});

export const actions = order.actions;
export const OrderActions = actions;

export default order;

import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Box, Avatar } from "@mui/material";
import FetchLogo from "@components/fetch-logo";
import { useSelector } from "react-redux";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const LINES_TO_SHOW = 2;
const useStyles = makeStyles({
  container: {
    maxWidth: 500,
  },
  multiLineEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": LINES_TO_SHOW,
    "-webkit-box-orient": "vertical",
    "&:hover": {
      textOverflow: "clip",
      whiteSpace: "normal",
      wordBreak: "break-all",
      "-webkit-line-clamp": "inherit",
    },
  },
});
function TeamOverview(props) {
  const { teamName, teamMessage, description, teamImageUrl } = useSelector(
    (state) => state.team
  );
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} mt="36px">
        <Box display="flex" justifyContent="center">
          <FetchLogo />
        </Box>
        {teamImageUrl && (
          <Box display="flex" justifyContent="center" mt={1}>
            <img src={teamImageUrl} style={{ maxHeight: "160px" }} />
            {/* <img src="/assets/images/team.svg" /> */}
          </Box>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        mt={teamImageUrl ? "35px" : "35px"}
        mb={teamImageUrl ? "35px" : "52px"}
      >
        <Typography
          variant={teamImageUrl ? "subHeading2" : "subHeading1"}
          align="center"
          color={(theme) => theme.palette.primary.contrastText}
        >
          {description}
        </Typography>

        <Typography
          variant={teamImageUrl ? "subText3" : "subText1"}
          align="center"
          color={(theme) => alpha(theme.palette.primary.contrastText, 0.7)}
        >
          {teamMessage}
        </Typography>
      </Grid>
    </Grid>
  );
}

TeamOverview.propTypes = {};

export default TeamOverview;

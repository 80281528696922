import React from "react";
import PropTypes from "prop-types";
import { Grid, Avatar, Typography, Box, IconButton } from "@mui/material";
import { RoundedButton } from "@components/elements";
import Timeline from "@components/timeline";
import { If, Then, Else } from "react-if";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import { nameInitials } from "@root/util/ui";
import WestIcon from "@mui/icons-material/West";

import { getTimelineNodes } from "./constant";
import { useNavigate } from "react-router-dom";
import Currency from "@components/currency";
import { tipPrecision } from "@components/tips-form/constants";

function TipHistoryCard(props) {
  const { name, totalTipsAmount, tips, imageUrl, variant } = props;
  const { width, height } = useWindowSize();

  const navigate = useNavigate();

  const noTips = !Boolean(tips.length);

  return (
    <Grid container alignContent="space-between" height={`${height}px`}>
      <Box position="fixed" top="0" width={width}>
        <Confetti
          width={width}
          height={height}
          colors={["#DEDEDE"]}
          numberOfPieces="80"
        />
      </Box>
      <Grid item xs={12} pt="64px">
        <Grid container justifyContent="center">
          <Grid item xs={11}>
            <Typography color="#000">
              <IconButton onClick={() => navigate(-1)}>
                <WestIcon />
              </IconButton>
            </Typography>
          </Grid>
          <Grid item xs={3.1}>
            <If condition={variant === "team" && imageUrl}>
              <Then>
                <Avatar
                  src={imageUrl}
                  alt={name}
                  sx={{ width: 95, height: 95 }}
                >
                  <Typography fontSize="36px" textTransform="uppercase">
                    {nameInitials(name)}
                  </Typography>
                </Avatar>
              </Then>
            </If>

            <If condition={variant === "staff"}>
              <Then>
                <Avatar
                  src={imageUrl}
                  alt={name}
                  sx={{ width: 95, height: 95 }}
                >
                  <Typography fontSize="36px" textTransform="uppercase">
                    {nameInitials(name)}
                  </Typography>
                </Avatar>
              </Then>
            </If>
          </Grid>
          <Grid xs={12} item>
            <Typography
              variant="h2"
              fontWeight="500"
              fontSize={variant === "team" && !imageUrl ? "30px" : "20px"}
              align="center"
              gutterBottom
            >
              {name}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h1"
              fontSize="80px"
              fontWeight="700"
              align="center"
              mb="0px"
              gutterBottom
            >
              <Currency value={totalTipsAmount} precision={tipPrecision} />
            </Typography>
            <Typography color="#BBBBBB" variant="h4" align="center">
              Total Tips Received This Month
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          position="relative"
          borderRadius="50px 50px 0 0 "
          backgroundColor="#fff"
          boxShadow="0px -3px 19px -2px rgba(0, 0, 0, 0.1)"
          mt="53px"
          p="32px"
          pb="19.5px"
        >
          <Typography variant="h3">Last 5 Tips History</Typography>
          {noTips ? (
            <>
              <Typography color="#BBBBBB" variant="h4">
                You currently have no tips.
              </Typography>
              <Typography pt={2}>
                <img src="/assets/images/zero-tip.svg" width="100%" />
              </Typography>
            </>
          ) : (
            <Box position="relative" minHeigh="200px">
              <Timeline nodes={getTimelineNodes(tips)} />
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

TipHistoryCard.propTypes = {
  variant: PropTypes.string.isRequired,
};

TipHistoryCard.defaultProps = {
  variant: "staff",
};

export default TipHistoryCard;

import { getTips, getTipsTotal } from "@graphql/tips/hooks";
import { getStaffDetail } from "@graphql/staff/hooks";
import { actions as StaffActions } from "./reducer";
import { actions as TeamActions } from "@store/team/reducer";
import { actions as OutletActions } from "@store/outlet/reducer";
import { OrderActions } from "@store/order/reducer";

export const getStaff = (input) => (dispatch) => {
  dispatch(StaffActions.startLoading());
  return getStaffDetail(input)
    .then((res) => {
      const {
        team: { outlet },
        isActive,
        isQREnable,
      } = res;
      if (!isActive || !isQREnable) {
        throw "Staff is disabled";
      }
      dispatch(StaffActions.updateStaffDetails(res));
      dispatch(OutletActions.updateOutlet(outlet));
      dispatch(OrderActions.updateServiceCharge(outlet?.serviceCharge));
      dispatch(
        TeamActions.updateTeam({
          ...res.team,
          selectedStaffMemberId: res.userId,
        })
      );
      return res;
    })
    .catch((error) => {
      dispatch(StaffActions.updateError(error));
      throw error;
    })
    .finally(() => dispatch(StaffActions.endLoading()));
};

export const getLastFiveTips = (input) => (dispatch) => {
  dispatch(StaffActions.startLoading());
  return getTips({ ...input, page: { pageNumber: 1, perPage: 5 } })
    .then((res) => {
      dispatch(StaffActions.updateTips(res));
    })
    .catch((error) => {
      dispatch(StaffActions.updateError(error));
      throw error;
    })
    .finally(() => dispatch(StaffActions.endLoading()));
};

export const getTipsInformation = (input) => async (dispatch) => {
  const { staffId, startDate, endDate, outletId } = input;
  try {
    const lastFiveTips = await getTips({
      staffId,
      outletId,
      startDate: "1992-01-20",
      endDate: "2092-01-31",
      page: { pageNumber: 1, perPage: 5 },
    });
    const tipsTotal = await getTipsTotal({
      staffId,
      startDate,
      endDate,
    });
    dispatch(
      StaffActions.updateTipsInformation({ tips: lastFiveTips, tipsTotal })
    );
  } catch (e) {
    dispatch(StaffActions.updateError(e));
    throw e;
  } finally {
    dispatch(StaffActions.endLoading());
  }
};

import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  InputBase,
  Box,
} from "@mui/material";
import SliderInput from "@components/slider-input";
import { useSelector, useDispatch } from "react-redux";
import { feedbackActions } from "@root/store/feedback/reducer";
import { updateFeedback, fetchFeedbackSetting } from "@root/store/feedback/api";
import { useEffect } from "react";
import queryString from "query-string";
import { getTeam } from "@store/team/api";

import {
  useParams,
  useNavigate,
  generatePath,
  useLocation,
} from "react-router-dom";
import CustomerDetail from "@components/customer-detail-form";
import { getTypeOfFeedback } from "@root/util/feedback";
import {
  transformRatingPayload,
  getValidCustomerDetailFromValues,
  PrimaryButton,
} from "./constant";
import Loading from "@components/loading";

const { updateFeedbackComment, updateRating } = feedbackActions;

function Feedback({ isLandingPage, ...props }) {
  const dispatch = useDispatch();

  const { tableId, paymentId, amount } = useParams();
  const { search } = window.location;
  const { id, teamId } = queryString.parse(search);

  const { pathname: path } = useLocation();
  const navigate = useNavigate();

  const {
    feedbackOptions,
    comment,
    feedbackSetting,
    averageRating,
    isLoading,
  } = useSelector((state) => state.feedback);

  const { outlet = {} } = useSelector((state) => state.team);
  const { id: outletId } = outlet;

  const { customerContactInfoSettings, isCustomerContactInfoEnabled } =
    feedbackSetting ?? {};

  const onChange = (value, key) => {
    const scaledValue = value / 10;
    dispatch(updateRating({ rating: scaledValue, key }));
  };

  const redirectWithPaymentId = ({ id: feedbackId }) => {
    const pathName = generatePath(path, {
      tableId,
      paymentId,
      amount,
      feedbackId,
    });

    return navigate(`/feedback-response/${feedbackId}`);
  };

  const onSave = ({ customerDetailFromValues = {} }) => {
    const ratingPayload = transformRatingPayload(feedbackOptions);

    const validCustomerDetailFromValues = getValidCustomerDetailFromValues(
      customerDetailFromValues
    );

    dispatch(
      updateFeedback({
        ...ratingPayload,
        feedback: comment,
        isPosTableId: !Boolean(validCustomerDetailFromValues?.tableId),
        tipPaymentId: Number(id),
        teamId: Number(teamId),
        outletId,
        ...validCustomerDetailFromValues,
      })
    ).then(redirectWithPaymentId);
  };

  useEffect(() => {
    // if (!Object.keys(feedbackSetting).length) {
    dispatch(getTeam(teamId)).then((res) => {
      const { outlet } = res;
      const { id: outletId } = outlet;
      dispatch(fetchFeedbackSetting({ outletId }));
    });
  }, []);

  useEffect(() => {
    document.body.style = "background: #f2f2f2;";
  }, []);

  if (isLoading) {
    return <Loading height="300px" />;
  }

  const noFeedbackNeeded =
    !feedbackOptions.length && !feedbackSetting.additionalFeedbackEnabled;

  if (noFeedbackNeeded) {
    return null;
  }

  if (!feedbackSetting?.isFeedbackEnabled) {
    return null;
  }

  const typeOfFeedback = getTypeOfFeedback({
    ...feedbackSetting,
    averageRating,
    feedbackOptions,
  });

  return (
    <Box padding="4px">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card elevation={0}>
            <CardContent style={{ paddingTop: "29px", paddingBottom: "11px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ marginBottom: "14px" }}>
                  <Typography
                    variant="subHeading2"
                    style={{ fontSize: 22, marginBottom: "6px" }}
                    align="center"
                  >
                    {feedbackSetting?.feedbackHeader ?? "How Did We Get On?"}
                  </Typography>
                  <Typography
                    variant="subText4"
                    color="secondary"
                    align="center"
                    style={{ fontSize: 16 }}
                  >
                    {feedbackSetting?.feedbackSubHeader}
                  </Typography>
                </Grid>
                {feedbackOptions.map((item) => (
                  <Grid
                    item
                    xs={12}
                    style={{ marginBottom: "7px" }}
                    key={item.title}
                  >
                    <SliderInput
                      title={item.title}
                      onChangeCommitted={(e, value) =>
                        onChange(value, item.key)
                      }
                      name={item.key}
                      defaultValue={item.rating}
                    />
                  </Grid>
                ))}
                {feedbackSetting.additionalFeedbackEnabled && (
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: "19px", marginBottom: "6px" }}
                  >
                    <Typography
                      variant="subHeading4"
                      sx={{
                        fontSize: "16px!important",
                        letterSpacing: "0.16px",
                      }}
                    >
                      Comments
                    </Typography>

                    <InputBase
                      id="standard-multiline-static"
                      variant="standard"
                      placeholder="Write your comment here..."
                      fullWidth
                      multiline
                      minRows={8}
                      onChange={(e) =>
                        dispatch(updateFeedbackComment(e.target.value))
                      }
                      style={{
                        backgroundColor: "#ffffff1f",
                        color: "#fff",
                        borderRadius: "20px",
                        padding: "20px",
                        marginTop: "10px",
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "32px" }}>
          {Boolean(isCustomerContactInfoEnabled) &&
          typeOfFeedback === "negative" ? (
            <CustomerDetail
              customerContactInfoSettings={customerContactInfoSettings}
              sendContactDetail={onSave}
            />
          ) : (
            <PrimaryButton onClick={onSave}>Submit Feedback</PrimaryButton>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

Feedback.propTypes = {};

export default Feedback;

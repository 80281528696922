import React, { useState } from "react";
import PropTypes from "prop-types";
import Adyen from "@containers/adyen";
import Stripe from "@containers/stripe";
import RoomChargesForm from "@components/room-charges-form";
import { useDispatch, useSelector } from "react-redux";
import { action as UiActions } from "@store/ui/reducer";
import { Box, Grid } from "@mui/material";
import Loading from "@components/loading";
import { useNavigate, Navigate } from "react-router-dom";
import { placeOrder } from "@store/checkout/api";
import CheckoutService from "@services/CheckoutService";
// import { orderStatus } from "@store/order-status/api";
import { loaderStyle, getTotalPayment, getTotalAmount } from "./constant";
import DemoCardForm from "@components/demo-card-form";
import { createTipRecord, addGiftAidRecord } from "@store/tip/api";
import { useEffect } from "react";
import useTipTo from "@hooks/use-tip-to";
import useBeforePaymentValidations from "@hooks/use-before-payment-validations";
import useConfirmPayment from "@hooks/use-confirm-payment";
import { getAllowedCards } from "@store/payment/api";
import PaymentFailed from "@components/payment-failed";
import NoPaymentGatewayModal from "@components/no-gateway-connected";
import PaymentMethodButton from "@components/payment-method-button";

function PaymentHandler({ variant, ...props }) {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const navigateTo = useNavigate();

  const { cardTypeCheck } = useBeforePaymentValidations();
  const { confirmPaymentStatus } = useConfirmPayment();

  // All redux selectors
  const { orderTotals = {} } = useSelector((state) => state.checkout);
  const outlet = useSelector((state) => state.outlet);
  const { cardType } = useSelector((state) => state.payment);
  const { totalOrderAmount, tipsAmount } = useSelector((state) => state.order);
  const {
    error: tipRecordError,
    isLoading: creatingTip,
    id: tipId,
    isGiftAidOptedIn,
    giftAidData,
  } = useSelector((state) => state.tip);
  const { noGatewayConnected } = useSelector((state) => state.paymentGateway);

  const tipToInformation = useTipTo();

  const { isGiftAidEnable } = useSelector((state) => state.team);

  // All de-structured variables
  const { outletId } = outlet;

  // This will be used by fetch apis
  const totalAmount = getTotalAmount({ totalOrderAmount, totalPrice: 0 });

  const navigateToSuccessScreen = () => {
    setLoading(false);
    navigateTo("/payment-success");
  };
  const confirmationFailedError = () => {
    setLoading(false);
    notifyError("Unable to make payment, please try again");
  };

  const startPaymentConfirmation = (paymentId) => {
    return new Promise((resolve, reject) => {
      const onSuccess = (params) => {
        navigateToSuccessScreen(params);
        return resolve(params);
      };
      const onFailed = (params) => {
        confirmationFailedError(params);
        return reject(params);
      };
      try {
        console.log("CardPayment :- Calling confirmPaymentStatus ");
        confirmPaymentStatus({
          paymentId,
          successCb: onSuccess,
          fallBackCb: onFailed,
        });
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  };

  const onPaymentSuccess = (paymentId) => {
    // This is to avoid callback loop issue
    console.log("CardPayment :- Starting onPaymentSuccess fn ");
    console.log("CardPayment :- Starting loader again fn ");
    setTimeout(() => setLoading((state) => true));
    return startPaymentConfirmation(paymentId)
      .then(console.log)
      .catch((e) => {
        console.log("=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>", e);
        throw e;
      });
  };

  const notifyError = (message) => {
    dispatch(UiActions.setSnackBarError(message));
  };

  const addGiftAidData = () => {
    if (!isGiftAidEnable || !isGiftAidOptedIn || tipsAmount < 20) {
      return false;
    }

    return dispatch(
      addGiftAidRecord({
        tipPaymentId: tipId,
        amount: tipsAmount,
        isGiftAidOptedIn,
        giftAidData,
      })
    );
  };

  const beforePayment = async () => {
    // TODO: Move this logic to hook useBeforePaymentValidations
    try {
      addGiftAidData();
      cardTypeCheck();
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const saveTipRecord = () => {
    return dispatch(
      createTipRecord({
        outletId,
        ...tipToInformation,
      })
    );
  };

  useEffect(() => {
    saveTipRecord();
    dispatch(getAllowedCards({ referenceId: outletId }));
  }, []);

  if (creatingTip) {
    return <Loading message="Loading..." />;
  }

  if (tipRecordError) {
    return <Navigate to="/error" />;
  }

  return (
    <>
      {React.cloneElement(props.children, {
        totalAmount: totalOrderAmount,
        notifyError,
        setLoading,
        onPaymentSuccess,
        createTipRecord,
        tipId,
        beforePayment,
        ...props,
      })}

      {isLoading && (
        <Loading
          message="Confirming Payment...Hang tight! "
          style={loaderStyle}
        />
      )}
      <PaymentFailed />

      {!noGatewayConnected && <NoPaymentGatewayModal />}
    </>
  );
}

PaymentHandler.propTypes = {
  variant: PropTypes.oneOf(["room-charge", "adyen"]).isRequired,
};

export default PaymentHandler;

// ==============================|| OVERRIDES - Link ||============================== //

export default function MuiLink(theme) {
  return {
    MuiLink: {
      variants: [
        {
          props: { variant: "link" },
          style: {
            borderRadius: "100px",
            padding: "16px",
            fontWeight: 600,
            fontSize: 16,
            textTransform: "unset",
            width: "100%",
            background: theme.palette.secondary.main,
            color: theme.palette.primary.contrastText,
            textAlign: "center",
            textDecoration: "none",
          },
        },
      ],
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: "unset",
          fontWeight: 700,
          fontsize: "0.825rem",
          borderRadius: "8px",
        }),
      },
    },
  };
}

// ==============================|| OVERRIDES - CARD ||============================== //
import { alpha } from "@mui/material/styles";
export default function MuiAlert(theme) {
  return {
    MuiAlert: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.contrastText,
          textAlign: "center",
          fontSize: "14px",
          backgroundImage: "none",
        }),
      },
    },
  };
}

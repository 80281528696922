import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Card,
  Box,
  CircularProgress,
} from "@mui/material";
import Padding from "@components/padding";
import FloatingButton from "@components/floating-button";
import { useDispatch, useSelector } from "react-redux";
import { action as UIActions } from "@store/ui/reducer";
import { useFormik } from "formik";
import * as yup from "yup";
import { sendTipReceipt } from "@graphql/tips/send-receipt";
import { useState } from "react";
import { getTipDetail } from "@graphql/tips/get-tip-detail";
import { saveAs } from "file-saver";
import Loading from "@components/loading";
import { lighten } from "@mui/material/styles";
import queryString from "query-string";
import FooterLogo from "@components/footer-logo";

const validationSchema = yup
  .object()
  .shape({ email: yup.string().email().required().label("Email") });

function EmailForm(props) {
  const { search } = window.location;
  const [isDownloading, setDownloading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { id } = queryString.parse(search);
  const emailSubmitted = window.localStorage.getItem("emailSubmitted");

  const dispatch = useDispatch();
  // const { id } = useSelector((state) => state.tip);

  const send = (value) => {
    setSubmitting(true);
    sendTipReceipt({
      tipPaymentId: Number(id),
      ...value,
    })
      .then(() => {
        setSubmitted(true);
        window.localStorage.setItem("emailSubmitted", "true");
      })
      .catch(() => {
        setSubmitting(false);
        dispatch(
          UIActions.setSnackBarError("Unable to send receipt. Try again later")
        );
      });
  };

  const form = useFormik({
    initialValues: { email: "" },
    validationSchema: validationSchema,
    onSubmit: send,
  });

  const onDownload = () => {
    setSubmitting(true);
    setDownloading(true);
    getTipDetail({ tipPaymentId: id })
      .then((res) => {
        saveAs(res?.tipReceiptURL, "receipt.pdf");
      })
      .catch(() => {
        dispatch(
          UIActions.setSnackBarError(
            "Unable to download receipt. Try again later"
          )
        );
      })
      .finally(() => {
        setDownloading(false);
        setSubmitting(false);
      });
  };

  const { values, errors } = form;

  const isSubmitted = submitted || Boolean(emailSubmitted);

  if (submitting || emailSubmitted) {
    return (
      <Grid container rowGap={2}>
        <Grid item xs={12}>
          <Card
            sx={{
              height: 248,
              mt: 3,
              backgroundColor: (theme) =>
                lighten(theme.palette.primary.main, 0.183),
            }}
          >
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="210px"
              >
                {isSubmitted && !isDownloading ? (
                  <>
                    <Typography variant="subHeading4" align="center">
                      Thank you
                    </Typography>
                    <Typography variant="subTitle3" align="center">
                      Your receipt has been sent
                    </Typography>
                  </>
                ) : (
                  <CircularProgress
                    color="secondary"
                    sx={{
                      color: (theme) => theme.palette.primary.contrastText,
                    }}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {isSubmitted && !isDownloading && (
          <Grid item xs={12}>
            <FloatingButton
              color="secondary"
              sx={{ width: "100%", padding: "11px" }}
              onClick={onDownload}
            >
              Download Receipt
            </FloatingButton>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <FooterLogo />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container rowGap={2}>
        <Grid item xs={12}>
          <Typography variant="subTitle3" fontWeight="500">
            Enter your email for your receipt.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="Email Address"
            name="email"
            variant="standard"
            onChange={form.handleChange}
            value={values.email}
            error={Boolean(errors.email)}
            helperText={errors?.email}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} pt={2}>
          <FloatingButton
            sx={{
              width: "100%",
              padding: "11px",
              backgroundColor: (theme) =>
                `${theme.palette.primary.contrastText}`,
              color: (theme) => theme.palette.primary.main,
              "&:hover": {
                backgroundColor: (theme) =>
                  `${theme.palette.primary.contrastText}`,
              },
            }}
            onClick={form.handleSubmit}
            buttonProps={{
              disabled: !Boolean(values?.email) || Boolean(errors.email),
            }}
          >
            Get Receipt
          </FloatingButton>
        </Grid>
        <Grid item xs={12}>
          <FloatingButton
            color="secondary"
            sx={{ width: "100%", padding: "11px" }}
            onClick={onDownload}
          >
            Download Receipt
          </FloatingButton>
        </Grid>
      </Grid>
      {/* {isDownloading && <Loading message="Downloading receipt" />} */}
    </>
  );
}

EmailForm.propTypes = {};

export default EmailForm;

import { gql } from "@apollo/client";

export const GET_TIP_DETAIL_QUERY = gql`
  query getTipPaymentById($tipPaymentId: ID!) {
    getTipPaymentById(tipPaymentId: $tipPaymentId) {
      id
      tipAmount
      payeeName
      gatewayChargeId
      stripChargeId
      chargeValue
      vatValue
      transferValue
      cardType
      gatewayTransactionId
      tipReceiptURL
    }
  }
`;

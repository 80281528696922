import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  error: null,
  customFields: {},
  giftAidData: {},
  apiResponse: {},
  giftAidError: false,
  isGiftAidOptedIn: false,
  customFieldsError: false,
};

const tip = createSlice({
  name: "tip",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    startLoading: (state, { payload }) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    endLoading: (state, { payload }) => ({ ...state, isLoading: false }),
    setTip: (state, { payload }) => ({ ...state, ...payload }),
    updateError: (state, { payload }) => ({ ...state, error: payload }),
    updateCustomFields: (state, { payload }) => ({
      ...state,
      customFields: payload,
    }),
    updateCustomFieldsError: (state, { payload }) => ({
      ...state,
      customFieldsError: payload,
    }),
    updateGiftAidData: (state, { payload }) => ({
      ...state,
      giftAidData: payload,
    }),
    toggleApplicationOfGiftAid: (state, { payload }) => ({
      ...state,
      isGiftAidOptedIn: !state?.isGiftAidOptedIn,
    }),
  },
});

export const actions = tip.actions;
export const TipActions = actions;

export default tip;

import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactGA from "react-ga4";

import { store } from "./store";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./themes";

window.store = store;
const root = ReactDOM.createRoot(document.getElementById("fetch-my-tips"));

// Sentry.init({
//   dsn:
//     "https://342c660fee5e40b7b51fe49d875eb03a@o512958.ingest.sentry.io/4504292519903232",
//   integrations: [new BrowserTracing()],
//   environment: process.env.NODE_ENV,

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_KEY);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
// import { getAdyenPaymentSession } from "@store/payment/api";
import { useDispatch, useSelector } from "react-redux";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import "./style.css";
import { Typography, Grid, Box } from "@mui/material";
import Loading from "@components/loading";
import DemoCardForm from "@components/demo-card-form";
import FloatingButton from "@components/floating-button";
import Currency from "@components/currency";

function Adyen({ totalPayment, totalAmount, notifyError, ...props }) {
  const { onPaymentFail, onPaymentSuccess, setLoading } = props;

  const dispatch = useDispatch();
  const paymentContainer = useRef(null);

  const { adyenConfiguration = {} } = useSelector((state) => state.payment);
  const outlet = useSelector((state) => state.outlet);
  const { outletId } = outlet;

  // useEffect(() => {
  //   dispatch(
  //     getAdyenPaymentSession({
  //       outletId: outletId,
  //       value: totalPayment,
  //     })
  //   );
  // }, []);

  const onPaymentComplete = (...args) => {
    const { resultCode } = args[0];
    if (resultCode === "Refused") {
      return onPaymentFail(...args);
    }
    return onPaymentSuccess(...args);
  };

  const getAdyenCheckout = async () => {
    const { id, sessionData, environment } = adyenConfiguration;
    const configuration = {
      environment, // Change to 'live' for the live environment.
      clientKey: `${process.env.ADYEN_CLIENT_KEY}`, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
      analytics: {
        enabled: false, // Set to false to not send analytics data to Adyen.
      },
      session: {
        id, // Unique identifier for the payment session.
        sessionData, // The payment session data.
      },
      beforeSubmit: (data, component, { resolve }) => {
        setLoading(true);
        resolve(data);
      },
      onPaymentCompleted: onPaymentComplete,
      // onError: onPaymentFail,
      // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
      // For example, this is 3D Secure configuration for cards:
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          billingAddressRequired: false,
        },
      },
    };

    if (Object.keys(adyenConfiguration).length) {
      const checkout = await AdyenCheckout(configuration);
      if (paymentContainer.current) {
        checkout.create("dropin").mount(paymentContainer.current);
      }
    }
  };

  // useEffect(async () => getAdyenCheckout(), [adyenConfiguration]);
  return (
    <Grid container rowGap={3}>
      <Grid item xs={12}>
        <Typography variant="h3">Please enter card details</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box position="relative">
          <Box
            position="absolute"
            height="200px"
            width="100vw"
            left="-24px"
            right="0px"
          >
            <Loading height="100%" />
          </Box>
          <Box sx={{ zIndex: 9999, position: "relative", background: "#fff" }}>
            <DemoCardForm onNameChange={() => {}} />
          </Box>
        </Box>
      </Grid>
      <FloatingButton position="fixed" onClick={onPaymentSuccess}>
        Pay - <Currency value={totalAmount} />
      </FloatingButton>
    </Grid>
  );
}

Adyen.propTypes = {};

export default Adyen;

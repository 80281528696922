export const getTypeOfFeedback = ({
  averageRating,
  feedbackSharingRatingThreshold,
  negativeFeedbackAverageThreshold,
  negativeFeedbackThresholds,
  ...params
}) => {
  console.log({
    averageRating,
    feedbackSharingRatingThreshold,
    negativeFeedbackAverageThreshold,
    negativeFeedbackThresholds,
    ...params,
  });

  const { food, hygiene, service, recommendation } = params;
  const isRatingEnabled = food || hygiene || service || recommendation;
  const { feedbackOptions } = params;
  const ratings = feedbackOptions?.reduce(
    (acc, val) => ({ ...acc, [val.name]: val.rating }),
    {}
  );

  let isNegativeRating = false;

  if (ratings?.foodRating <= negativeFeedbackThresholds?.food) {
    isNegativeRating = true;
  }

  if (ratings?.serviceRating <= negativeFeedbackThresholds?.service) {
    isNegativeRating = true;
  }

  if (ratings?.hygieneRating <= negativeFeedbackThresholds?.hygiene) {
    isNegativeRating = true;
  }
  if (ratings?.recommendRating <= negativeFeedbackThresholds?.recommendation) {
    isNegativeRating = true;
  }

  if (isNegativeRating) {
    return "negative";
  }

  if (averageRating >= feedbackSharingRatingThreshold) {
    return "positive";
  }

  return "normal";
};

import React from "react";
import UnexpectedError from "@components/unexpected-error";
import FullHeightLayout from "@root/layouts/FullHeightLayout";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

function UnexpectedErrorPage(props) {
  const { error } = useSelector((state) => state.tip);
  let propsToSend = props;
  if (error) {
    propsToSend = {
      ...propsToSend,
      message: ["Product not activated", "Please contact admin"],
    };
  }
  return (
    <FullHeightLayout className="unexpected-error-page">
      <Box display="flex" alignItems="center" height="100%">
        <UnexpectedError {...propsToSend} />
      </Box>
    </FullHeightLayout>
  );
}

export default UnexpectedErrorPage;
